import React, {Component} from 'react';
import 'ol/ol.css';
import "ol-ext/dist/ol-ext.css";
import $ from "jquery";

import LDH from '../helpers/LeopardDataHelper';
import LOLH from "../helpers/LeopardOpenLayersHelper";
import LeopardStaticUIConfig from "./LeopardStaticUIConfig";
import LeopardCountdownSwitch from "../datashaping/LeopardCountdownSwitch";
import LRH from "../helpers/LeopardReactHelper";
import LeopardTooltipWithLink from "../datashaping/LeopardTooltipWithLink";
import LeopardAjaxHelper from "../helpers/LeopardAjaxHelper";
import {Popover} from 'devextreme-react/popover';
import ScrollView from 'devextreme-react/scroll-view';
import LWH from "../helpers/LeopardWebsocketHelper";
import LeopardAPIGatewayConfig from "./LeopardAPIGatewayConfig";

class LeopardMapEngine extends Component {
    constructor(props) {
        super(props);

        this.state = {
            mapInstance: null
        };

        this.selectedParentViewData = null;
        this.popoverContent = [];
        this.uiObjectInstances = [];
        this.mapDefinition = [];
        this.dataViewId = null;
        this.relationshipsLinkedToDataView = [];
        this.pendingWebsocketCallbacks = [];
    }

    componentDidMount() {
        let that = this;
        this.mapDefinition = this.props.mapDefinition;
        this.dataViewId = this.props.dataViewId;

        if (LDH.IsObjectNull(this.mapDefinition.mapProvider) ||
            LDH.IsValueEmpty(this.mapDefinition.mapProvider) ||
            this.mapDefinition.mapProvider === "google-map") {
            this.initializeGoogleMap();
        }
        if (this.mapDefinition.mapProvider === "open-street-map") {
            this.initializeOpenStreetMap();
        }
        this.establishRelationships();

        $("#leopard-map-control-" + this.dataViewId).resize(function () {
            if (!LDH.IsObjectNull(that.state.mapInstance) &&
                !LDH.IsObjectNull(that.state.mapInstance.ol3map)) {
                that.state.mapInstance.ol3map.updateSize();
            }
        });
    }

    componentWillUnmount() {
        if (LDH.IsObjectNull(window.leopardMapInstances)) {
            window.leopardMapInstances = [];
        }
        for (let i = 0; i < window.leopardMapInstances.length; i++) {
            if (LDH.IsObjectNull(window.leopardMapInstances[i])) {
                continue;
            }
            let id = window.leopardMapInstances[i].dataViewId;
            if (id === this.dataViewId) {
                delete window.leopardMapInstances[i];
                break;
            }
        }
        LWH.RemovePendingEventByDataViewId(this.dataViewId);
        for (let i = 0; i < this.pendingWebsocketCallbacks.length; i++) {
            clearInterval(this.pendingWebsocketCallbacks[i]);
        }
        LRH.DisposeUIInstancesFromList(this.uiObjectInstances);
        this.setState({mapInstance: null});
    }

    setObjectInstance = (data) => {
        if (LDH.IsObjectNull(data.ref) ||
            !LDH.IsObjectNull(this.uiObjectInstances[data.controlName])) {
            return false;
        }
        this.uiObjectInstances[data.controlName] = data.ref;
        return true;
    };

    setMapInstance = (thisComp, dataObj) => {
        if (!thisComp.setObjectInstance(dataObj)) {
            return;
        }
        if (LDH.IsObjectNull(window.leopardMapInstances)) {
            window.leopardMapInstances = [];
        }

        let mapFound = false;
        let instanceIndex = null;
        for (let i = 0; i < window.leopardMapInstances.length; i++) {
            if (LDH.IsObjectNull(window.leopardMapInstances[i])) {
                continue;
            }
            let id = window.leopardMapInstances[i].dataViewId;
            if (id === thisComp.props.dataViewId) {
                instanceIndex = i;
                mapFound = true;
                break;
            }
        }
        if (mapFound === false) {
            window.leopardMapInstances.push({
                instance: dataObj.ref,
                dataViewId: thisComp.props.dataViewId
            });
        } else {
            window.leopardMapInstances[instanceIndex] = {
                instance: dataObj.ref,
                dataViewId: thisComp.props.dataViewId
            }
        }

        if (!LDH.IsObjectNull(thisComp.props.setMapInstance)) {
            thisComp.props.setMapInstance({
                instance: dataObj.ref,
                id: thisComp.props.dataViewId,
                type: "map"
            });
        }
    }

    establishRelationships = () => {
        let that = this;
        that.relationships = that.props.relationships;
        let dashboardItemId = that.props.dataViewId;

        if (!LDH.IsObjectNull(dashboardItemId) && !LDH.IsValueEmpty(dashboardItemId) &&
            !LDH.IsObjectNull(that.relationships) &&
            that.relationshipsLinkedToDataView.length === 0) {
            let linkedList = LDH.GetRelationshipsByDashboardItemId(that.relationships,
                dashboardItemId);
            that.relationshipsLinkedToDataView = linkedList;
        }

        that.selectedParentViewData = null;
        if (!LDH.IsObjectNull(dashboardItemId) && !LDH.IsValueEmpty(dashboardItemId) &&
            !LDH.IsObjectNull(that.relationships)) {
            LeopardStaticUIConfig.Global_DashboardDataViewListeners.push({
                dashboardItemId,
                props: that.props,
                instance: "blank",
                callback(data) {
                    if (!LDH.IsObjectNull(that.props.mapDefinition.parameters) &&
                        !LDH.IsValueEmpty(that.props.mapDefinition.parameters)) {
                        let parametersCloned = LDH.DeepClone(that.props.mapDefinition.parameters);

                        for (let x = 0; x < parametersCloned.length; x++) {
                            let item = parametersCloned[x];
                            if (!LDH.IsObjectNull(window["dataViewParam_" + that.props.dataViewId +
                            "_control_" + item.parameterName])) {
                                let controlId = window["dataViewParam_" + that.props.dataViewId +
                                "_control_" + item.parameterName];
                                data.dataFromSource[item.parameterName] = controlId;
                            }
                        }
                    }

                    for (let i = 0; i < data.features.length; i++) {
                        if (data.features[i] === "rowlink") {
                            let mapDefinition = that.props.mapDefinition;
                            let params = mapDefinition.oDataQueryForLinkedView;
                            that.selectedParentViewData = data.dataFromSource;

                            if (!LDH.IsObjectNull(params) && !LDH.IsValueEmpty(params)) {
                                if (!LDH.IsObjectNull(data.dataFromSource)) {
                                    params = LDH.ConvertArrayMacroToString(params, data.dataFromSource, null);

                                    let method = "get";
                                    if (!LDH.IsObjectNull(mapDefinition.httpMethodForLinkedView) &&
                                        !LDH.IsValueEmpty(mapDefinition.httpMethodForLinkedView)) {
                                        method = mapDefinition.httpMethodForLinkedView;
                                    }

                                    if (method !== "eventsync") {
                                        LeopardAjaxHelper.ApiGatewayInvoker(method, params, null, function (response) {
                                            if (!LDH.IsObjectNull(mapDefinition.postDataStriptForLinkedView) &&
                                                !LDH.IsValueEmpty(mapDefinition.postDataStriptForLinkedView)) {
                                                let javascript = mapDefinition.postDataStriptForLinkedView;
                                                let dataName = "parentRowData";
                                                let dataValue = response;
                                                let mapControl = that.state.mapInstance.ol3map;
                                                let gpsList = LDH.EvaluateJavaScriptForDataShaping(javascript,
                                                    dataName, dataValue, that.props.dataViewId);

                                                if (LDH.IsObjectNull(gpsList)) gpsList = [];
                                                that.displayRoutesOnMap_ORS(mapControl, "driving-car",
                                                    gpsList, that.props.dataViewId, mapDefinition,
                                                    {parentRowData: data.dataFromSource}, true);
                                            }
                                        }, function (error, sessionTimeout) {
                                            if (sessionTimeout !== undefined && sessionTimeout === true) {
                                                LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                                            } else {
                                                LRH.ShowToast("Failed to load the Map data.", "error", 5000);
                                            }
                                        }, null, null, false);
                                    } else {
                                        let postTemplate = LDH.DeepClone(LeopardAPIGatewayConfig.ProfileAPI_BodyTemplate());
                                        postTemplate.type = "leopardsystems.delivery.gps.request";
                                        postTemplate.source = LDH.GenerateGuid();

                                        let javascript = mapDefinition.oDataQueryForLinkedView;
                                        let dataName = "parentRowData";
                                        let dataValue = that.selectedParentViewData;
                                        let postData = LDH.EvaluateJavaScriptForDataShaping(javascript,
                                            dataName, dataValue, that.props.dataViewId);

                                        LeopardAjaxHelper.SendRequestByEventSync(function (response) {
                                            if (!LDH.IsObjectNull(mapDefinition.postDataStriptForLinkedView) &&
                                                !LDH.IsValueEmpty(mapDefinition.postDataStriptForLinkedView)) {
                                                let javascript = mapDefinition.postDataStriptForLinkedView;
                                                let dataName = "data";
                                                let dataValue = {
                                                    parentRowData: that.selectedParentViewData,
                                                    response: response
                                                };
                                                let mapControl = that.state.mapInstance.ol3map;
                                                let gpsList = LDH.EvaluateJavaScriptForDataShaping(javascript,
                                                    dataName, dataValue, that.props.dataViewId);

                                                if (LDH.IsObjectNull(gpsList)) gpsList = [];
                                                that.displayRoutesOnMap_ORS(mapControl, "driving-car",
                                                    gpsList, that.props.dataViewId, mapDefinition,
                                                    {parentRowData: data.dataFromSource}, true);
                                            }
                                        }, function (error, sessionTimeout) {
                                            if (sessionTimeout !== undefined && sessionTimeout === true) {
                                                LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                                            } else {
                                                LRH.ShowToast("Failed to load the Map data.", "error", 5000);
                                            }
                                        }, postTemplate, postData);
                                    }
                                }
                            } else {
                                if (!LDH.IsObjectNull(mapDefinition.postDataStriptForLinkedView) &&
                                    !LDH.IsValueEmpty(mapDefinition.postDataStriptForLinkedView)) {
                                    let javascript = mapDefinition.postDataStriptForLinkedView;
                                    let dataName = "parentRowData";
                                    let dataValue = that.selectedParentViewData;
                                    let mapControl = that.state.mapInstance.ol3map;
                                    let gpsList = LDH.EvaluateJavaScriptForDataShaping(javascript,
                                        dataName, dataValue, that.props.dataViewId);

                                    if (LDH.IsObjectNull(gpsList)) gpsList = [];
                                    that.displayRoutesOnMap_ORS(mapControl, "driving-car",
                                        gpsList, that.props.dataViewId, mapDefinition,
                                        {parentRowData: data.dataFromSource}, true);
                                }
                            }
                        }
                    }
                }
            });
        }
    };

    initializeMapEvents = () => {
        let that = this;
        this.state.mapInstance.ol3map.on("moveend", function () {
            let popupControl = that.uiObjectInstances["popoverControl"];
            if (LDH.IsObjectNull(popupControl) ||
                LDH.IsObjectNull(popupControl.instance)) {
                return;
            }
            popupControl.instance.repaint();
        });
    }

    initializeGoogleMap = () => {
        let that = this;
        let provider = LeopardStaticUIConfig.MapProviderGoogle;
        let viewOptions = {center: [0, 0], zoom: 0, maxZoom: 22, minZoom: 0};
        let elemName = "leopard-map-control-" + this.dataViewId;
        let mapInstance = LOLH.InitializeMap(provider, viewOptions, elemName);
        this.setState({mapInstance: mapInstance}, function () {
            that.state.mapInstance.activate();
            that.setMapInstance(that, {
                ref: that.state.mapInstance.ol3map,
                controlName: "mapControl"
            });
            that.initializeMapEvents();
            that.initializeMapData();
        });
    };

    initializeOpenStreetMap = () => {
        let that = this;
        let provider = LeopardStaticUIConfig.MapProviderOpenStreetMap;
        let viewOptions = {center: [0, 0], zoom: 0, maxZoom: 22, minZoom: 0};
        let elemName = "leopard-map-control-" + this.dataViewId;
        let ol3map = LOLH.InitializeMap(provider, viewOptions, elemName);

        let mapInstance = {};
        mapInstance.ol3map = ol3map;
        this.setState({mapInstance: mapInstance}, function () {
            that.setMapInstance(that, {
                ref: that.state.mapInstance.ol3map,
                controlName: "mapControl"
            });
            that.initializeMapEvents();
            that.initializeMapData();
        });
    };

    initializeMapData = () => {
        let that = this;
        let mapDefinition = that.props.mapDefinition;
        let params = mapDefinition.oDataQueryForStandaloneDataView;
        let method = "get";
        if (!LDH.IsObjectNull(mapDefinition.httpMethodForStandaloneDataView) &&
            !LDH.IsValueEmpty(mapDefinition.httpMethodForStandaloneDataView)) {
            method = mapDefinition.httpMethodForStandaloneDataView;
        }

        if (LDH.IsObjectNull(params) || LDH.IsValueEmpty(params)) {
            if (!LDH.IsObjectNull(mapDefinition.postDataStriptForStandaloneDataView) &&
                !LDH.IsValueEmpty(mapDefinition.postDataStriptForStandaloneDataView)) {
                let javascript = mapDefinition.postDataStriptForStandaloneDataView;
                let dataName = "parentRowData";
                let dataValue = "";
                let gpsList = LDH.EvaluateJavaScriptForDataShaping(javascript,
                    dataName, dataValue, that.props.dataViewId);

                if (LDH.IsObjectNull(gpsList)) gpsList = [];
                let mapControl = that.state.mapInstance.ol3map;
                let dataViewId = that.props.dataViewId;
                let coords3857List = [];

                for (let i = 0; i < gpsList.length; i++) {
                    if (LDH.IsObjectNull(gpsList[i].Gps)) {
                        continue;
                    }
                    let coords3857 = LOLH.ConvertCoordsToEPSG3857(gpsList[i].Gps);
                    LOLH.CreateMarker(mapDefinition, coords3857, mapControl, dataViewId, i,
                        gpsList.length, that.uiObjectInstances, null, that, gpsList[i]);
                    coords3857List.push(coords3857);
                }
                setTimeout(function () {
                    LOLH.CenterMapByMultipleCoords(mapControl, coords3857List);
                }, 50);
            }
        } else {
            if (method !== "eventsync") {
                LeopardAjaxHelper.ApiGatewayInvoker(method, params, null, function (response) {
                    if (!LDH.IsObjectNull(mapDefinition.postDataStriptForStandaloneDataView) &&
                        !LDH.IsValueEmpty(mapDefinition.postDataStriptForStandaloneDataView)) {
                        let javascript = mapDefinition.postDataStriptForStandaloneDataView;
                        let dataName = "parentRowData";
                        let dataValue = response;
                        let mapControl = that.state.mapInstance.ol3map;
                        let gpsList = LDH.EvaluateJavaScriptForDataShaping(javascript,
                            dataName, dataValue, that.props.dataViewId);

                        if (LDH.IsObjectNull(gpsList)) gpsList = [];
                    }
                }, function (error, sessionTimeout) {
                    if (sessionTimeout !== undefined && sessionTimeout === true) {
                        LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                    } else {
                        LRH.ShowToast("Failed to load the Map data.", "error", 5000);
                    }
                }, null, null, false);
            } else {
                let postTemplate = LDH.DeepClone(LeopardAPIGatewayConfig.ProfileAPI_BodyTemplate());
                postTemplate.type = "leopardsystems.delivery.gps.request";
                postTemplate.source = LDH.GenerateGuid();

                let javascript = mapDefinition.oDataQueryForStandaloneDataView;
                let dataName = "parentRowData";
                let dataValue = that.selectedParentViewData;
                let postData = LDH.EvaluateJavaScriptForDataShaping(javascript,
                    dataName, dataValue, that.props.dataViewId);

                LeopardAjaxHelper.SendRequestByEventSync(function (response) {
                    if (!LDH.IsObjectNull(mapDefinition.postDataStriptForStandaloneDataView) &&
                        !LDH.IsValueEmpty(mapDefinition.postDataStriptForStandaloneDataView)) {
                        let javascript = mapDefinition.postDataStriptForStandaloneDataView;
                        let dataName = "data";
                        let dataValue = {
                            parentRowData: that.selectedParentViewData,
                            response: response
                        };
                        let mapControl = that.state.mapInstance.ol3map;
                        let gpsList = LDH.EvaluateJavaScriptForDataShaping(javascript,
                            dataName, dataValue, that.props.dataViewId);

                        if (LDH.IsObjectNull(gpsList)) gpsList = [];
                    }
                }, function (error, sessionTimeout) {
                    if (sessionTimeout !== undefined && sessionTimeout === true) {
                        LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                    } else {
                        LRH.ShowToast("Failed to load the Map data.", "error", 5000);
                    }
                }, postTemplate, postData);
            }
        }
    };

    displayRoutesOnMap_ORS = (mapControl, routeType, pointCoords, dataViewId, mapDefinition, parentData, refreshExtentOnLoad, clearOverlaysOnMap, createMarkerForRoute) => {
        let that = this;
        if (LDH.IsObjectNull(pointCoords) || pointCoords.length === 0) {
            LOLH.ClearRoutesOnMap(mapControl);
            LOLH.ClearOverlaysOnMap(mapControl);
            return;
        }

        if (pointCoords.length === 1) {
            LOLH.ClearRoutesOnMap(mapControl);
            LOLH.ClearOverlaysOnMap(mapControl);

            let coords3857 = LOLH.ConvertCoordsToEPSG3857(pointCoords[0].Gps);
            LOLH.CreateMarker(that.mapDefinition, coords3857, mapControl, dataViewId, 0,
                pointCoords.length, that.uiObjectInstances, parentData, that, pointCoords[0]);
            LOLH.CenterMapByCoords(mapControl, coords3857);
            return;
        }

        let url = LDH.ReplaceAll(window.ORS_Api_DirectionUrl, "{0}", routeType);
        let coordsArray = [];
        let pointCount = 0;
        for (let i = 0; i < pointCoords.length; i++) {
            if (pointCount >= 50) break;
            if (LDH.IsObjectNull(pointCoords[i].Gps)) {
                continue;
            }
            coordsArray.push(pointCoords[i].Gps);
            pointCount++;
        }

        let body = JSON.stringify({"coordinates": coordsArray});
        let headers = [
            {
                name: "Accept",
                value: "application/json, application/geo+json, application/gpx+xml, " +
                    "img/png; charset=utf-8"
            },
            {name: "Content-Type", value: "application/json"},
            {name: "Authorization", value: LeopardStaticUIConfig.OpenRouteServiceAPIKey}
        ];

        $("#gridViewToobar_" + dataViewId).show();
        $("#Map_TopBar_Refresh_" + dataViewId).addClass("leopard-ui-disabled");

        if (LDH.IsObjectNull(clearOverlaysOnMap) || clearOverlaysOnMap) {
            LOLH.ClearOverlaysOnMap(mapControl);
        }

        if (LDH.IsObjectNull(createMarkerForRoute) || createMarkerForRoute) {
            for (let i = 0; i < pointCoords.length; i++) {
                if (LDH.IsObjectNull(pointCoords[i].Gps)) {
                    continue;
                }
                let coords3857 = LOLH.ConvertCoordsToEPSG3857(pointCoords[i].Gps);
                LOLH.CreateMarker(that.mapDefinition, coords3857, mapControl, dataViewId, i,
                    pointCoords.length, that.uiObjectInstances, parentData, that, pointCoords[i]);
            }
        }

        LeopardAjaxHelper.GenericXMLHttpRequest("post", url, body, function (responseData) {
            try {
                LOLH.ClearRoutesOnMap(mapControl);

                let jsonData = JSON.parse(responseData);
                LOLH.AddMapLayerByGeoJSON(jsonData, mapControl, that.mapDefinition);
                mapControl.updateSize();

                if (LDH.IsObjectNull(refreshExtentOnLoad) || refreshExtentOnLoad) {
                    $("#map_elements_cache_" + dataViewId).empty();
                    setTimeout(function () {
                        let features = LOLH.GetFeatureByGeoJSON(jsonData, "EPSG:3857");
                        LOLH.SetMapExtentByFeature(mapControl, features[0], mapDefinition);
                    }, 100);
                }
                $("#gridViewToobar_" + dataViewId).hide();
                $("#Map_TopBar_Refresh_" + dataViewId).removeClass("leopard-ui-disabled");
            } catch (ex) {
                LRH.ShowToast("Failed to retrieve map data.", "error", 5000);
                $("#gridViewToobar_" + dataViewId).hide();
                $("#Map_TopBar_Refresh_" + dataViewId).removeClass("leopard-ui-disabled");
                console.log("error", ex);
            }
        }, function (error) {
            LRH.ShowToast("Failed to retrieve map data.", "error", 5000);
            $("#gridViewToobar_" + dataViewId).hide();
            $("#Map_TopBar_Refresh_" + dataViewId).removeClass("leopard-ui-disabled");
            console.log("error", error);
        }, "open-route-service", headers);
    };

    autoRefreshCountdownOnEnd = () => {
        let dataViewId = this.props.dataViewId;
        $("#Map_TopBar_Refresh_" + dataViewId).removeClass("leopard-ui-disabled");

        this.refreshOnClick();
    };

    refreshOnClick = () => {
        let that = this;
        let mapDefinition = this.mapDefinition;
        let params = mapDefinition.oDataQueryForLinkedView;
        let parentData = this.selectedParentViewData;
        let dataViewId = this.props.dataViewId;

        if ($("#Map_TopBar_Refresh_" + dataViewId).hasClass("leopard-ui-disabled")) {
            return;
        }

        if (!LDH.IsObjectNull(params) && !LDH.IsValueEmpty(params)) {
            if (!LDH.IsObjectNull(parentData)) {
                params = LDH.ConvertArrayMacroToString(params, parentData, null);

                let method = "get";
                if (!LDH.IsObjectNull(mapDefinition.httpMethodForLinkedView) &&
                    !LDH.IsValueEmpty(mapDefinition.httpMethodForLinkedView)) {
                    method = mapDefinition.httpMethodForLinkedView;
                }
                LeopardAjaxHelper.ApiGatewayInvoker(method, params, null, function (response) {
                    if (!LDH.IsObjectNull(mapDefinition.postDataStriptForLinkedView) &&
                        !LDH.IsValueEmpty(mapDefinition.postDataStriptForLinkedView)) {
                        let javascript = mapDefinition.postDataStriptForLinkedView;
                        let dataName = "parentRowData";
                        let dataValue = response;
                        let mapControl = that.state.mapInstance.ol3map;
                        let gpsList = LDH.EvaluateJavaScriptForDataShaping(javascript,
                            dataName, dataValue, dataViewId);

                        if (LDH.IsObjectNull(gpsList)) gpsList = [];
                        that.displayRoutesOnMap_ORS(mapControl, "driving-car",
                            gpsList, dataViewId, mapDefinition,
                            {parentRowData: parentData}, false);
                    }
                }, function (error, errorHandler) {

                }, null, null, false);
            }
        }
    };

    mapToolbar = (mapDefinition) => {
        let that = this;

        let enableAutoRefresh = false;
        if (!LDH.IsObjectNull(mapDefinition.enableAutoRefresh) &&
            !LDH.IsValueEmpty(mapDefinition.enableAutoRefresh)) {
            enableAutoRefresh = mapDefinition.enableAutoRefresh;
        }

        let autoRefreshInterval = 30;
        if (!LDH.IsObjectNull(mapDefinition.autoRefreshInterval) &&
            !LDH.IsValueEmpty(mapDefinition.autoRefreshInterval)) {
            autoRefreshInterval = mapDefinition.autoRefreshInterval;
        }

        let showAutoRefreshSwitch = true;
        if (!LDH.IsObjectNull(mapDefinition.showAutoRefreshSwitch) &&
            !LDH.IsValueEmpty(mapDefinition.showAutoRefreshSwitch)) {
            showAutoRefreshSwitch = mapDefinition.showAutoRefreshSwitch;
        }

        return (
            <React.Fragment>
                <div className={"leopard-map-toolbar"} style={{minHeight: "30px"}}>
                    {
                        enableAutoRefresh === false ? "" :
                            <span id={"autoRefresh_" + that.props.dataViewId}
                                  className={"leopard-autorefresh-button_wrapper"}
                                  style={{display: showAutoRefreshSwitch ? "block" : "none"}}>
                                     <div id={"autoRefreshCountdownControl_" + that.props.dataViewId}>
                                        <LeopardCountdownSwitch
                                            autoRefreshCountdownOnEnd={that.autoRefreshCountdownOnEnd}
                                            tooltip={"The map will be refreshed automatically when timer counts down to 0."}
                                            autoRefreshInterval={autoRefreshInterval}
                                            fieldValue={enableAutoRefresh} gridViewId={that.props.dataViewId}/>
                                     </div>
                                </span>
                    }
                    {
                        LRH.RenderDataViewParameters(mapDefinition.parameters, that.props.dataViewId,
                            mapDefinition, function (data) {
                                if (data.parameterTriggerOnChange === false) {
                                    return;
                                }
                                window[data.controlName] = data.control.value;
                                if (!LDH.IsObjectNull(data.dataShapingForQuery) &&
                                    !LDH.IsValueEmpty(data.dataShapingForQuery)) {
                                    let javascript = data.dataShapingForQuery;
                                    let dataName = "data";
                                    let dataValue = [];
                                    LDH.EvaluateJavaScriptForDataShaping(javascript,
                                        dataName, dataValue, that.props.dataViewId);
                                }

                                if (data.autoApplyParameterFilter) {
                                    // that.refreshOnClick({
                                    //     gridViewId: that.props.dataViewId,
                                    //     refreshChildViews: true
                                    // });
                                }
                            }, function (data) {
                            }, null, null)
                    }
                    <span id={"gridViewToobar_" + that.props.dataViewId} className="leopard-gridview-dataloading">
                        <i className="fas fa-spinner fa-pulse" style={{color: "#FF8100", fontSize: "18px"}}></i>
                    </span>
                    <span style={{padding: "0 2px 0 0"}}>
                            <LeopardTooltipWithLink
                                elementId={"Map_TopBar_Refresh_" + that.props.dataViewId}
                                labelText={"Refresh"} width={250} title={"Refresh"}
                                onClick={(e) => this.refreshOnClick({e: e})}
                                text={"The Refresh button allows you to refresh data and repaint the map."}/>
                        </span>
                </div>
            </React.Fragment>
        )
    };

    onPopoverShowing = (e) => {
        let that = this;
        let popoverInstance = that.uiObjectInstances["popoverControl"].instance;
        let content = popoverInstance.content();
        let popoverId = LDH.GenerateGuid();
        $(".leopard-table-body", content).attr("id", popoverId);

        let progress = "<tr><td><span class='leopard-gridview-dataloading' style='display: block;padding-bottom: 5px;margin: 0;'>" +
            "<i class='fas fa-spinner fa-pulse' style='color: rgb(255, 128, 0);font-size: 25px;'></i></span></td></tr>";

        $("#" + popoverId, content).html(progress);
        popoverInstance.option("closeOnOutsideClick", false);

        $("#Map_TopBar_Refresh_" + that.props.dataViewId).addClass("leopard-ui-disabled");

        if (!LDH.IsObjectNull(that.mapDefinition.oDataTableForMarkerTooltip) &&
            !LDH.IsValueEmpty(that.mapDefinition.oDataTableForMarkerTooltip) &&
            !LDH.IsValueEmpty(that.mapDefinition.oDataQueryForMarkerTooltip) &&
            !LDH.IsValueEmpty(that.mapDefinition.oDataQueryForMarkerTooltip)) {
            let eventKey = LDH.GenerateGuid();
            let params = that.mapDefinition.oDataQueryForMarkerTooltip;
            let pointData = popoverInstance.option("pointData");

            params = LDH.ConvertArrayMacroToString(params, pointData, null);
            LWH.AddPendingEvent(eventKey, popoverId, that.dataViewId, "leopard-map-tooltip-request", null, false);

            let messageTemplate = LWH.GetMessageTemplate(
                "leopardsystems.tooltip.request", window.websocketConnectionId, {
                    "entity": that.mapDefinition.oDataTableForMarkerTooltip, "query": params
                }, eventKey, "connectionId"
            );
            LWH.SendMessage(JSON.stringify(messageTemplate));

            let pendingCallback = LWH.WaitForWebsocketResponse(eventKey, function (response, responseData) {
                if (response === "aborted") {
                    popoverInstance.option("closeOnOutsideClick", true);
                    $("#Map_TopBar_Refresh_" + that.dataViewId).removeClass("leopard-ui-disabled");
                } else if (response === "completed") {
                    if (LDH.IsObjectNull(responseData)) return;
                    that.initializeTooltipPopup(responseData, popoverId, content, popoverInstance);
                }
            });
            that.pendingWebsocketCallbacks.push(pendingCallback);
        } else {
            let responseData = {};
            responseData["data"] = [];
            that.initializeTooltipPopup(responseData, popoverId, content, popoverInstance);
        }
    };

    initializeTooltipPopup = (responseData, popoverId, content, popoverInstance) => {
        let that = this;
        responseData = responseData.data[0];
        if (!LDH.IsObjectNull(that.mapDefinition.postDataStriptForMarkerTooltip) &&
            !LDH.IsValueEmpty(that.mapDefinition.postDataStriptForMarkerTooltip)) {
            let javascript = that.mapDefinition.postDataStriptForMarkerTooltip;
            let dataName = "data";
            let dataValue = responseData;
            responseData = LDH.EvaluateJavaScriptForDataShaping(javascript,
                dataName, dataValue, that.dataViewId);
        }

        let keys = Object.keys(responseData);
        let popoverContentData = [];
        for (let v = 0; v < keys.length; v++) {
            if (LDH.IsObjectNull(responseData[keys[v]])) {
                continue;
            }
            if (LDH.IsValueEmpty(responseData[keys[v]])) {
                continue;
            }
            popoverContentData.push({
                id: keys[v].replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3'),
                value: responseData[keys[v]]
            })
        }
        that.popoverContent = popoverContentData;

        let htmlData = "";
        let routeData = [];
        for (let i = 0; i < that.popoverContent.length; i++) {
            let item = that.popoverContent[i];
            if (item.id === "RouteData" || item.id === "Route Data") {
                routeData = item.value;
                continue;
            }
            htmlData +=
                "<tr>" +
                "   <td class='leopard-map-marker-popover-contentcell-id'>" +
                "        <div class='leopard-map-marker-popover-contentcell-id-text' " +
                "             title='" + item.id + "'>" + item.id + "</div>" +
                "   </td>" +
                "   <td class='leopard-map-marker-popover-contentcell-value'>" +
                "        <div class='leopard-map-marker-popover-contentcell-value-text' " +
                "             title='" + item.value + "'>" + item.value + "</div>" +
                "   </td>" +
                "</tr>";
        }

        if (!LDH.IsValueEmpty(this.mapDefinition.showRouteButton) && this.mapDefinition.showRouteButton) {
            htmlData +=
                "<Tr>" +
                "   <td class='leopard-map-marker-popover-contentcell-id'>" +
                "       <div class='dx-widget dx-button dx-button-normal leopard-button leopard-map-markerbutton-route' " +
                "            id='leopard-map-markerbutton-route-" + popoverId + "' style='margin-top: 10px;'>" +
                "           <div class='dx-button-content'><span class='dx-button-text'>Route</span></div>" +
                "       </div>" +
                "   </td>" +
                "</Tr>";
        }

        if ($("#" + popoverId, content).length > 0) {
            $("#" + popoverId, content).html(htmlData);
            $(".leopard-map-markerbutton-route").on("click", function () {
                //TODO: Data needs to be replaced with real data.

                let routeDataDemo = [];
                if (popoverInstance.option("pointData").Id === 0) {
                    routeDataDemo.push({ Id: 0, Gps: [145.159239, -37.909121] });
                    routeDataDemo.push({ Id: 1, Gps: [145.07221585459172, -37.817636947692556] });
                }
                if (popoverInstance.option("pointData").Id === 1) {
                    routeDataDemo.push({ Id: 0, Gps: [144.986367, -37.865089] });
                    routeDataDemo.push({ Id: 1, Gps: [145.06570205086183, -37.828923395355446] });
                }
                if (popoverInstance.option("pointData").Id === 2) {
                    routeDataDemo.push({ Id: 0, Gps: [145.10737343852534, -37.854913264725624] });
                    routeDataDemo.push({ Id: 1, Gps: [144.89109008636032, -37.85480931176169] });
                }
                if (popoverInstance.option("pointData").Id === 3) {
                    routeDataDemo.push({ Id: 0, Gps: [145.03700517329426, -37.82895349615123] });
                    routeDataDemo.push({ Id: 1, Gps: [144.8469643191578,-37.780445855476415] });
                }
                if (popoverInstance.option("pointData").Id === 4) {
                    routeDataDemo.push({ Id: 0, Gps: [145.0417758417964, -37.85379079484252] });
                    routeDataDemo.push({ Id: 1, Gps: [145.1564750576785, -37.87338846879988] });
                }
                if (popoverInstance.option("pointData").Id === 5) {
                    routeDataDemo.push({ Id: 0, Gps: [144.9680955171525, -37.81052942461624] });
                    routeDataDemo.push({ Id: 1, Gps: [144.9955210687397, -37.86907586767322] });
                }
                if (popoverInstance.option("pointData").Id === 6) {
                    routeDataDemo.push({ Id: 0, Gps: [144.9961894538872, -37.84709391995972] });
                    routeDataDemo.push({ Id: 1, Gps: [145.05267444340203, -37.89129964812254] });
                }
                if (popoverInstance.option("pointData").Id === 7) {
                    routeDataDemo.push({ Id: 0, Gps: [145.01474205361768, -37.83579156436482] });
                    routeDataDemo.push({ Id: 1, Gps: [145.11361002668176, -37.77629390246882] });
                }
                if (popoverInstance.option("pointData").Id === 8) {
                    routeDataDemo.push({ Id: 0, Gps: [145.0106781508196, -37.86397445937192] });
                    routeDataDemo.push({ Id: 1, Gps: [144.87911309240533, -37.782272640921576] });
                }

                that.onRouteButtonClick(routeDataDemo);
                popoverInstance.hide();
                return false;
            });
        }
        popoverInstance.repaint();
        popoverInstance.option("closeOnOutsideClick", true);
        $("#Map_TopBar_Refresh_" + that.dataViewId).removeClass("leopard-ui-disabled");
    }

    onRouteButtonClick = (gpsList) => {
        let that = this;
        let mapControl = that.state.mapInstance.ol3map;

        that.displayRoutesOnMap_ORS(mapControl, "driving-car",
            gpsList, that.props.dataViewId, that.mapDefinition,
            null, true, false, false);
    };

    onPopoverHidden = (e) => {
        let that = this;
        let content = that.uiObjectInstances["popoverControl"].instance.content();
        $(".leopard-table-body", content).attr("id", "").html("");
    };

    popoverContentRender = (e) => {
        return (<React.Fragment>
            <ScrollView showScrollbar={"onHover"}>
                <table>
                    <tbody className={"leopard-table-body"}></tbody>
                </table>
            </ScrollView>
        </React.Fragment>)
    };

    render() {
        let markerIcon = "fas fa-map-marker";
        if (!LDH.IsObjectNull(this.mapDefinition.markerIcon) && !LDH.IsValueEmpty(this.mapDefinition.markerIcon)) {
            markerIcon = this.mapDefinition.markerIcon;
        }

        let paddingTop = "0px";
        if (!LDH.IsObjectNull(this.mapDefinition.markerTopPadding) && !LDH.IsValueEmpty(this.mapDefinition.markerTopPadding)) {
            paddingTop = this.mapDefinition.markerTopPadding;
        }

        return (
            <React.Fragment>
                {this.mapToolbar(this.mapDefinition)}
                <div className={"leopard-map-container"}>
                    <div id={"leopard-map-control-" + this.props.dataViewId}
                         className={"leopard-map-control"}></div>
                </div>
                <div style={{display: "none"}}>
                    <div id={"map_marker_template_" + this.dataViewId}>
                        <i className={markerIcon + " leopard-map-marker-container"}>
                            <div className={"leopard-map-marker-text"} style={{paddingTop: paddingTop}}></div>
                        </i>
                    </div>
                    <div id={"map_elements_cache_" + this.dataViewId}></div>
                </div>
                <Popover position="top" Width={400} maxHeight={300} ref={(e) => this.setObjectInstance({
                    controlName: "popoverControl", ref: e
                })} onShowing={this.onPopoverShowing} onHidden={this.onPopoverHidden}
                         contentRender={this.popoverContentRender}>
                </Popover>
            </React.Fragment>
        );
    }
}

export default LeopardMapEngine;