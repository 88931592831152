import $ from "jquery";

const FieldComponent = window.Formio.Components.components.field;
const editForm = window.Formio.Components.components.nested.editForm;

class LeopardHtmlFormBuilderSignaturePad extends FieldComponent {
    constructor(component, options, data) {
        super(component, options, data);
        this.componentKey = "";
        this.controlGuid = "";
        this.signaturePadObj = null;
        this.data = [];
    }

    static schema() {
        return FieldComponent.schema({
            type: 'leopardSignaturePad'
        });
    }

    static builderInfo() {
        return {
            title: 'Signature',
            schema: LeopardHtmlFormBuilderSignaturePad.schema()
        };
    }

    generateGuid() {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
        }

        return s4() + s4() + s4() + s4() + s4() + s4() + s4() + s4();
    }

    init() {
        super.init();
    }

    render(children) {
        this.componentKey = this.component.key;
        this.controlGuid = this.generateGuid();
        this.data = this._data;
        var key = this.componentKey + " " + this.controlGuid;

        var html =
            "<div class='htmlformbuilder_leopardsignaturepad_container " + key + "'>" +
            "   <div>" +
            "       <canvas class='htmlformbuilder_leopardsignaturepad " + key + "'></canvas>" +
            "   </div>" +
            "   <div>" +
            "       <button class='btn btn-secondary buttonclear " + key + "'>CLEAR</button>" +
            "   </div>" +
            "</div>";

        return super.render(html);
    }

    attach(element) {
        var that = this;
        var attachElem = super.attach(element);

        $(".htmlformbuilder_leopardsignaturepad." + that.controlGuid).each(function () {
            if ($(this).hasClass("control-init")) return;

            var canvas = $(this).get(0);
            var signaturePad = new window.SignaturePad(canvas, {
                backgroundColor: "rgb(245, 245, 235)"
            });
            that.signaturePadObj = signaturePad;

            signaturePad.addEventListener("endStroke", () => {
                var dataToUpdate = {
                    svgData: signaturePad.toSVG(),
                    rawData: signaturePad.toData()
                }
                that.updateValue(dataToUpdate);
            }, {once: false});

            var $parent = $(this).closest(".htmlformbuilder_leopardsignaturepad_container");
            $(".buttonclear", $parent).off("click").on("click", function () {
                signaturePad.clear();
                var dataToUpdate = {svgData: "", rawData: ""};
                that.updateValue(dataToUpdate);
                return false;
            });
            $(this).addClass("control-init");
        });
        return attachElem;
    }

    getValue() {
        return super.getValue();
    }

    destroy() {
        return super.destroy();
    }

    setValue(value, flags = {}) {
        var that = this;

        if (that.signaturePadObj !== null && value !== null) {
            that.signaturePadObj.fromData(value.rawData, {clear: true});
        }

        var key = this.componentKey + "." + this.controlGuid;
        if (typeof that.data["__dataSubmitted"] !== "undefined" &&
            that.data["__dataSubmitted"] === true) {
            that.signaturePadObj.off();
            $(".buttonclear." + key).hide();
        }

        return super.setValue(value);
    }

    updateValue(value, flags = {}) {
        return super.updateValue(value, flags);
    }
}

export default LeopardHtmlFormBuilderSignaturePad;