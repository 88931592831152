import React, {Component} from 'react';
import LeopardAttachableWidgetEngine from "./LeopardAttachableWidgetEngine";

class LeopardChildAttachableWidget extends Component {
    render() {
        return (
            <React.Fragment>
                <LeopardAttachableWidgetEngine definition={this.props.definition}
                                               dashboardLevel={this.props.dashboardLevel}
                                               useStateStore={this.props.useStateStore}
                                               dashboardItemType={this.props.dashboardItemType}
                                               relationships={this.props.relationships}
                                               updateWindowDimensionsRequired={this.props.updateWindowDimensionsRequired}
                                               setPhotoInstance={this.props.setPhotoInstance}
                                               setNotepadInstance={this.props.setNotepadInstance}
                                               dataViewId={this.props.dataViewId}/>
            </React.Fragment>
        );
    }
}

export default LeopardChildAttachableWidget;
