import React, {Component} from 'react';
import $ from "jquery";
import Box, {Item} from 'devextreme-react/box';
import LDH from '../helpers/LeopardDataHelper';
import LeopardDataHelper from '../helpers/LeopardDataHelper';
import {NumberBox, SelectBox, TextBox} from 'devextreme-react';
import LeopardStaticUIConfig from "./LeopardStaticUIConfig";
import LRH from "../helpers/LeopardReactHelper";
import LeopardTooltipWithLink from "../datashaping/LeopardTooltipWithLink";
import DateBox from "devextreme-react/date-box";
import {RequiredRule} from 'devextreme-react/validator';
import Validator from 'devextreme-react/ui/validator';
import LeopardAjaxHelper from "../helpers/LeopardAjaxHelper";
import ValidationEngine from 'devextreme/ui/validation_engine';
import LWH from "../helpers/LeopardWebsocketHelper";
import LeopardAttachmentPopup from "../components/LeopardAttachmentPopup";
import LeopardCognitoConfig from "./LeopardCognitoConfig";
import LeopardDataImportPopup from "../components/LeopardDataImportPopup";
import {PatternRule} from "devextreme-react/form";

class LeopardFormWidgetEngine extends Component {
    constructor(props) {
        super(props);

        this.state = {
            formInstance: null,
            isWidgetInitialized: false,
        };

        this.selectedParentViewData = null;
        this.selectedGrandParentViewData = null;
        this.uiObjectInstances = [];
        this.formDefinition = [];
        this.dataViewId = null;
        this.relationshipsLinkedToDataView = [];
        this.formDataToSubmit = {};
        this.relationships = [];
        this.formSubmitMethod = "";
        this.disposablePopupInstances = [];
    }

    componentDidMount() {
        let that = this;
        this.formDefinition = this.props.formDefinition;
        this.dataViewId = this.props.dataViewId;
        this.relationships = this.props.relationships;

        if (!LDH.IsObjectNull(this.formDefinition.parameters)) {
            for (let i = 0; i < this.formDefinition.parameters.length; i++) {
                let columnName = this.formDefinition.parameters[i].columnName;
                if (LDH.IsObjectNull(columnName) || LDH.IsValueEmpty(columnName)) {
                    continue;
                }
                let defaultValue = this.formDefinition.parameters[i].defaultValue;
                this.formDataToSubmit[columnName] = defaultValue;
            }
        }

        if (!LDH.IsObjectNull(this.formDefinition.widgetInitLogic) &&
            !LDH.IsValueEmpty(this.formDefinition.widgetInitLogic)) {
            let javascript = this.formDefinition.widgetInitLogic;
            let dataName = "data";
            let dataValue = {
                parameters: this.formDefinition.parameters
            };
            LDH.EvaluateJavaScriptForDataShaping(javascript, dataName,
                dataValue, this.props.dataViewId, null);
        }

        this.establishRelationships();
        this.setState({isWidgetInitialized: true}, function () {
            let interval = setInterval(function () {
                if (that.uiObjectInstances === null || that.uiObjectInstances === []) {
                    return;
                }
                clearInterval(interval);

                for (let i = 0; i < that.formDefinition.parameters.length; i++) {
                    let param = that.formDefinition.parameters[i];

                    let parameterData = [];
                    let keys = Object.keys(that.uiObjectInstances);
                    for (let j = 0; j < keys.length; j++) {
                        if (keys[j].indexOf("formField_") === -1) {
                            continue;
                        }
                        parameterData.push({
                            parameterName: keys[j],
                            parameterValue: that.uiObjectInstances[keys[j]].option("value")
                        })
                    }

                    if (!LDH.IsObjectNull(param.formDataOnChangedLogic) &&
                        !LDH.IsValueEmpty(param.formDataOnChangedLogic)) {
                        let javascript = param.formDataOnChangedLogic;
                        let dataName = "data";
                        let dataValue = {
                            fieldValue: null,
                            formData: that.formDataToSubmit,
                            parameterData: parameterData
                        };
                        let fieldValue = LDH.EvaluateJavaScriptForDataShaping(javascript,
                            dataName, dataValue, that.props.dataViewId, null);
                        let paramName = "formField_" + param.fieldName + "Input_" + that.props.dataViewId;

                        that.uiObjectInstances[paramName].option("value", fieldValue);
                    }
                }
            }, 100);
        });
    }

    componentWillUnmount = () => {
        let formDefinition = this.formDefinition;
        LWH.RemovePendingEventByDataViewId(this.dataViewId);
        LRH.DisposeUIInstancesFromList(this.uiObjectInstances);

        if (!LDH.IsObjectNull(formDefinition.parameters) && formDefinition.parameters.length > 0) {
            for (let k = 0; k < formDefinition.parameters.length; k++) {
                let controlId = formDefinition.parameters[k].parameterName;
                let controlName = "dataViewParam_" + this.dataViewId + "_control_" + controlId;
                if (!LDH.IsObjectNull(window[controlName])) {
                    delete window[controlName];
                }
            }
        }

        let popupInstances = this.disposablePopupInstances;
        LRH.DisposeUIInstancesFromList(popupInstances);

        if (!LDH.IsObjectNull(formDefinition.workspaceUnmountLogic) &&
            !LDH.IsValueEmpty(formDefinition.workspaceUnmountLogic)) {
            let javascript = formDefinition.workspaceUnmountLogic;
            let dataName = "data";
            let dataValue = {definition: formDefinition};
            LDH.EvaluateJavaScriptForDataShaping(javascript, dataName,
                dataValue, this.dataViewId, null);
        }
        window["leopard-popup-photowidget-data-" + this.dataViewId] = null;
        window["formwidget-focused-input-" + this.dataViewId] = null;
    };

    addDisposablePopupInstances = (e) => {
        for (let i = 0; i < e.length; i++) {
            this.disposablePopupInstances.push(e[i]);
        }
    };

    popupButtonSaveOnClick = (e) => {
        this.uiObjectInstances["popupPhotoGalleryInstance"].hide();
    };

    prefillPopupOnClosed = (e, selectedRowData, isPrefillLocked) => {
        let that = this;
        if (LDH.IsObjectNull(selectedRowData)) {
            return;
        }
        let keys = Object.keys(this.uiObjectInstances);
        let keys2 = Object.keys(selectedRowData);
        let inputInstance = null;

        for (let i = 0; i < keys.length; i++) {
            if (keys[i].indexOf("formField_") === -1) {
                continue;
            }
            let valueToSend = "";
            let found = false;
            let isLastField = false;
            let foundIndex = 0;

            for (let j = 0; j < keys2.length; j++) {
                let id = keys2[j] + "Input_" + this.props.dataViewId;
                if (keys[i].indexOf(id) === -1) {
                    continue;
                }
                valueToSend = selectedRowData[keys2[j]];

                let $parent = $("#" + keys[i]).closest(".formField_container");
                let $link = $(".leopard-generic-hyperlink", $parent);

                if (isPrefillLocked && $link.length > 0 && !$link.hasClass("locked") &&
                    !LDH.IsValueEmpty(valueToSend)) {
                    $link.trigger("click");
                } else if (isPrefillLocked && $link.length > 0 && $link.hasClass("locked") &&
                    LDH.IsValueEmpty(valueToSend)) {
                    $link.trigger("click");
                } else if (!isPrefillLocked && $link.length > 0 && $link.hasClass("locked")) {
                    $link.trigger("click");
                }
                found = true;
                foundIndex = j;
                break;
            }

            if (found) {
                if (LDH.IsValueEmpty(valueToSend)) valueToSend = "";
                this.uiObjectInstances[keys[i]].option("value", valueToSend);

                if (LDH.IsValueEmpty(valueToSend) && inputInstance === null) {
                    inputInstance = this.uiObjectInstances[keys[i]];
                }
                isLastField = (foundIndex === keys2.length - 1);
            }

            if (found && isLastField && inputInstance === null) {
                inputInstance = this.uiObjectInstances[keys[i]];
            }
        }

        if (!LDH.IsObjectNull(inputInstance) && !inputInstance.option("readOnly")) {
            setTimeout(function () {
                inputInstance.focus();
            }, 500);
        } else {
            let focusedInput = window["formwidget-focused-input-" + this.props.dataViewId];
            if (!LDH.IsObjectNull(focusedInput) && !$("#" + focusedInput).hasClass("dx-state-readonly")) {
                setTimeout(function () {
                    $("#" + focusedInput + " " + "input.dx-texteditor-input").focus();
                }, 500);
            } else {
                for (let i = 0; i < this.formDefinition.parameters.length; i++) {
                    let param = this.formDefinition.parameters[i];
                    if (!LDH.IsObjectNull(param.defaultAutoFocus) && param.defaultAutoFocus) {
                        setTimeout(function () {
                            focusedInput = "formField_" + param.fieldName + "Input_" + that.props.dataViewId;
                            $("#" + focusedInput + " " + "input.dx-texteditor-input").focus();
                        }, 500);
                        break;
                    }
                }
            }
        }
    };

    addPrefillPopupInstance = (e) => {
        if (LDH.IsObjectNull(e)) return;
        this.uiObjectInstances["popupPrefillInstance"] = e.instance;
    };

    setInputFieldInstance = (data) => {
        if (data.e === undefined || data.e === null || data.e.instance === null) {
            return;
        }
        if (!LDH.IsObjectNull(data.defaultValueShapingLogic)) {
            data.e.instance.option("defaultValueShapingLogic", data.defaultValueShapingLogic);
        }
        if (!LDH.IsObjectNull(data.defaultValue)) {
            data.e.instance.option("inputDefaultValue", data.defaultValue);
        }
        if (!LDH.IsObjectNull(data.defaultAutoFocus)) {
            data.e.instance.option("inputDefaultAutoFocus", data.defaultAutoFocus);
        }
        if (!LDH.IsObjectNull(data.clearDataOnFormSubmit)) {
            data.e.instance.option("inputClearDataOnFormSubmit", data.clearDataOnFormSubmit);
        }
        if (!LDH.IsObjectNull(data.manualLockEnabled)) {
            data.e.instance.option("inputManualLockEnabled", data.manualLockEnabled);
        }
        this.uiObjectInstances[data.input] = data.e.instance;
    };

    setUIInstance = (data) => {
        if (data.e === undefined || data.e === null) {
            return;
        }
        let instances = this.uiObjectInstances;
        instances[data.name] = data.e;
    };

    customValidationRuleCallback = (data) => {
        let instances = this.uiObjectInstances;
        return LRH.BindValidationRulesToUIObject(data, instances);
    };

    inputFieldOnFocusIn = (data) => {
        let that = this;
        if (!LDH.IsObjectNull(data.parameter) && !LDH.IsObjectNull(data.parameter.isReadOnly)
            && data.parameter.isReadOnly) {
            return;
        }
        window["formwidget-focused-input-" + that.dataViewId] = data.id;
    };

    inputFieldOnKeyDown = (data) => {
        let keycode = data.e.event.key;
        if (typeof data.parameter === "undefined" || LDH.IsObjectNull(data.parameter)) {
            return;
        }
        if (typeof data.parameter.submitOnEnterKey !== "undefined" &&
            !LDH.IsObjectNull(data.parameter.submitOnEnterKey) &&
            data.parameter.submitOnEnterKey && keycode === "Enter") {
            setTimeout(function () {
                $(data.parameter.submitButtonJqueryClass).trigger("click");
            }, 100);
        } else if (typeof data.parameter.submitOnTabKey !== "undefined" &&
            !LDH.IsObjectNull(data.parameter.submitOnTabKey) &&
            data.parameter.submitOnTabKey && keycode === "Tab") {
            setTimeout(function () {
                $(data.parameter.submitButtonJqueryClass).trigger("click");
            }, 100);
        }

        if (!LDH.IsObjectNull(data.parameter.nextTabJqueryId) &&
            !LDH.IsValueEmpty(data.parameter.nextTabJqueryId) &&
            keycode === "Tab") {
            setTimeout(function () {
                $(data.parameter.nextTabJqueryId).focus();
            }, 100);
        }
    };

    inputFieldValueOnChanged = (data) => {
        if (LDH.IsObjectNull(data.fieldValue) || LDH.IsValueEmpty(data.fieldValue)) {
            data.fieldValue = "";
        }
        this.formDataToSubmit[data.fieldName] = data.fieldValue;

        if (!LDH.IsObjectNull(data.parameter.valueOnChangedLogic) &&
            !LDH.IsValueEmpty(data.parameter.valueOnChangedLogic)) {
            let javascript = data.parameter.valueOnChangedLogic;
            let dataName = "data";
            let dataValue = {
                fieldValue: data.fieldValue,
                formData: this.formDataToSubmit
            };
            let newValue = LDH.EvaluateJavaScriptForDataShaping(javascript, dataName,
                dataValue, data.dataViewId, null);

            if (typeof newValue !== "object" && typeof newValue !== "array") {
                data.fieldValue = newValue;
                this.formDataToSubmit[data.fieldName] = data.fieldValue;
                let fieldParam = "formField_" + data.parameter.fieldName + "Input_" + data.dataViewId;
                this.uiObjectInstances[fieldParam].option("value", data.fieldValue);
            }
        }

        for (let i = 0; i < data.formParameters.length; i++) {
            let param = data.formParameters[i];

            let parameterData = [];
            let keys = Object.keys(this.uiObjectInstances);
            for (let j = 0; j < keys.length; j++) {
                if (keys[j].indexOf("formField_") === -1) {
                    continue;
                }
                parameterData.push({
                    parameterName: keys[j],
                    parameterValue: this.uiObjectInstances[keys[j]].option("value")
                })
            }

            if (!LDH.IsObjectNull(param.formDataOnChangedLogic) &&
                !LDH.IsValueEmpty(param.formDataOnChangedLogic)) {
                let javascript = param.formDataOnChangedLogic;
                let dataName = "data";
                let dataValue = {
                    fieldValue: data.fieldValue,
                    formData: this.formDataToSubmit,
                    parameterData: parameterData
                };
                let fieldValue = LDH.EvaluateJavaScriptForDataShaping(javascript,
                    dataName, dataValue, data.dataViewId, null);
                let paramName = "formField_" + param.fieldName + "Input_" + data.dataViewId;

                this.uiObjectInstances[paramName].option("value", fieldValue);
            }
        }

        this.valueOnChangedSendDataToChildView(data);
    };

    establishRelationships = () => {
        let that = this;
        that.relationships = that.relationships;
        let dashboardItemId = that.dataViewId;
        if (!LDH.IsObjectNull(dashboardItemId) && !LDH.IsValueEmpty(dashboardItemId) &&
            !LDH.IsObjectNull(that.relationships) &&
            that.relationshipsLinkedToDataView.length === 0) {
            let linkedList = LDH.GetRelationshipsByDashboardItemId(that.relationships,
                dashboardItemId);
            that.relationshipsLinkedToDataView = linkedList;
        }

        that.selectedParentViewData = null;
        that.selectedGrandParentViewData = null;

        if (!LDH.IsObjectNull(dashboardItemId) && !LDH.IsValueEmpty(dashboardItemId)) {
            LeopardStaticUIConfig.Global_DashboardDataViewSiteAccessRegister.push({
                dashboardItemId: dashboardItemId,
                props: that.props,
                instance: new Object(),
                dataViewPersistentId: that.formDefinition.dataViewPersistentId,
                callback(data) {
                    window["datasource_subscriber_dataview_persistent_" + data.persistentId] = data;

                    for (let i = 0; i < that.formDefinition.parameters.length; i++) {
                        let parameter = that.formDefinition.parameters[i];

                        if (LDH.IsObjectNull(parameter.triggerDefaultValueLogicOnDataReceived) ||
                            !parameter.triggerDefaultValueLogicOnDataReceived) {
                            continue;
                        }
                        if (!LDH.IsObjectNull(parameter.defaultValue) && !LDH.IsValueEmpty(parameter.defaultValue)) {
                            parameter.defaultValue = LDH.FilterMacro(parameter.defaultValue, null, null);
                        }
                        if (!LDH.IsObjectNull(parameter.defaultValueShapingLogic) && !LDH.IsValueEmpty(parameter.defaultValueShapingLogic)) {
                            let javascript = parameter.defaultValueShapingLogic;
                            let dataName = "data";
                            let dataValue = {
                                defaultValue: parameter.defaultValue
                            };
                            parameter.defaultValue = LDH.EvaluateJavaScriptForDataShaping(javascript, dataName,
                                dataValue, that.dataViewId, null);
                        }
                        let inputInstance = that.uiObjectInstances["formField_" + parameter.fieldName + "Input_" + that.dataViewId];
                        if (!LDH.IsObjectNull(inputInstance)) {
                            inputInstance.option("value", parameter.defaultValue);
                        }
                    }
                }
            });
        }

        if (!LDH.IsObjectNull(dashboardItemId) && !LDH.IsValueEmpty(dashboardItemId) &&
            !LDH.IsObjectNull(that.relationships)) {
            LeopardStaticUIConfig.Global_DashboardDataViewListeners.push({
                dashboardItemId,
                props: that.props,
                instance: "blank",
                callback(data) {
                    var dataViewId = that.props.GridViewId;
                    if (!LDH.IsObjectNull(that.props.dataViewId) &&
                        !LDH.IsValueEmpty(that.props.dataViewId)) {
                        dataViewId = that.props.dataViewId;
                    }

                    for (let i = 0; i < data.features.length; i++) {
                        if (data.features[i] === "rowlink") {
                            that.selectedParentViewData = data.dataFromSource;
                            that.selectedGrandParentViewData = data.dataFromParent;

                            if (!LDH.IsObjectNull(data.dataFromSource)) {
                                if (!LDH.IsObjectNull(that.formDefinition.workspaceDataFromParentLogic) &&
                                    !LDH.IsValueEmpty(that.formDefinition.workspaceDataFromParentLogic)) {
                                    let javascript = that.formDefinition.workspaceDataFromParentLogic;
                                    let dataName = "parentRowData";
                                    let dataValue = {
                                        parentData: that.selectedParentViewData,
                                        grandParentData: that.selectedGrandParentViewData
                                    };
                                    javascript = LDH.FilterMacro(javascript);
                                    LDH.EvaluateJavaScriptForDataShaping(javascript, dataName,
                                        dataValue, dataViewId);
                                }

                                if (!LDH.IsObjectNull(that.formDefinition.apiEnabled) &&
                                    that.formDefinition.apiEnabled === true) {
                                    let odataQuery = that.formDefinition.apiODataQuery;
                                    let useEventSync = that.formDefinition.apiUseEventSync;
                                    let responseDataFromAPI = [];

                                    if ((!LDH.IsObjectNull(odataQuery) && !LDH.IsValueEmpty(odataQuery)) ||
                                        (!LDH.IsObjectNull(odataQuery) && useEventSync)) {
                                        let javascript = odataQuery;
                                        let dataName = "parentRowData";
                                        let dataValue = {
                                            parentData: that.selectedParentViewData,
                                            grandParentData: that.selectedGrandParentViewData
                                        };
                                        odataQuery = LDH.FilterMacro(odataQuery);
                                        odataQuery = LDH.EvaluateJavaScriptForDataShaping(javascript,
                                            dataName, dataValue, that.dataViewId);

                                        let postTemplate = "";
                                        if (!LDH.IsObjectNull(that.formDefinition.apiODataHttpPostBody) &&
                                            !LDH.IsValueEmpty(that.formDefinition.apiODataHttpPostBody)) {
                                            let javascript = that.formDefinition.apiODataHttpPostBody;
                                            let dataName = "parentRowData";
                                            let dataValue = {
                                                parentData: that.selectedParentViewData,
                                                grandParentData: that.selectedGrandParentViewData
                                            };
                                            javascript = LDH.FilterMacro(javascript);
                                            postTemplate = LDH.EvaluateJavaScriptForDataShaping(javascript, dataName,
                                                dataValue, dataViewId);
                                        }

                                        if (!LDH.IsObjectNull(that.formDefinition.apiUseEventSync) &&
                                            !LDH.IsValueEmpty(that.formDefinition.apiUseEventSync) &&
                                            that.formDefinition.apiUseEventSync === true) {

                                            $("#gridViewToobar_" + dataViewId).show();
                                            LeopardAjaxHelper.SendRequestByEventSync(function (response) {
                                                responseDataFromAPI = response.body.data;
                                                if (!LDH.IsObjectNull(that.formDefinition.apiPostDataScript) &&
                                                    !LDH.IsValueEmpty(that.formDefinition.apiPostDataScript)) {
                                                    let javascript = that.formDefinition.apiPostDataScript;
                                                    let dataName = "responseData";
                                                    let dataValue = responseDataFromAPI;
                                                    responseDataFromAPI = LDH.EvaluateJavaScriptForDataShaping(javascript, dataName,
                                                        dataValue, dataViewId);
                                                }
                                                that.populateDataIntoFields(data, responseDataFromAPI);
                                                $("#gridViewToobar_" + dataViewId).hide();
                                            }, function (error, sessionTimeout) {
                                                if (sessionTimeout !== undefined && sessionTimeout === true) {
                                                    LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                                                } else {
                                                    LRH.ShowToast("Failed to process your request. " +
                                                        "Please check your network connection status and try again.", "error", 5000);
                                                }
                                                $("#gridViewToobar_" + dataViewId).hide();
                                            }, postTemplate, "not-required");
                                        } else {
                                            $("#gridViewToobar_" + dataViewId).show();
                                            LeopardAjaxHelper.GenericHttpRequest("get", odataQuery, null, function (response) {
                                                responseDataFromAPI = response;
                                                if (!LDH.IsObjectNull(that.formDefinition.apiPostDataScript) &&
                                                    !LDH.IsValueEmpty(that.formDefinition.apiPostDataScript)) {
                                                    let javascript = that.formDefinition.apiPostDataScript;
                                                    let dataName = "responseData";
                                                    let dataValue = responseDataFromAPI;
                                                    responseDataFromAPI = LDH.EvaluateJavaScriptForDataShaping(javascript, dataName,
                                                        dataValue, dataViewId);
                                                }
                                                that.populateDataIntoFields(data, responseDataFromAPI);
                                                $("#gridViewToobar_" + dataViewId).hide();
                                            }, function (error, sessionTimeout) {
                                                if (sessionTimeout !== undefined && sessionTimeout === true) {
                                                    LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                                                } else {
                                                    LRH.ShowToast("Failed to process your request. " +
                                                        "Please check your network connection status and try again.", "error", 5000);
                                                }
                                                $("#gridViewToobar_" + dataViewId).hide();
                                            }, false, true);
                                        }
                                    } else {
                                        if (!LDH.IsObjectNull(that.formDefinition.apiPostDataScript) &&
                                            !LDH.IsValueEmpty(that.formDefinition.apiPostDataScript)) {
                                            let javascript = that.formDefinition.apiPostDataScript;
                                            let dataName = "responseData";
                                            let dataValue = [];
                                            responseDataFromAPI = LDH.EvaluateJavaScriptForDataShaping(javascript, dataName,
                                                dataValue, dataViewId);
                                        }
                                        that.populateDataIntoFields(data, responseDataFromAPI);
                                    }
                                }
                            }
                        }
                    }
                }
            });
        }

        let interval = setInterval(function () {
            if (interval === null || that.uiObjectInstances === null ||
                that.uiObjectInstances === []) {
                return;
            }
            clearInterval(interval);
            that.valueOnChangedSendDataToChildView(null);
        }, 100);
    };

    populateDataIntoFields = (data, responseDataFromAPI) => {
        let that = this;

        if (!LDH.IsObjectNull(responseDataFromAPI) && typeof responseDataFromAPI === "object") {
            let responseKeys = Object.keys(responseDataFromAPI);
            if (LDH.IsObjectNull(data.dataFromSource)) {
                data.dataFromSource = [];
            }
            for (let x = 0; x < responseKeys.length; x++) {
                let keyValue = responseKeys[x];
                let keyData = responseDataFromAPI[keyValue];
                data.dataFromSource[keyValue] = keyData;
            }
        }

        if (!LDH.IsObjectNull(that.formDefinition.parameters) &&
            !LDH.IsValueEmpty(that.formDefinition.parameters)) {
            let parametersCloned = LDH.DeepClone(that.formDefinition.parameters);

            for (let x = 0; x < parametersCloned.length; x++) {
                let item = parametersCloned[x];
                if (!LDH.IsObjectNull(item.parentFieldName) && !LDH.IsValueEmpty(item.parentFieldName)) {
                    let sourceObjects = Object.keys(data.dataFromSource);
                    for (let k = 0; k < sourceObjects.length; k++) {
                        if (sourceObjects[k] === item.parentFieldName &&
                            (LDH.IsObjectNull(item.defaultValue) || LDH.IsValueEmpty(item.defaultValue))) {
                            item.fieldValue = data.dataFromSource[sourceObjects[k]];

                            let input = that.uiObjectInstances["formField_" +
                            item.fieldName + "Input_" + that.dataViewId];
                            if (!LDH.IsObjectNull(input) &&
                                (LDH.IsObjectNull(that.formDefinition.enableParentDataDisplay) ||
                                    that.formDefinition.enableParentDataDisplay)) {
                                input.option("value", item.fieldValue);
                            }
                            break;
                        }
                    }
                }
                if (!LDH.IsObjectNull(item.columnName) && !LDH.IsValueEmpty(item.columnName)) {
                    let sourceObjects = Object.keys(data.dataFromSource);
                    for (let k = 0; k < sourceObjects.length; k++) {
                        if (sourceObjects[k] === item.columnName &&
                            (LDH.IsObjectNull(sourceObjects[sourceObjects[k]]) ||
                                LDH.IsValueEmpty(sourceObjects[sourceObjects[k]]))) {

                            let input = that.uiObjectInstances["formField_" +
                            item.fieldName + "Input_" + that.dataViewId];
                            if (!LDH.IsObjectNull(input)) {
                                input.option("value", data.dataFromSource[sourceObjects[k]]);
                            }
                            break;
                        }
                    }
                }
            }
            that.formDefinition.parameters = parametersCloned;
        }
    }

    clearFormDataAndAutoFocus = (dataViewId, isClearFormData) => {
        let that = this;
        let firstInputbox = false;

        if (LDH.IsObjectNull(isClearFormData) || isClearFormData) {
            let keys = Object.keys(that.uiObjectInstances);
            let resetValues = [];

            for (let k = 0; k < keys.length; k++) {
                if (!keys[k].toString().startsWith("formField_")) {
                    continue;
                }
                let inputReadOnly = that.uiObjectInstances[keys[k]].option("readOnly");

                let isClearDataOnFormSubmit = that.uiObjectInstances[keys[k]].option("inputClearDataOnFormSubmit");
                if (LDH.IsObjectNull(isClearDataOnFormSubmit) || LDH.IsValueEmpty(isClearDataOnFormSubmit)) {
                    isClearDataOnFormSubmit = true;
                }

                let shapingLogic = that.uiObjectInstances[keys[k]].option("defaultValueShapingLogic");
                if (LDH.IsObjectNull(shapingLogic) || LDH.IsValueEmpty(shapingLogic)) {
                    shapingLogic = "";
                }

                let lockEnabled = that.uiObjectInstances[keys[k]].option("inputManualLockEnabled");
                if (LDH.IsObjectNull(lockEnabled) || LDH.IsValueEmpty(lockEnabled)) {
                    lockEnabled = false;
                }

                let defaultValue = that.uiObjectInstances[keys[k]].option("inputDefaultValue");
                if (LDH.IsObjectNull(defaultValue) || LDH.IsValueEmpty(defaultValue)) {
                    defaultValue = "";
                }
                defaultValue = LDH.FilterMacro(defaultValue, null, null);

                if (isClearDataOnFormSubmit && ((lockEnabled && !inputReadOnly)) || !lockEnabled) {
                    if (!LDH.IsObjectNull(shapingLogic) && !LDH.IsValueEmpty(shapingLogic)) {
                        let javascript = shapingLogic;
                        let dataName = "data";
                        let dataValue = {
                            fieldValue: null,
                            formData: that.formDataToSubmit,
                            parameterData: null
                        };
                        defaultValue = LDH.EvaluateJavaScriptForDataShaping(javascript,
                            dataName, dataValue, that.props.dataViewId, null);

                        defaultValue = LDH.FilterMacro(defaultValue, null, null);
                    }
                    if (!LDH.IsObjectNull(defaultValue) && !LDH.IsValueEmpty(defaultValue)) {
                        resetValues.push({
                            control: that.uiObjectInstances[keys[k]],
                            value: defaultValue
                        })
                    }
                } else {
                    resetValues.push({
                        control: that.uiObjectInstances[keys[k]],
                        value: that.uiObjectInstances[keys[k]].option("value")
                    })
                }
                if (firstInputbox === false) {
                    that.uiObjectInstances[keys[k]].focus();
                }
                firstInputbox = true;
            }

            ValidationEngine.resetGroup("validation-group-" + dataViewId);

            for (let i = 0; i < resetValues.length; i++) {
                resetValues[i].control.option("value", resetValues[i].value);
            }

            setTimeout(function () {
                for (let k2 = 0; k2 < keys.length; k2++) {
                    if (!keys[k2].toString().startsWith("formField_")) {
                        continue;
                    }
                    if (!that.uiObjectInstances[keys[k2]].option("inputDefaultAutoFocus")) {
                        continue;
                    }
                    that.uiObjectInstances[keys[k2]].focus();
                }
            }, 100);
        }
    };

    actionItemSendDataToView = (data) => {
        let listeners = LeopardStaticUIConfig.Global_DashboardDataViewSiteAccessRegister;
        let persistentId = data.sendDataToViewByPersistentId;
        if (LDH.IsObjectNull(persistentId) || LDH.IsValueEmpty(persistentId)) {
            return;
        }

        for (let v = 0; v < listeners.length; v++) {
            if (listeners[v].dataViewPersistentId === persistentId) {
                if (LDH.IsObjectNull(listeners[v]) || LDH.IsObjectNull(listeners[v].instance)) {
                    continue;
                }

                let keys = Object.keys(data.rowData);
                for (let i = 0; i < keys.length; i++) {
                    if (LDH.IsObjectNull(data.rowData[keys[i]])) {
                        data.rowData[keys[i]] = "";
                    }
                }

                listeners[v].callback({
                    dataFromSource: data.rowData,
                    dataFromParent: this.selectedParentViewData,
                    dataViewType: "formeditor",
                    parentGridDef: data.definition,
                    actionType: "send-data-to-childview",
                    commandLinkData: data.commandLinkData
                });
            }
        }
    };

    valueOnChangedSendDataToChildView = (data) => {
        let that = this;

        for (let i = 0; i < this.relationshipsLinkedToDataView.length; i++) {
            let pId = this.relationshipsLinkedToDataView[i].parentDataViewId;
            let cId = this.relationshipsLinkedToDataView[i].childDataViewId;
            let features = this.relationshipsLinkedToDataView[i].interactiveFeatures;
            let dataSourceId = this.relationshipsLinkedToDataView[i].dataSourceId;
            let listeners = LeopardStaticUIConfig.Global_DashboardDataViewListeners;
            let childDashboardItemId = cId.split(":")[0];
            if (childDashboardItemId === this.props.dataViewId) continue;

            let rowData = {
                parameterData: [],
                formData: this.formDataToSubmit,
                fieldData: data
            };

            let keys = Object.keys(that.uiObjectInstances);
            for (let j = 0; j < keys.length; j++) {
                if (keys[j].indexOf("formField_") === -1) {
                    continue;
                }
                let inputValue = that.uiObjectInstances[keys[j]].option("value");
                if (LDH.IsObjectNull(inputValue) || LDH.IsValueEmpty(inputValue)) {
                    inputValue = "";
                }
                rowData.parameterData.push({
                    parameterName: keys[j],
                    parameterValue: inputValue
                })
            }

            for (let v = 0; v < listeners.length; v++) {
                if (listeners[v].dashboardItemId === childDashboardItemId) {
                    if (LDH.IsObjectNull(listeners[v].instance)) {
                        continue;
                    }
                    try {
                        if (!LDH.IsObjectNull(listeners[v].instance) &&
                            listeners[v].instance != "blank" &&
                            listeners[v].instance.NAME !== "dxScheduler") {
                            listeners[v].instance.clearSelection();
                        }
                        listeners[v].callback({
                            dataFromSource: rowData,
                            parentDataViewId: pId,
                            childDataViewId: cId,
                            features,
                            dataSourceId,
                            dataViewType: "chart"
                        });
                    } catch (ex) {
                        console.log("Failed to call clearSelection.");
                    }
                }
            }
        }
    };

    clearChildViewDataSendMessage = (data) => {
        let dataViewPersistentId = data.dataViewPersistentId;

        let dataSendToView = {};
        dataSendToView["clearChildViewData"] = data.clearChildViewData;

        let listeners = LeopardStaticUIConfig.Global_DashboardDataViewSiteAccessRegister;
        if (LDH.IsObjectNull(dataViewPersistentId) || LDH.IsValueEmpty(dataViewPersistentId)) {
            return;
        }
        for (let v = 0; v < listeners.length; v++) {
            if (listeners[v].dataViewPersistentId === dataViewPersistentId) {
                if (LDH.IsObjectNull(listeners[v]) || LDH.IsObjectNull(listeners[v].instance)) {
                    continue;
                }
                listeners[v].callback({
                    dataFromSource: null,
                    dataFromParent: null,
                    dataViewType: "formeditor",
                    parentGridDef: null,
                    actionType: "clear-data-childview"
                });
            }
        }
    };

    sendDataToViewLogic = (dataViewPersistentId, sendDataToViewLogic, thisComp, documentData) => {
        if (!LDH.IsObjectNull(dataViewPersistentId) && !LDH.IsValueEmpty(dataViewPersistentId)) {
            let dataSendToView = {};
            dataSendToView["formData"] = thisComp.formDataToSubmit;

            for (let i = 0; i < thisComp.formDefinition.parameters.length; i++) {
                let parameterData = [];
                let keys = Object.keys(thisComp.uiObjectInstances);
                for (let j = 0; j < keys.length; j++) {
                    if (keys[j].indexOf("formField_") === -1) {
                        continue;
                    }
                    parameterData.push({
                        parameterName: keys[j],
                        parameterValue: thisComp.uiObjectInstances[keys[j]].option("value")
                    })
                }
                dataSendToView["parameterData"] = parameterData;
            }
            dataSendToView["documentData"] = documentData;

            if (!LDH.IsObjectNull(sendDataToViewLogic) && !LDH.IsValueEmpty(sendDataToViewLogic)) {
                let javascript = sendDataToViewLogic;
                let dataName = "data";
                let dataValue = dataSendToView;
                let resultData = LDH.EvaluateJavaScriptForDataShaping(javascript, dataName,
                    dataValue, thisComp.dataViewId, null);

                thisComp.actionItemSendDataToView({
                    commandLinkData: resultData.commandLinkData,
                    rowData: resultData.rowData,
                    definition: resultData.definition,
                    sendDataToViewByPersistentId: dataViewPersistentId
                });
            }
        }
    };

    prepareDataPostList = (dataPostLogic, thisComp) => {
        let dataSendToView = {};
        dataSendToView["formData"] = thisComp.formDataToSubmit;

        for (let i = 0; i < thisComp.formDefinition.parameters.length; i++) {
            let parameterData = [];
            let keys = Object.keys(thisComp.uiObjectInstances);
            for (let j = 0; j < keys.length; j++) {
                if (keys[j].indexOf("formField_") === -1) {
                    continue;
                }
                parameterData.push({
                    parameterName: keys[j],
                    parameterValue: thisComp.uiObjectInstances[keys[j]].option("value")
                })
            }
            dataSendToView["parameterData"] = parameterData;
        }

        let javascript = dataPostLogic;
        let dataName = "data";
        let dataValue = dataSendToView;
        return LDH.EvaluateJavaScriptForDataShaping(javascript, dataName,
            dataValue, thisComp.dataViewId, null);
    };

    sendPhotosToS3 = (thisComp, sendPhotoToS3Logic, callback) => {
        let photosToSubmit = window["leopard-popup-photowidget-data-" + thisComp.dataViewId];
        if (LDH.IsObjectNull(photosToSubmit)) photosToSubmit = [];

        let S3_BUCKET = LeopardStaticUIConfig.S3BucketNameForAttachmentStore;
        let REGION = LeopardCognitoConfig.AmplifyAuthAndAPIConfig.Auth.region;
        let CREDENTIAL = {
            accessKeyId: window.userCredential.accessKeyId,
            secretAccessKey: window.userCredential.secretAccessKey,
            sessionToken: window.userCredential.sessionToken
        };

        const AWS = require('aws-sdk');
        AWS.config.update({credentials: CREDENTIAL});
        let S3 = new AWS.S3({region: REGION});

        let params = {};
        if (!LDH.IsObjectNull(sendPhotoToS3Logic) && !LDH.IsValueEmpty(sendPhotoToS3Logic)) {
            let javascript = sendPhotoToS3Logic;
            let dataName = "data";
            let dataValue = {
                s3Bucket: S3_BUCKET,
                region: REGION,
                accessKeyId: window.userCredential.accessKeyId,
                secretAccessKey: window.userCredential.secretAccessKey,
                photos: photosToSubmit,
                dataViewId: thisComp.dataViewId
            };

            params = LDH.EvaluateJavaScriptForDataShaping(javascript,
                dataName, dataValue, thisComp.dataViewId);
        }

        for (let k = 0; k < params.length; k++) {
            if (params[k].Key.indexOf("/IMG/") > -1 && params[k].Metadata.ParentType === "Item") {
                for (let v1 = 0; v1 < photosToSubmit.length; v1++) {
                    if ("cc_" + photosToSubmit[v1].id !== params[k].Metadata.AttachmentId) {
                        continue;
                    }
                    params[k].Body = photosToSubmit[v1]["imageData"];
                }
            }
            if (params[k].Key.indexOf("/TN/") > -1 && params[k].Metadata.ParentType === "Item") {
                for (let v1 = 0; v1 < photosToSubmit.length; v1++) {
                    if ("cc_" + photosToSubmit[v1].id !== params[k].Metadata.AttachmentId) {
                        continue;
                    }
                    params[k].Body = photosToSubmit[v1]["thumbnailData"];
                }
            }
        }

        let eventChainDataViewId = "";
        for (let k = 0; k < params.length; k++) {
            if (!LDH.IsObjectNull(params[k].EventChainDataViewId) && !LDH.IsValueEmpty(params[k].EventChainDataViewId)) {
                eventChainDataViewId = params[k].EventChainDataViewId;
            }
            let eventChainPhotosToSubmit = window["leopard-popup-photowidget-data-" + eventChainDataViewId];
            if (LDH.IsObjectNull(eventChainPhotosToSubmit)) eventChainPhotosToSubmit = [];

            for (let w = 0; w < eventChainPhotosToSubmit.length; w++) {
                if (params[k].Metadata.AttachmentId === "cc_" + eventChainPhotosToSubmit[w].id &&
                    (LDH.IsObjectNull(eventChainPhotosToSubmit[w]["IsUploaded"]) ||
                        !eventChainPhotosToSubmit[w]["IsUploaded"])) {
                    if (params[k].Key.indexOf("/IMG/") > -1) {
                        params[k].Body = eventChainPhotosToSubmit[w]["imageData"];
                    }
                    if (params[k].Key.indexOf("/TN/") > -1) {
                        params[k].Body = eventChainPhotosToSubmit[w]["thumbnailData"];
                    }
                }
            }
            delete params[k].EventChainDataViewId;
        }

        if (!LDH.IsObjectNull(window["leopard-popup-photowidget-data-" + eventChainDataViewId])) {
            for (let v1 = 0; v1 < window["leopard-popup-photowidget-data-" + eventChainDataViewId].length; v1++) {
                window["leopard-popup-photowidget-data-" + eventChainDataViewId][v1]["IsUploaded"] = true;
            }
        }

        let promises = [];
        for (let j = 0; j < params.length; j++) {
            promises.push(S3.putObject(params[j]).promise());
        }
        Promise.all(promises).then(function () {
            window["leopard-popup-photowidget-data-" + thisComp.dataViewId] = null;

            $("#FormEditor_TopBar_PhotosIndicator_" + thisComp.dataViewId + " " +
                ".leopard-topmenu-indicator-number-text").text(0);
            $("#FormEditor_TopBar_PhotosIndicator_" + thisComp.dataViewId).hide();
            callback();
        });
    }

    formSubmissionSuccessCallback = (thisComp, definition, isSendDataToView, isClearForm, sendDataToViewByPersistentId,
                                     sendDataToViewLogic, successMessage, clearChildView) => {
        if (LDH.IsObjectNull(clearChildView)) clearChildView = true;

        if (clearChildView) {
            if (typeof window["datasource_subscriber_dataview_persistent_" + definition.dataViewPersistentId] !== "undefined") {
                delete window["datasource_subscriber_dataview_persistent_" + definition.dataViewPersistentId];
            }
        }
        if (isSendDataToView) {
            thisComp.sendDataToViewLogic(sendDataToViewByPersistentId, sendDataToViewLogic, thisComp, null);
        }
        if (isClearForm) {
            thisComp.clearFormDataAndAutoFocus(thisComp.dataViewId, isClearForm);
        }
        if (clearChildView) {
            thisComp.clearChildViewDataSendMessage({
                clearChildViewData: clearChildView,
                dataViewPersistentId: sendDataToViewByPersistentId,
                persistentId: definition.dataViewPersistentId
            });
        }
        if (!LDH.IsObjectNull(successMessage) && !LDH.IsValueEmpty(successMessage)) {
            LRH.ShowToast(successMessage, "success", 5000);
        }
    };

    formSubmissionErrorCallback = (thisComp, ex) => {
        $("#gridViewToobar_" + thisComp.dataViewId).hide();
        thisComp.uiObjectInstances["formeditor_topbar_update"].option("disabled", false);
        thisComp.uiObjectInstances["formeditor_topbar_create"].option("disabled", false);
        thisComp.uiObjectInstances["formeditor_topbar_cancel"].option("disabled", false);
        thisComp.uiObjectInstances["formeditor_topbar_done"].option("disabled", false);
        LRH.ShowToast("Failed to send data to the backend API.", "error", 5000);
    };

    formSubmissionLogic = (odataQuery, postDataScript, directory, filename, submissionType, definition, customDataPostLogic,
                           sendDataToViewPersistentId, sendDataToViewLogic, clearFormData, successMessage, clearChildView,
                           sendPhotoToS3Logic) => {
        let that = this;
        let requestBody = LDH.DeepClone(this.formDataToSubmit);

        let url = odataQuery;
        if (!LDH.IsObjectNull(odataQuery) && !LDH.IsValueEmpty(odataQuery)) {
            let javascript = odataQuery;
            let dataName = "parentRowData";
            let dataValue = {
                parentData: this.selectedParentViewData,
                grandParentData: this.selectedGrandParentViewData
            };
            url = LDH.FilterMacro(url);
            url = LDH.EvaluateJavaScriptForDataShaping(javascript,
                dataName, dataValue, that.dataViewId);
        }

        if (!LDH.IsObjectNull(postDataScript) && !LDH.IsValueEmpty(postDataScript)) {
            let javascript = postDataScript;
            let dataName = "formData";
            requestBody = LDH.EvaluateJavaScriptForDataShaping(javascript,
                dataName, requestBody, that.dataViewId);
        }

        $("#gridViewToobar_" + that.dataViewId).show();
        that.uiObjectInstances["formeditor_topbar_update"].option("disabled", true);
        that.uiObjectInstances["formeditor_topbar_create"].option("disabled", true);
        that.uiObjectInstances["formeditor_topbar_cancel"].option("disabled", true);
        that.uiObjectInstances["formeditor_topbar_done"].option("disabled", true);
        that.uiObjectInstances["formeditor_topbar_photos"].option("disabled", true);

        if (!LDH.IsObjectNull(directory) && !LDH.IsValueEmpty(directory) &&
            !LDH.IsObjectNull(filename) && !LDH.IsValueEmpty(filename)) {
            let profile = window.userProfile;
            let userId = LeopardDataHelper.GetUserIdFromUserProfile(profile);

            LeopardAjaxHelper.RetrieveDocumentFromS3(userId, filename, directory, function (documentData) {
                documentData = LDH.FilterMacro(documentData);

                let grandParentData = that.selectedParentViewData;
                if (!LDH.IsObjectNull(that.formDefinition.enableGrandParentData) &&
                    that.formDefinition.enableGrandParentData === true) {
                    grandParentData = that.selectedGrandParentViewData;
                }

                if (!LDH.IsObjectNull(grandParentData)) {
                    let parentKeys = Object.keys(grandParentData);

                    for (let k = 0; k < parentKeys.length; k++) {
                        let parentValue = grandParentData[parentKeys[k]];
                        documentData = LDH.ReplaceAll(documentData, "{Parent_" + parentKeys[k] + "}", parentValue);
                    }
                }

                let keys = Object.keys(requestBody);
                for (let v = 0; v < keys.length; v++) {
                    let formValue = requestBody[keys[v]];
                    documentData = LDH.ReplaceAll(documentData, "{" + keys[v] + "}", formValue);
                }
                documentData = JSON.parse(documentData);

                LeopardAjaxHelper.GridViewCRUD_InsertData(url, documentData, function () {
                    that.formSubmissionSuccessCallback(that, definition, true, clearFormData,
                        sendDataToViewPersistentId, sendDataToViewLogic, successMessage, clearChildView);
                    that.sendPhotosToS3(that, sendPhotoToS3Logic, function () {
                        $("#gridViewToobar_" + that.dataViewId).hide();
                        that.uiObjectInstances["formeditor_topbar_update"].option("disabled", false);
                        that.uiObjectInstances["formeditor_topbar_create"].option("disabled", false);
                        that.uiObjectInstances["formeditor_topbar_cancel"].option("disabled", false);
                        that.uiObjectInstances["formeditor_topbar_done"].option("disabled", false);
                        that.uiObjectInstances["formeditor_topbar_photos"].option("disabled", false);
                    });
                }, function (ex) {
                    that.formSubmissionErrorCallback(that, ex);
                }, false);
            }, function (ex) {
                that.formSubmissionErrorCallback(that, ex);
            });
        } else if (!LDH.IsObjectNull(customDataPostLogic) && !LDH.IsValueEmpty(customDataPostLogic)) {
            let docDataList = that.prepareDataPostList(customDataPostLogic, that);
            let docDataListStr = JSON.stringify(docDataList);
            docDataListStr = LDH.FilterMacro(docDataListStr, 20, null);
            docDataList = JSON.parse(docDataListStr);

            let processedCount = 0;
            for (let i = 0; i < docDataList.length; i++) {
                let urlPath = docDataList[i]["__ApiUrl"];
                let jsonData = docDataList[i];

                LeopardAjaxHelper.GridViewCRUD_InsertDataWithoutUserData(urlPath, jsonData, function () {
                    processedCount++;
                    if (processedCount >= docDataList.length) {
                        that.formSubmissionSuccessCallback(that, definition, true, clearFormData,
                            sendDataToViewPersistentId, sendDataToViewLogic, successMessage, clearChildView);
                        that.sendPhotosToS3(that, sendPhotoToS3Logic, function () {
                            $("#gridViewToobar_" + that.dataViewId).hide();
                            that.uiObjectInstances["formeditor_topbar_update"].option("disabled", false);
                            that.uiObjectInstances["formeditor_topbar_create"].option("disabled", false);
                            that.uiObjectInstances["formeditor_topbar_cancel"].option("disabled", false);
                            that.uiObjectInstances["formeditor_topbar_done"].option("disabled", false);
                            that.uiObjectInstances["formeditor_topbar_photos"].option("disabled", false);
                        });
                    }
                }, function (ex) {
                    processedCount++;
                    that.formSubmissionErrorCallback(that, ex);
                });
            }
        } else {
            LeopardAjaxHelper.GridViewCRUD_InsertData(url, requestBody, function () {
                that.formSubmissionSuccessCallback(that, definition, true, clearFormData,
                    sendDataToViewPersistentId, sendDataToViewLogic, successMessage, clearChildView);
                that.sendPhotosToS3(that, sendPhotoToS3Logic, function () {
                    $("#gridViewToobar_" + that.dataViewId).hide();
                    that.uiObjectInstances["formeditor_topbar_update"].option("disabled", false);
                    that.uiObjectInstances["formeditor_topbar_create"].option("disabled", false);
                    that.uiObjectInstances["formeditor_topbar_cancel"].option("disabled", false);
                    that.uiObjectInstances["formeditor_topbar_done"].option("disabled", false);
                    that.uiObjectInstances["formeditor_topbar_photos"].option("disabled", false);
                });
            }, function (ex) {
                that.formSubmissionErrorCallback(that, ex);
            });
        }
    };

    formSubmit = (e) => {
        let validationGroup = ValidationEngine.getGroupConfig("validation-group-" + this.props.dataViewId);
        let validate = validationGroup.validate();
        e.preventDefault();

        if (this.formSubmitMethod === "create" && validate.isValid) {
            let odataQuery = this.formDefinition.dataCreateODataQuery;
            let script = this.formDefinition.dataCreatePostDataScript;
            let directory = this.formDefinition.dataCreateTemplateDirectory;
            let filename = this.formDefinition.dataCreateTemplateFilename;
            let customLogic = this.formDefinition.dataCreateCustomDataPostLogic;
            let sendDataPersistentId = this.formDefinition.dataCreateSendDataToPersistentId;
            let sendDataLogic = this.formDefinition.dataCreateSendDataToViewLogic;
            let clearData = this.formDefinition.dataCreateClearFormData;
            let clearChildView = this.formDefinition.dataCreateClearChildViewData;
            let sendPhotoToS3Logic = this.formDefinition.dataCreatePhotoUploadS3Logic;
            if (LDH.IsObjectNull(clearData)) clearData = true;

            let successMessage = this.formDefinition.dataCreateCustomSuccessMessage;
            let method = this.formSubmitMethod;
            this.formSubmissionLogic(odataQuery, script, directory, filename, method, this.formDefinition,
                customLogic, sendDataPersistentId, sendDataLogic, clearData, successMessage, clearChildView,
                sendPhotoToS3Logic);
        } else if (this.formSubmitMethod === "update" && validate.isValid) {
            let odataQuery = this.formDefinition.dataUpdateODataQuery;
            let script = this.formDefinition.dataUpdatePostDataScript;
            let directory = this.formDefinition.dataUpdateTemplateDirectory;
            let filename = this.formDefinition.dataUpdateTemplateFilename;
            let customLogic = this.formDefinition.dataUpdateCustomDataPostLogic;
            let sendDataPersistentId = this.formDefinition.dataUpdateSendDataToPersistentId;
            let sendDataLogic = this.formDefinition.dataUpdateSendDataToViewLogic;
            let clearData = this.formDefinition.dataUpdateClearFormData;
            let clearChildView = this.formDefinition.dataUpdateClearChildViewData;
            let sendPhotoToS3Logic = this.formDefinition.dataUpdatePhotoUploadS3Logic;
            if (LDH.IsObjectNull(clearData)) clearData = true;

            let successMessage = this.formDefinition.dataUpdateCustomSuccessMessage;
            let method = this.formSubmitMethod;
            this.formSubmissionLogic(odataQuery, script, directory, filename, method, this.formDefinition,
                customLogic, sendDataPersistentId, sendDataLogic, clearData, successMessage, clearChildView,
                sendPhotoToS3Logic);
        } else if (this.formSubmitMethod === "done" && validate.isValid) {
            let odataQuery = this.formDefinition.dataFinaliseODataQuery;
            let script = this.formDefinition.dataFinalisePostDataScript;
            let directory = this.formDefinition.dataFinaliseTemplateDirectory;
            let filename = this.formDefinition.dataFinaliseTemplateFilename;
            let customLogic = this.formDefinition.dataFinaliseCustomDataPostLogic;
            let sendDataPersistentId = this.formDefinition.dataFinaliseSendDataToPersistentId;
            let sendDataLogic = this.formDefinition.dataFinaliseSendDataToViewLogic;
            let clearData = this.formDefinition.dataFinaliseClearFormData;
            let clearChildView = this.formDefinition.dataFinaliseClearChildViewData;
            let sendPhotoToS3Logic = this.formDefinition.dataFinalisePhotoUploadS3Logic;
            if (LDH.IsObjectNull(clearData)) clearData = true;

            let successMessage = this.formDefinition.dataFinaliseCustomSuccessMessage;
            let method = this.formSubmitMethod;
            this.formSubmissionLogic(odataQuery, script, directory, filename, method, this.formDefinition,
                customLogic, sendDataPersistentId, sendDataLogic, clearData, successMessage, clearChildView,
                sendPhotoToS3Logic);
        } else if (this.formSubmitMethod === "cancel" && validate.isValid) {
            let odataQuery = this.formDefinition.dataCancelODataQuery;
            let script = this.formDefinition.dataCancelPostDataScript;
            let directory = this.formDefinition.dataCancelTemplateDirectory;
            let filename = this.formDefinition.dataCancelTemplateFilename;
            let customLogic = this.formDefinition.dataCancelCustomDataPostLogic;
            let sendDataPersistentId = this.formDefinition.dataCancelSendDataToPersistentId;
            let sendDataLogic = this.formDefinition.dataCancelSendDataToViewLogic;
            let clearData = this.formDefinition.dataCancelClearFormData;
            let clearChildView = this.formDefinition.dataCancelClearChildViewData;
            let sendPhotoToS3Logic = this.formDefinition.dataCancelPhotoUploadS3Logic;
            if (LDH.IsObjectNull(clearData)) clearData = true;

            let successMessage = this.formDefinition.dataCancelCustomSuccessMessage;
            let method = this.formSubmitMethod;
            this.formSubmissionLogic(odataQuery, script, directory, filename, method, this.formDefinition,
                customLogic, sendDataPersistentId, sendDataLogic, clearData, successMessage, clearChildView,
                sendPhotoToS3Logic);
        }
    };

    formToolbar = (formDefinition) => {
        let that = this;
        return (
            <React.Fragment>
                <div className={"leopard-formeditor-toolbar"} style={{minHeight: "30px"}}>
                    <div style={{width: "100%"}}></div>
                    <span id={"gridViewToobar_" + that.dataViewId} className="leopard-gridview-dataloading">
                        <i className="fas fa-spinner fa-pulse" style={{color: "#FF8100", fontSize: "18px"}}></i>
                    </span>
                    <span style={{padding: "0 2px 0 0", display: "flex"}}>
                            <LeopardTooltipWithLink
                                controlVisible={(!LDH.IsObjectNull(formDefinition.showPrefillButton) &&
                                    !LDH.IsValueEmpty(formDefinition.showPrefillButton))
                                    ? formDefinition.showPrefillButton : false}
                                elementId={"FormEditor_TopBar_Prefill_" + that.dataViewId}
                                additionalClass={"FormEditor_TopBar_Prefill_Persist_" + formDefinition.dataViewPersistentId}
                                refName={"formeditor_topbar_prefill"}
                                refFunc={(e) => this.setUIInstance(e)}
                                labelText={"Prefill"} width={250} gridViewId={that.dataViewId}
                                onClick={(e) => this.formEditorPrefillOnClick({e, formDefinition})}/>
                    </span>
                    <span style={{padding: "0 2px 0 0", display: "flex"}}>
                            <LeopardTooltipWithLink
                                controlVisible={(!LDH.IsObjectNull(formDefinition.showPhotosButton) &&
                                    !LDH.IsValueEmpty(formDefinition.showPhotosButton))
                                    ? formDefinition.showPhotosButton : false}
                                elementId={"FormEditor_TopBar_Photos_" + that.dataViewId}
                                additionalClass={"FormEditor_TopBar_Photos_Persist_" + formDefinition.dataViewPersistentId}
                                refName={"formeditor_topbar_photos"}
                                refFunc={(e) => this.setUIInstance(e)}
                                labelText={"Photos"} width={250} gridViewId={that.dataViewId}
                                onClick={(e) => this.formEditorPhotosOnClick({e})}/>
                        <div id={"FormEditor_TopBar_PhotosIndicator_" + that.dataViewId}
                             className={"leopard-topmenu-indicator-number-container"}>
                            <div className={"leopard-topmenu-indicator-number-text"}>0</div>
                        </div>
                    </span>
                    <span style={{padding: "0 2px 0 0"}}>
                            <LeopardTooltipWithLink
                                controlVisible={(!LDH.IsObjectNull(formDefinition.showClearButton) &&
                                    !LDH.IsValueEmpty(formDefinition.showClearButton))
                                    ? formDefinition.showClearButton : false}
                                elementId={"FormEditor_TopBar_ClearFields_" + that.dataViewId}
                                additionalClass={"FormEditor_TopBar_ClearFields_Persist_" + formDefinition.dataViewPersistentId}
                                refName={"formeditor_topbar_clearfields"}
                                refFunc={(e) => this.setUIInstance(e)}
                                labelText={"Clear"} width={250} gridViewId={that.dataViewId}
                                onClick={(e) => this.formEditorClearOnClick({e})}/>
                    </span>
                    <span style={{padding: "0 2px 0 0"}}>
                            <LeopardTooltipWithLink
                                controlVisible={(LDH.IsObjectNull(formDefinition.dataCancelEnableControl) ||
                                    LDH.IsValueEmpty(formDefinition.dataCancelEnableControl))
                                    ? true : formDefinition.dataCancelEnableControl}
                                elementId={"FormEditor_TopBar_Cancel_" + that.dataViewId}
                                additionalClass={"FormEditor_TopBar_Cancel_Persist_" + formDefinition.dataViewPersistentId}
                                refName={"formeditor_topbar_cancel"}
                                refFunc={(e) => this.setUIInstance(e)}
                                labelText={"Cancel"} width={250} gridViewId={that.dataViewId}
                                onClick={(e) => this.formEditorCancelOnClick({e})}
                                useSubmitBehavior={true}/>
                    </span>
                    <span style={{padding: "0 2px 0 0"}}>
                            <LeopardTooltipWithLink
                                controlVisible={(LDH.IsObjectNull(formDefinition.dataFinaliseEnableControl) ||
                                    LDH.IsValueEmpty(formDefinition.dataFinaliseEnableControl))
                                    ? true : formDefinition.dataFinaliseEnableControl}
                                elementId={"FormEditor_TopBar_Done_" + that.dataViewId}
                                additionalClass={"FormEditor_TopBar_Done_Persist_" + formDefinition.dataViewPersistentId}
                                refName={"formeditor_topbar_done"}
                                refFunc={(e) => this.setUIInstance(e)}
                                labelText={"Done"} width={250} gridViewId={that.dataViewId}
                                onClick={(e) => this.formEditorDoneOnClick({e})}
                                useSubmitBehavior={true}/>
                    </span>
                    <span style={{padding: "0 2px 0 0"}}>
                            <LeopardTooltipWithLink
                                controlVisible={(LDH.IsObjectNull(formDefinition.dataUpdateEnableControl) ||
                                    LDH.IsValueEmpty(formDefinition.dataUpdateEnableControl))
                                    ? true : formDefinition.dataUpdateEnableControl}
                                elementId={"FormEditor_TopBar_Update_" + that.dataViewId}
                                additionalClass={"FormEditor_TopBar_Update_Persist_" + formDefinition.dataViewPersistentId}
                                refName={"formeditor_topbar_update"}
                                refFunc={(e) => this.setUIInstance(e)}
                                labelText={"Update"} width={250} gridViewId={that.dataViewId}
                                onClick={(e) => this.formEditorUpdateOnClick({e})}
                                useSubmitBehavior={true}/>
                    </span>
                    <span style={{padding: "0 2px 0 0"}}>
                            <LeopardTooltipWithLink
                                controlVisible={(LDH.IsObjectNull(formDefinition.dataCreateEnableControl) ||
                                    LDH.IsValueEmpty(formDefinition.dataCreateEnableControl))
                                    ? true : formDefinition.dataCreateEnableControl}
                                elementId={"FormEditor_TopBar_Create_" + that.dataViewId}
                                additionalClass={"FormEditor_TopBar_Create_Persist_" + formDefinition.dataViewPersistentId}
                                refName={"formeditor_topbar_create"}
                                refFunc={(e) => this.setUIInstance(e)}
                                labelText={"Create"} width={250} gridViewId={that.dataViewId}
                                onClick={(e) => this.formEditorCreateOnClick({e})}
                                useSubmitBehavior={true}/>
                    </span>
                </div>
            </React.Fragment>
        )
    };

    formEditorCreateOnClick = (e) => {
        this.formSubmitMethod = "create";
    };

    formEditorUpdateOnClick = (e) => {
        this.formSubmitMethod = "update";
    };

    formEditorCancelOnClick = (e) => {
        this.formSubmitMethod = "cancel";
    };

    formEditorClearOnClick = (e) => {
        this.clearFormDataAndAutoFocus(this.dataViewId, true);
    };

    addPhotoGalleryPopupInstance = (e) => {
        if (LDH.IsObjectNull(e)) return;
        this.uiObjectInstances["popupPhotoGalleryInstance"] = e.instance;
    };

    formEditorPhotosOnClick = (e) => {
        if ($("#FormEditor_TopBar_Photos_" + this.dataViewId).hasClass("leopard-ui-disabled")) {
            return;
        }
        let data = {
            data: {
                "showThumbnails": true,
                "dataSourceId": "",
                "columnType": "photo-gallery",
                "reportDataViewId": "",
                "allowUploadPhotos": true
            },
            e: {
                row: {
                    data: {}
                }
            }
        };
        window.Global_PopupCustomColumnData = data;
        let instance = this.uiObjectInstances["popupPhotoGalleryInstance"];
        instance.option("visible", true);
    };

    formEditorPrefillOnClick = (e) => {
        let data = {
            definition: {
                "showPrefillButton": e.formDefinition.showPrefillButton,
                "prefillPostDataProcessingLogic": e.formDefinition.prefillPostDataProcessingLogic
            },
            data: {}
        };
        window.Global_PopupTempData = data;
        let instance = this.uiObjectInstances["popupPrefillInstance"];
        window.Global_PopupTempObjectInstance = instance;
        instance.option("visible", true);
    };

    formEditorDoneOnClick = (e) => {
        this.formSubmitMethod = "done";
    };

    customValidationRuleCallback = (data) => {
        let instances = this.uiObjectInstances;
        return LRH.BindValidationRulesToUIObjectLite(data, instances, ".dx-item.dx-box-item");
    };

    generateValidationRules = (data, fieldName, parameter, inputFieldName, thisComp) => {
        if (parameter.isReadOnly === true) return null;


        let jsonData = (LDH.IsObjectNull(data) || LDH.IsValueEmpty(data)) ? [] : JSON.parse(data);
        return jsonData.map(function (vItem, i) {
            let key = "validationRules_" + fieldName + "_patternRule_" + i;
            if (vItem.regex === "required" || vItem.regex === "[required]") {
                return <RequiredRule key={key} validationCallback={(e) => thisComp.customValidationRuleCallback({
                    e, input: fieldName, regex: vItem.regex, error: vItem.error,
                    inputFieldName: inputFieldName
                })} type="custom"></RequiredRule>
            } else {
                return (
                    <PatternRule key={key} message={vItem.error} pattern={vItem.regex}
                                 validationCallback={(e) => thisComp.customValidationRuleCallback({
                                     e, input: fieldName, regex: vItem.regex, error: vItem.error,
                                     inputFieldName: inputFieldName
                                 })} type="custom"/>
                )
            }
        });
    };

    manualLockOnClick = (data, thisComp) => {
        let $lock = $("." + data.lockClass);
        if ($lock.hasClass("locked")) {
            $lock.removeClass("locked").text("Lock");
            thisComp.uiObjectInstances[data.target].option("readOnly", false);
            thisComp.uiObjectInstances[data.target].option("elementAttr", {class: ""});
            thisComp.uiObjectInstances[data.target].option("focusStateEnabled", true);
        } else {
            $lock.addClass("locked").text("Unlock");
            thisComp.uiObjectInstances[data.target].option("readOnly", true);
            thisComp.uiObjectInstances[data.target].option("elementAttr", {
                class: "leopard-readonly-redtext"
            });
            thisComp.uiObjectInstances[data.target].option("focusStateEnabled", false);
        }
    };

    renderBoxItems = (rowIndex, totalColumnCount, formDefinition) => {
        let that = this;
        let result = [];
        let currentIndexCount = 0;

        for (let i = 0; i < formDefinition.parameters.length; i++) {
            let parameter = formDefinition.parameters[i];
            if (!LDH.IsObjectNull(parameter["rendered"]) && parameter["rendered"]) {
                continue;
            }
            if (currentIndexCount >= totalColumnCount) {
                continue;
            }
            parameter["rendered"] = true;
            currentIndexCount++;

            if (!LDH.IsObjectNull(parameter.defaultValue) && !LDH.IsValueEmpty(parameter.defaultValue)) {
                parameter.defaultValue = LDH.FilterMacro(parameter.defaultValue, null, null);
            }

            if (!LDH.IsObjectNull(parameter.defaultValueShapingLogic) && !LDH.IsValueEmpty(parameter.defaultValueShapingLogic)) {
                let javascript = parameter.defaultValueShapingLogic;
                let dataName = "data";
                let dataValue = {
                    defaultValue: parameter.defaultValue
                };
                parameter.defaultValue = LDH.EvaluateJavaScriptForDataShaping(javascript, dataName,
                    dataValue, that.dataViewId, null);
            }

            // DO NOT set the "focusStateEnabled" property directly in HTML attribute.
            setTimeout(function () {
                let focusStateEnabled = false;
                if (LDH.IsObjectNull(parameter.allowFocusOnTab) || parameter.allowFocusOnTab) {
                    focusStateEnabled = true;
                }
                let uiObj = that.uiObjectInstances["formField_" + parameter.fieldName + "Input_" + that.dataViewId];
                if (!LDH.IsObjectNull(parameter.manualLockEnabled) && parameter.manualLockEnabled && typeof uiObj !== "undefined" &&
                    uiObj.option("readOnly")) {
                    focusStateEnabled = false;
                }
                uiObj.option("focusStateEnabled", focusStateEnabled);
            }, 100);

            if (LDH.IsObjectNull(parameter.fieldType) || LDH.IsValueEmpty(parameter.fieldType) || parameter.fieldType === "textbox") {
                result.push(
                    <Item ratio={1} key={"row_" + i}>
                        <div
                            className={"formeditor_" + that.dataViewId + "_" + parameter.fieldName + " " + "formeditorinput_" + parameter.fieldName}
                            style={{paddingLeft: "20px"}}>
                            <div style={{paddingBottom: "5px", marginTop: "10px"}}>
                                {parameter.fieldDisplayName}
                            </div>
                            <div className={"formField_container"} style={{display: "flex"}}>
                                <TextBox readOnly={parameter.isReadOnly} style={{width: "100%"}} showClearButton={true}
                                         defaultValue={LDH.IsObjectNull(parameter.defaultValue) ? "" : parameter.defaultValue}
                                         id={"formField_" + parameter.fieldName + "Input_" + that.dataViewId}
                                         onFocusIn={(e) => that.inputFieldOnFocusIn({
                                             id: "formField_" + parameter.fieldName + "Input_" + that.dataViewId,
                                             e: e,
                                             parameter: parameter
                                         })}
                                         className={(LDH.IsObjectNull(parameter.defaultAutoFocus) || !parameter.defaultAutoFocus) ? "" : "autofocus_field"}
                                         onKeyDown={(e) => that.inputFieldOnKeyDown({
                                             fieldName: parameter.columnName,
                                             fieldValue: e.value,
                                             parameter: parameter,
                                             dataViewId: that.dataViewId,
                                             formParameters: formDefinition.parameters,
                                             e: e
                                         })}
                                         onValueChanged={
                                             (e) => that.inputFieldValueOnChanged({
                                                 fieldName: parameter.columnName,
                                                 fieldValue: e.value,
                                                 parameter: parameter,
                                                 dataViewId: that.dataViewId,
                                                 formParameters: formDefinition.parameters
                                             })}
                                         ref={(e) => that.setInputFieldInstance({
                                             e,
                                             input: "formField_" + parameter.fieldName + "Input_" + that.dataViewId,
                                             defaultValueShapingLogic: parameter.defaultValueShapingLogic,
                                             defaultValue: parameter.defaultValue,
                                             defaultAutoFocus: parameter.defaultAutoFocus,
                                             clearDataOnFormSubmit: parameter.clearDataOnFormSubmit,
                                             manualLockEnabled: parameter.manualLockEnabled
                                         })}>
                                    <Validator validationGroup={"validation-group-" + that.dataViewId}>
                                        {(LDH.IsObjectNull(parameter.validationRules) || LDH.IsValueEmpty(parameter.validationRules)) ? null :
                                            that.generateValidationRules(parameter.validationRules, parameter.fieldName, parameter,
                                                "formField_" + parameter.fieldName + "Input_" + that.dataViewId, that)}
                                    </Validator>
                                </TextBox>
                                {
                                    !LDH.IsObjectNull(parameter.manualLockEnabled) && parameter.manualLockEnabled ?
                                        <div
                                            className={"leopard-generic-hyperlink formfield_manual_lock_" + that.dataViewId + "_" + parameter.fieldName}
                                            style={{paddingLeft: "10px"}} onClick={(e) => that.manualLockOnClick({
                                            lockClass: "formfield_manual_lock_" + that.dataViewId + "_" + parameter.fieldName,
                                            target: "formField_" + parameter.fieldName + "Input_" + that.dataViewId
                                        }, that)
                                        }>Lock</div> : null
                                }
                            </div>
                        </div>
                    </Item>);
            } else if (parameter.fieldType === "date" || parameter.fieldType === "datetime") {
                result.push(
                    <Item ratio={1} key={"row_" + i}>
                        <div
                            className={"formeditor_" + that.dataViewId + "_" + parameter.fieldName + " " + "formeditorinput_" + parameter.fieldName}
                            style={{paddingLeft: "20px"}}>
                            <div style={{paddingBottom: "5px", marginTop: "10px"}}>
                                {parameter.fieldDisplayName}
                            </div>
                            <div className={"formField_container"} style={{display: "flex"}}>
                                <DateBox style={{width: "100%"}}
                                         type={parameter.fieldType} readOnly={parameter.isReadOnly}
                                         displayFormat={(LDH.IsObjectNull(parameter.dateFormat) || LDH.IsValueEmpty(parameter.dateFormat)) ? "" : parameter.dateFormat}
                                         defaultValue={LDH.IsObjectNull(parameter.defaultValue) ? null : parameter.defaultValue}
                                         className={(LDH.IsObjectNull(parameter.defaultAutoFocus) || !parameter.defaultAutoFocus) ? "" : "autofocus_field"}
                                         onFocusIn={(e) => that.inputFieldOnFocusIn({
                                             id: "formField_" + parameter.fieldName + "Input_" + that.dataViewId,
                                             e: e
                                         })}
                                         id={"formField_" + parameter.fieldName + "Input_" + that.dataViewId}
                                         onKeyDown={(e) => that.inputFieldOnKeyDown({
                                             fieldName: parameter.columnName,
                                             fieldValue: e.value,
                                             parameter: parameter,
                                             dataViewId: that.dataViewId,
                                             formParameters: formDefinition.parameters,
                                             e: e
                                         })}
                                         onValueChanged={(e) => that.inputFieldValueOnChanged({
                                             fieldName: parameter.columnName,
                                             fieldValue: e.value,
                                             parameter: parameter,
                                             dataViewId: that.dataViewId,
                                             formParameters: formDefinition.parameters
                                         })}
                                         ref={(e) => that.setInputFieldInstance({
                                             e,
                                             input: "formField_" + parameter.fieldName + "Input_" + that.dataViewId,
                                             defaultValueShapingLogic: parameter.defaultValueShapingLogic,
                                             defaultValue: parameter.defaultValue,
                                             defaultAutoFocus: parameter.defaultAutoFocus,
                                             clearDataOnFormSubmit: parameter.clearDataOnFormSubmit,
                                             manualLockEnabled: parameter.manualLockEnabled
                                         })}>
                                    <Validator validationGroup={"validation-group-" + that.dataViewId}>
                                        {(LDH.IsObjectNull(parameter.validationRules) || LDH.IsValueEmpty(parameter.validationRules)) ? null :
                                            that.generateValidationRules(parameter.validationRules, parameter.fieldName, parameter,
                                                "formField_" + parameter.fieldName + "Input_" + that.dataViewId, that)}
                                    </Validator>
                                </DateBox>
                                {
                                    !LDH.IsObjectNull(parameter.manualLockEnabled) && parameter.manualLockEnabled ?
                                        <div
                                            className={"leopard-generic-hyperlink formfield_manual_lock_" + that.dataViewId + "_" + parameter.fieldName}
                                            style={{paddingLeft: "10px"}} onClick={(e) => that.manualLockOnClick({
                                            lockClass: "formfield_manual_lock_" + that.dataViewId + "_" + parameter.fieldName,
                                            target: "formField_" + parameter.fieldName + "Input_" + that.dataViewId
                                        }, that)
                                        }>Lock</div> : null
                                }
                            </div>
                        </div>
                    </Item>);
            } else if (parameter.fieldType === "numberbox") {
                result.push(
                    <Item ratio={1} key={"row_" + i}>
                        <div
                            className={"formeditor_" + that.dataViewId + "_" + parameter.fieldName + " " + "formeditorinput_" + parameter.fieldName}
                            style={{paddingLeft: "20px"}}>
                            <div style={{paddingBottom: "5px", marginTop: "10px"}}>
                                {parameter.fieldDisplayName}
                            </div>
                            <div className={"formField_container"} style={{display: "flex"}}>
                                <NumberBox style={{width: "100%"}}
                                           defaultValue={LDH.IsObjectNull(parameter.defaultValue) ? "" : parameter.defaultValue}
                                           id={"formField_" + parameter.fieldName + "Input_" + that.dataViewId}
                                           className={(LDH.IsObjectNull(parameter.defaultAutoFocus) || !parameter.defaultAutoFocus) ? "" : "autofocus_field"}
                                           showSpinButtons={false} readOnly={parameter.isReadOnly}
                                           showClearButton={true}
                                           onFocusIn={(e) => that.inputFieldOnFocusIn({
                                               id: "formField_" + parameter.fieldName + "Input_" + that.dataViewId,
                                               e: e
                                           })}
                                           format={(!LDH.IsObjectNull(parameter.allowDecimal) && parameter.allowDecimal === true) ? "" : "#"}
                                           min={(LDH.IsObjectNull(parameter.minValue) || LDH.IsValueEmpty(parameter.minValue)) ? -999999 : parameter.minValue}
                                           max={(LDH.IsObjectNull(parameter.maxValue) || LDH.IsValueEmpty(parameter.maxValue)) ? 999999 : parameter.maxValue}
                                           onKeyDown={(e) => that.inputFieldOnKeyDown({
                                               fieldName: parameter.columnName,
                                               fieldValue: e.value,
                                               parameter: parameter,
                                               dataViewId: that.dataViewId,
                                               formParameters: formDefinition.parameters,
                                               e: e
                                           })}
                                           onValueChanged={(e) => that.inputFieldValueOnChanged({
                                               fieldName: parameter.columnName,
                                               fieldValue: e.value,
                                               parameter: parameter,
                                               dataViewId: that.dataViewId,
                                               formParameters: formDefinition.parameters
                                           })}
                                           ref={(e) => that.setInputFieldInstance({
                                               e,
                                               input: "formField_" + parameter.fieldName + "Input_" + that.dataViewId,
                                               defaultValueShapingLogic: parameter.defaultValueShapingLogic,
                                               defaultValue: parameter.defaultValue,
                                               defaultAutoFocus: parameter.defaultAutoFocus,
                                               clearDataOnFormSubmit: parameter.clearDataOnFormSubmit,
                                               manualLockEnabled: parameter.manualLockEnabled
                                           })}>
                                    <Validator validationGroup={"validation-group-" + that.dataViewId}>
                                        {(LDH.IsObjectNull(parameter.validationRules) || LDH.IsValueEmpty(parameter.validationRules)) ? null :
                                            that.generateValidationRules(parameter.validationRules, parameter.fieldName, parameter,
                                                "formField_" + parameter.fieldName + "Input_" + that.dataViewId, that)}
                                    </Validator>
                                </NumberBox>
                                {
                                    !LDH.IsObjectNull(parameter.manualLockEnabled) && parameter.manualLockEnabled ?
                                        <div
                                            className={"leopard-generic-hyperlink formfield_manual_lock_" + that.dataViewId + "_" + parameter.fieldName}
                                            style={{paddingLeft: "10px"}} onClick={(e) => that.manualLockOnClick({
                                            lockClass: "formfield_manual_lock_" + that.dataViewId + "_" + parameter.fieldName,
                                            target: "formField_" + parameter.fieldName + "Input_" + that.dataViewId
                                        }, that)
                                        }>Lock</div> : null
                                }
                            </div>
                        </div>
                    </Item>);
            } else if (parameter.fieldType === "dropdown") {
                result.push(
                    <Item ratio={1} key={"row_" + i}>
                        <div
                            className={"formeditor_" + that.dataViewId + "_" + parameter.fieldName + " " + "formeditorinput_" + parameter.fieldName}
                            style={{paddingLeft: "20px"}}>
                            <div style={{paddingBottom: "5px", marginTop: "10px"}}>
                                {parameter.fieldDisplayName}
                            </div>
                            <div className={"formField_container"} style={{display: "flex"}}>
                                <SelectBox displayExpr={'name'} valueExpr={'id'} style={{width: "100%"}}
                                           defaultValue={LDH.IsObjectNull(parameter.defaultValue) ? "" : parameter.defaultValue}
                                           dataSource={LDH.IsObjectNull(parameter.dropdownValues) ? "" : parameter.dropdownValues}
                                           className={(LDH.IsObjectNull(parameter.defaultAutoFocus) || !parameter.defaultAutoFocus) ? "" : "autofocus_field"}
                                           readOnly={parameter.isReadOnly}
                                           onFocusIn={(e) => that.inputFieldOnFocusIn({
                                               id: "formField_" + parameter.fieldName + "Input_" + that.dataViewId,
                                               e: e
                                           })}
                                           id={"formField_" + parameter.fieldName + "Input_" + that.dataViewId}
                                           onKeyDown={(e) => that.inputFieldOnKeyDown({
                                               fieldName: parameter.columnName,
                                               fieldValue: e.value,
                                               parameter: parameter,
                                               dataViewId: that.dataViewId,
                                               formParameters: formDefinition.parameters,
                                               e: e
                                           })}
                                           onValueChanged={(e) => that.inputFieldValueOnChanged({
                                               fieldName: parameter.columnName,
                                               fieldValue: e.value,
                                               parameter: parameter,
                                               dataViewId: that.dataViewId,
                                               formParameters: formDefinition.parameters
                                           })}
                                           ref={(e) => that.setInputFieldInstance({
                                               e,
                                               input: "formField_" + parameter.fieldName + "Input_" + that.dataViewId,
                                               defaultValueShapingLogic: parameter.defaultValueShapingLogic,
                                               defaultValue: parameter.defaultValue,
                                               defaultAutoFocus: parameter.defaultAutoFocus,
                                               clearDataOnFormSubmit: parameter.clearDataOnFormSubmit,
                                               manualLockEnabled: parameter.manualLockEnabled
                                           })}>
                                    <Validator validationGroup={"validation-group-" + that.dataViewId}>
                                        {(LDH.IsObjectNull(parameter.validationRules) || LDH.IsValueEmpty(parameter.validationRules)) ? null :
                                            that.generateValidationRules(parameter.validationRules, parameter.fieldName, parameter,
                                                "formField_" + parameter.fieldName + "Input_" + that.dataViewId, that)}
                                    </Validator>
                                </SelectBox>
                                {
                                    !LDH.IsObjectNull(parameter.manualLockEnabled) && parameter.manualLockEnabled ?
                                        <div
                                            className={"leopard-generic-hyperlink formfield_manual_lock_" + that.dataViewId + "_" + parameter.fieldName}
                                            style={{paddingLeft: "10px"}} onClick={(e) => that.manualLockOnClick({
                                            lockClass: "formfield_manual_lock_" + that.dataViewId + "_" + parameter.fieldName,
                                            target: "formField_" + parameter.fieldName + "Input_" + that.dataViewId
                                        }, that)
                                        }>Lock</div> : null
                                }
                            </div>
                        </div>
                    </Item>);
            }
        }
        return result;
    };

    render() {
        let that = this;
        if (this.state.isWidgetInitialized === false) {
            return null;
        }
        let formDefinition = LDH.DeepClone(this.formDefinition);
        let totalColumnCount = formDefinition.columnCount;
        if (LDH.IsObjectNull(totalColumnCount) || LDH.IsValueEmpty(totalColumnCount)) {
            totalColumnCount = 4;
        }

        if (LDH.IsObjectNull(formDefinition.parameters)) {
            formDefinition.parameters = [];
        }

        for (let j = 0; j < formDefinition.parameters.length; j++) {
            formDefinition.parameters[j]["rendered"] = false;
            if (LDH.IsObjectNull(formDefinition.parameters[j]["displayOrder"]) ||
                LDH.IsValueEmpty(formDefinition.parameters[j]["displayOrder"])) {
                formDefinition.parameters[j]["displayOrder"] = 0;
            }
        }

        formDefinition.parameters.sort(function (a, b) {
            if (a.displayOrder < b.displayOrder) {
                return -1;
            }
            if (a.displayOrder > b.displayOrder) {
                return 1;
            }
            return 0;
        });

        return (
            <React.Fragment>
                <form onSubmit={this.formSubmit} style={{height: "calc(100% - 26px)", overflowY: "auto"}}>
                    {this.formToolbar(formDefinition)}
                    <div className={"leopard-formeditor-container"}>
                        {
                            formDefinition.parameters.map(function (parameter, index) {
                                return index % totalColumnCount === 0 ? (
                                    <Box direction="row" width="100%" key={"column_" + index}>
                                        {
                                            that.renderBoxItems(index, totalColumnCount, formDefinition)
                                        }
                                    </Box>) : null
                            })
                        }
                    </div>
                </form>
                <input type={"text"} className={"leopard-dummy_textbox_for_autofocus"}/>

                <LeopardAttachmentPopup
                    popupPhotoGalleryInstance={that.addPhotoGalleryPopupInstance} popupWidth={"1024px"}
                    addDisposablePopupInstances={(e) => that.addDisposablePopupInstances(e)}
                    popupButtonSaveOnClick={(e) => that.popupButtonSaveOnClick(e)}
                    popupHeight={"695px"} popupTitle={"Attachments"}
                    dataViewId={that.props.dataViewId}
                ></LeopardAttachmentPopup>

                <LeopardDataImportPopup
                    popupTitle={"Prefill"} contentHeight={"100%"} dataViewId={that.props.dataViewId}
                    popupWidth={"800px"} popupHeight={"80%"}
                    addDisposablePopupInstances={(e) => that.addDisposablePopupInstances(e)}
                    popupDataImportInstance={that.addPrefillPopupInstance}
                    popupOnClosed={(e, a, b) => that.prefillPopupOnClosed(e, a, b)}>
                </LeopardDataImportPopup>
            </React.Fragment>
        );
    }
}

export default LeopardFormWidgetEngine;