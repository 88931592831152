import React, {Component} from 'react';
import $ from 'jquery';
import LeopardSecurity from '../security/LeopardSecurity';
import {Analytics, Auth} from 'aws-amplify';
import LeopardStaticUIConfig from '../foundation/LeopardStaticUIConfig';
import {TextBox} from 'devextreme-react';
import LDH from "../helpers/LeopardDataHelper";
import LeopardCognitoConfig from "../foundation/LeopardCognitoConfig";

class LeopardUserLogin extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showLoginError: false,
            username: "",
            password: "",
            showLoadingProgress: false,
            validationError: ""
        }
    }

    componentDidMount = () => {
        let version = LDH.ReplaceAll(LeopardStaticUIConfig.ControlCentreVersion, ".", "");
        let loginCss =
            '<link rel="stylesheet" type="text/css" href="/css/metronic/style.bundle.css?v=' + version + '" />' +
            '<link rel="stylesheet" type="text/css" href="/css/leopard-theme-fonts.css?v=' + version + '" />' +
            '<link rel="stylesheet" type="text/css" href="/css/login.css?v=' + version + '" />';
        $("head").append(loginCss);

        if (localStorage.getItem('theme_manager') !== null &&
            localStorage.getItem('theme_manager') !== "") {
            let themeColors = JSON.parse(localStorage.getItem('theme_manager'));
            $("head").append(
                "<style style='text/css'>" +
                ":root {" +
                "   --mainColor: " + themeColors.MainColor + "; " +
                "   --shadeColor: " + themeColors.ShadeColor + "; " +
                "   --hoverColor: " + themeColors.HoverSelectionColor + "; " +
                "   --secondaryColor: " + themeColors.TopBarColor + "; " +
                "}" +
                "</style>");
        } else {
            $("head").append(
                "<style style='text/css'>" +
                ":root {" +
                "   --mainColor: #FF8100; " +
                "   --shadeColor: #FFF3E7; " +
                "   --hoverColor: #ffd8b5; " +
                "   --secondaryColor: #212529" +
                "}" +
                "</style>");
        }

        this.setState({
            showLoginError: false,
            username: "",
            password: "",
            showLoadingProgress: false,
            validationError: ""
        });
    };

    userNameOnValueChanged = (e) => {
        this.setState({username: e.value});
    };

    passwordOnValueChanged = (e) => {
        this.setState({password: e.value});
    };

    passwordOnEnterKey = (e) => {
        let keyCode = e.event.key;
        if (keyCode === "Enter") {
            this.AccountLoginButtonOnClick();
        }
    };

    AccountLoginButtonOnClick = () => {
        let that = this;
        let username = that.state.username;
        let password = that.state.password;

        that.setState({showLoadingProgress: true});
        that.setState({showLoginError: false});
        that.setState({validationError: ""});

        Analytics.configure({disabled: true});
        Auth.configure(LeopardCognitoConfig.AmplifyAuthAndAPIConfig);

        LeopardSecurity.UserLogin(username, password, function () {
            that.setState({showLoginError: false});
            that.setState({validationError: ""});
            window.location.reload();
        }, function () {
            that.setState({showLoginError: true});
            that.setState({showLoadingProgress: false});
            that.setState({validationError: "Login attempt unsuccessful."});
        }, this.props.isTestMode);
    };

    render() {
        if (this.props.authState === 'signIn') {
            let loggedOut = !LDH.IsObjectNull(window.isUserLoggedOut) && window.isUserLoggedOut;
            return (
                <React.Fragment>
                    <div className="leopard-loading-icon login-cover-progress-icon">
                        <i className="fas fa-spinner fa-pulse"></i>
                        <div className={"logging-out-text"}>Logging out...</div>
                    </div>
                    <div className="m--skin- m-header--fixed m-header--fixed-mobile m-aside-left--enabled m-aside-left--skin-dark m-aside-left--fixed m-aside-left--offcanvas
                        m-footer--push m-aside--offcanvas-default login-page-container"
                         style={{display: (loggedOut ? "none" : "block")}}>
                        <div className="m-grid m-grid--hor m-grid--root m-page">
                            <div
                                className="m-grid__item m-grid__item--fluid m-grid m-grid--hor m-login m-login--signin m-login--2 m-login-2--skin-3">
                                <div className="m-grid__item m-grid__item--fluid m-login__wrapper">
                                    <div className="m-login__container">
                                        <div className="m-login__logo">
                                            <img src="/css/images/LeopardLogo.png" width={"180px"}
                                                 alt="Leopard Systems"/>
                                        </div>
                                        <div className="m-login__signin">
                                            <div className="m-login__head">
                                                <div className="m-login__title">{"Control Centre"}</div>
                                            </div>
                                            <form className="m-login__form m-form" onSubmit={function () {
                                                return false;
                                            }}>
                                                <div className="form-group m-form__group"
                                                     style={{marginBottom: "10px"}}>
                                                    <TextBox maxLength={50} placeholder="Username" mode="text"
                                                             onValueChanged={(e) => this.userNameOnValueChanged(e)}
                                                             id="input_login_username"/>
                                                </div>
                                                <div className="form-group m-form__group">
                                                    <TextBox maxLength={50} placeholder="Password" mode="password"
                                                             onValueChanged={(e) => this.passwordOnValueChanged(e)}
                                                             onEnterKey={(e) => this.passwordOnEnterKey(e)}
                                                             id="input_login_password"/>
                                                </div>
                                                <div className="login-failed-text"
                                                     style={{display: (this.state.showLoginError) ? "block" : "none"}}>
                                                    {this.state.validationError}
                                                </div>
                                                <div className="m-login__form-action">
                                                    <div className={"leopard-loading-icon"}
                                                         style={{display: this.state.showLoadingProgress ? "block" : "none"}}>
                                                        <i className="fas fa-spinner fa-pulse"
                                                           style={{color: "#FF8100", fontSize: "25px"}}></i>
                                                    </div>
                                                    <button type="button" id="button_login"
                                                            className="btn btn-focus m-btn m-btn--pill m-btn--custom m-btn--air m-login__btn m-login__btn--primary button-login"
                                                            onClick={() => this.AccountLoginButtonOnClick()}>
                                                        Login
                                                    </button>
                                                </div>
                                                <div className="leopard-version-text">
                                                    Version: {LeopardStaticUIConfig.ControlCentreVersion}
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            );
        }
        return null;
    }
}

export default LeopardUserLogin;
