import React, {Component} from 'react';
import {connect} from "react-redux";
import SplitterLayout from 'react-splitter-layout';
import 'react-splitter-layout/lib/index.css';
import LeopardTooltipWithLink from "../datashaping/LeopardTooltipWithLink";
import LRH from "../helpers/LeopardReactHelper";
import LDH from "../helpers/LeopardDataHelper";
import LeopardDataHelper from "../helpers/LeopardDataHelper";
import $ from 'jquery';

import LeopardDocumentEditorEngine from "../foundation/LeopardDocumentEditorEngine";
import LeopardDocumentPreviewerEngine from "../foundation/LeopardDocumentPreviewerEngine";
import LeopardSecurity from "../security/LeopardSecurity";
import LeopardDocumentEditorDesigner from "../datashaping/LeopardDocumentEditorDesigner";
import LeopardStaticUIConfig from "../foundation/LeopardStaticUIConfig";
import LeopardAjaxHelper from "../helpers/LeopardAjaxHelper";
import {FileUploader} from "devextreme-react";

class LeopardDocumentEditorConfiguration extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isExpandDesigner: false,
            isDocumentEditorReadOnly: false,
            isHideDocumentEditor: false,
            isDataViewInitialized: false,
            documentDefinition: {}
        }
        this.uiObjectInstances = [];
        this.documentDataJSON = null;
        this.documentDataString = "";
        this.documentDataForEditor = "";
        this.relationships = [];
        this.relationshipsLinkedToDataView = [];
        this.initializedListeners = false;
        this.selectedParentViewData = null;
    }

    componentDidMount() {
        let that = this;
        let definition = null;
        if (!LDH.IsObjectNull(this.props.definition) &&
            !LDH.IsObjectNull(this.props.definition.documentDefinition)) {
            definition = this.props.definition.documentDefinition;
        } else if (!LDH.IsObjectNull(this.props.documentDefinition) &&
            !LDH.IsObjectNull(this.props.documentDefinition.documentDefinition)) {
            definition = this.props.documentDefinition.documentDefinition;
        } else if (!LDH.IsObjectNull(this.props.documentDefinition) &&
            !LDH.IsObjectNull(this.props.documentDefinition)) {
            definition = this.props.documentDefinition;
        }
        that.setState({
            isDataViewInitialized: true, documentDefinition: definition
        }, function () {
            that.initializeDashboardDataViewListeners();
            $("#leopard-document-editor-loading-progress-" + that.props.dataViewId).hide();
        });
    };

    reinitializeDocumentEditor(definition, staticJSONData) {
        let that = this;
        if (!LDH.IsObjectNull(staticJSONData)) {
            that.documentDataForEditor = staticJSONData;
            that.documentDataString = JSON.stringify(staticJSONData);
            that.documentDataJSON = JSON.stringify(staticJSONData);
        }

        that.setState({
            isDocumentEditorReadOnly: false, isHideDocumentEditor: true,
            isDataViewInitialized: true, documentDefinition: definition
        }, function () {
            // A workaround for re-generate JSON forms after the data has been changed.
            that.setState({isHideDocumentEditor: false}, function () {
                let $root = $(".document-editor_widget_" + that.props.dataViewId);
                $(".splitter-layout .layout-pane-primary", $root).removeClass("hide-scrollbar");
            });
            that.initializeDashboardDataViewListeners();
            $("#leopard-document-editor-loading-progress-" + that.props.dataViewId).hide();
        });

        setTimeout(function () {
            LRH.TriggerWindowResizeEvent();
        }, 100);
    };

    componentWillUnmount = () => {
        let uiObjectInstances = this.uiObjectInstances;
        LRH.DisposeUIInstancesFromList(uiObjectInstances);
    };

    setUIInstance = (data) => {
        if (data.e === undefined || data.e === null) {
            return;
        }
        let instances = this.uiObjectInstances;
        instances[data.name] = data.e;
    };

    initializeDashboardDataViewListeners = () => {
        let that = this;
        if (that.initializedListeners === true) {
            return;
        }
        that.initializedListeners = true;

        if (!that.state.isDataViewInitialized) return;
        that.relationships = that.props.relationships;
        let dashboardItemId = that.props.dataViewId;

        if (!LDH.IsObjectNull(dashboardItemId) && !LDH.IsValueEmpty(dashboardItemId) &&
            !LDH.IsObjectNull(that.relationships) &&
            that.relationshipsLinkedToDataView.length === 0) {
            let linkedList = LDH.GetRelationshipsByDashboardItemId(that.relationships,
                dashboardItemId);
            that.relationshipsLinkedToDataView = linkedList;
        }

        that.selectedParentViewData = null;
        if (!LDH.IsObjectNull(dashboardItemId) && !LDH.IsValueEmpty(dashboardItemId) &&
            !LDH.IsObjectNull(that.relationships)) {
            LeopardStaticUIConfig.Global_DashboardDataViewListeners.push({
                dashboardItemId,
                props: that.props,
                instance: "document-editor",
                callback(data) {
                    if (!LDH.IsObjectNull(that.props.definition.documentDefinition.parameters) &&
                        !LDH.IsValueEmpty(that.props.definition.documentDefinition.parameters)) {
                        let parametersCloned = LDH.DeepClone(that.props.definition.documentDefinition.parameters);

                        for (let x = 0; x < parametersCloned.length; x++) {
                            let item = parametersCloned[x];
                            if (!LDH.IsObjectNull(window["dataViewParam_" + that.props.dataViewId +
                            "_control_" + item.parameterName])) {
                                let controlId = window["dataViewParam_" + that.props.dataViewId +
                                "_control_" + item.parameterName];
                                data.dataFromSource[item.parameterName] = controlId;
                            }
                        }
                    }

                    for (let i = 0; i < data.features.length; i++) {
                        if (data.features[i] === "rowlink") {
                            that.selectedParentViewData = data.dataFromSource;
                            $("#leopard-document-editor-loading-progress-" + that.props.dataViewId).show();

                            if (LDH.IsObjectNull(data.dataFromSource)) {
                                //TODO: implement logic here.
                                $("#leopard-document-editor-loading-progress-" + that.props.dataViewId).hide();
                            } else {
                                LeopardAjaxHelper.RetrieveDocumentFromS3(data.dataFromSource.owner, data.dataFromSource.name,
                                    data.dataFromSource.type, function (documentData) {
                                        let definition = LDH.DeepClone(that.state.documentDefinition);
                                        let jsonData = {};
                                        try {
                                            jsonData = JSON.parse(documentData);
                                        } catch (ex) {
                                            jsonData = {};
                                        }
                                        jsonData = LDH.ConvertJsonDataNullValuesOnly(jsonData, false);
                                        if (data.dataFromSource.name.indexOf(".json") === -1) {
                                            $("#leopard-document-editor-loading-progress-" + that.props.dataViewId).hide();
                                            $(".document-editor_widget_" + that.props.dataViewId).hide();
                                            $(".document-editor-notjson-warning-" + that.props.dataViewId).show();
                                            return;
                                        }

                                        if (!LDH.IsJsonString(documentData)) {
                                            $("#leopard-document-editor-loading-progress-" + that.props.dataViewId).hide();
                                            $(".document-editor_widget_" + that.props.dataViewId).hide();
                                            $(".document-editor-notjson-warning-" + that.props.dataViewId).show();
                                            return;
                                        }

                                        $(".document-editor_widget_" + that.props.dataViewId).show();
                                        $(".document-editor-notjson-warning-" + that.props.dataViewId).hide();
                                        if (data.dataFromSource.type.toString().toLowerCase() !== "cc_dataview_config") {
                                            LeopardAjaxHelper.RetrieveDocumentFromS3(data.dataFromSource.owner, data.dataFromSource.name,
                                                data.dataFromSource.type.toString().toLowerCase(), function (documentData) {
                                                    definition = that.documentEditorSchemaParser(definition, documentData);
                                                    that.reinitializeDocumentEditor(definition, jsonData);
                                                });
                                        } else {
                                            definition = that.documentEditorSchemaParser(definition, documentData);
                                            that.reinitializeDocumentEditor(definition, jsonData);
                                        }
                                    }, function (error, sessionTimeout) {
                                        if (sessionTimeout !== undefined && sessionTimeout === true) {
                                            LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                                        } else {
                                            LRH.ShowToast("Failed to retrieve document.", "error", 5000);
                                        }
                                        $("#leopard-document-editor-loading-progress-" + that.props.dataViewId).hide();
                                    });
                            }
                        }
                    }
                }
            });
        }
    };

    documentEditorSchemaParser = (definition, documentData) => {
        try {
            let documentDataJSON = JSON.parse(documentData);
            if (!LDH.IsObjectNull(documentDataJSON.documentEditorDataSchema) &&
                !LDH.IsValueEmpty(documentDataJSON.documentEditorDataSchema)) {
                definition.documentEditorDataSchema = documentDataJSON.documentEditorDataSchema;
            } else {
                definition.documentEditorDataSchema = "";
            }

            if (!LDH.IsObjectNull(documentDataJSON.documentEditorUISchema) &&
                !LDH.IsValueEmpty(documentDataJSON.documentEditorUISchema)) {
                definition.documentEditorUISchema = documentDataJSON.documentEditorUISchema;
            } else {
                definition.documentEditorUISchema = "";
            }
        } catch (ex) {
            definition.documentEditorDataSchema = "";
            definition.documentEditorUISchema = "";
        }
        return definition;
    };

    documentEditorDesignerButtonOnClick = () => {
        if (LeopardSecurity.ShowErrorMessageForLeopardAdminFeature(this)) {
            return;
        }
        if (this.state.isExpandDesigner) {
            this.setState({isExpandDesigner: false}, function () {
                this.props.updateWindowDimensionsRequired();
            });
        } else {
            this.setState({isExpandDesigner: true}, function () {
                this.props.updateWindowDimensionsRequired();
            });
        }
    }

    schemaFileUploaderOnSelectedFileChanged = (data) => {
        let that = this;
        let reader = new FileReader();
        reader.onload = function (event) {
            try {
                let fileData = JSON.parse(event.target.result);
                let definition = null;
                if (!LDH.IsObjectNull(fileData.documentDefinition)) {
                    definition = fileData.documentDefinition;
                } else if (!LDH.IsObjectNull(fileData.dataViewPersistentId)) {
                    definition = fileData;
                }
                that.reinitializeDocumentEditor(definition);

                setTimeout(function () {
                    let instance = that.uiObjectInstances["exportDataViewFileUploader_Schema"].instance;
                    if (!LDH.IsObjectNull(instance)) instance.reset();
                }, 100);
            } catch (ex) {

            }
        };
        if (data.e.value.length > 0) {
            reader.readAsText(data.e.value[0]);
        }
    }

    dataFileUploaderOnSelectedFileChanged = (data) => {
        let that = this;
        let reader = new FileReader();
        reader.onload = function (event) {
            try {
                let fileData = JSON.parse(event.target.result);
                let definition = that.state.documentDefinition;
                that.reinitializeDocumentEditor(definition, fileData);
                setTimeout(function () {
                    let instance = that.uiObjectInstances["exportDataViewFileUploader_Data"].instance;
                    if (!LDH.IsObjectNull(instance)) instance.reset();
                }, 100);
            } catch (ex) {

            }
        };
        if (data.e.value.length > 0) {
            reader.readAsText(data.e.value[0]);
        }
    }

    documentDataPreviewerImportDataOnClick = () => {
        let $root = $(".document-editor_widget_" + this.props.dataViewId);
        $(".data_file_uploader .dx-fileuploader-button.dx-button.dx-button-normal", $root).trigger("click");
    }

    documentEditorImportButtonOnClick = () => {
        let $root = $(".document-editor_widget_" + this.props.dataViewId);
        $(".schema_file_uploader .dx-fileuploader-button.dx-button.dx-button-normal", $root).trigger("click");
    }

    documentEditorExportButtonOnClick = () => {
        let that = this;
        let documentRef = that.state.documentDefinition;
        documentRef.documentEditorDataReceivingLogic = this.documentDataString;
        LRH.DownloadStringToFile("DocSchemas.json", JSON.stringify(documentRef));
    }

    documentEditorSaveButtonOnClick = () => {
        let that = this;
        if (LDH.IsObjectNull(this.selectedParentViewData)) {
            return;
        }
        if (LDH.IsValueEmpty(this.selectedParentViewData.type) ||
            LDH.IsValueEmpty(this.selectedParentViewData.name)) {
            LRH.ShowToast("The directory and file name cannot be blank.", "error", 5000);
            return;
        }
        let fileContent = this.documentDataString;
        $(".document-editor-saveprogress-" + that.props.dataViewId).show();

        let ownerType = "orgid";
        let userId = LeopardDataHelper.GetUserIdFromUserProfile(window.userProfile);
        if (this.selectedParentViewData.owner === userId){
            ownerType = "userid";
        }

        LeopardAjaxHelper.InsertOrUpdateDocumentToS3(JSON.parse(fileContent),
            this.selectedParentViewData.name,
            this.selectedParentViewData.type, function () {
                LRH.ShowToast("Your document has been successfully saved.", "success", 5000);

                that.uiObjectInstances["data-document-editor-save"].option("disabled", false);
                $(".document-editor-saveprogress-" + that.props.dataViewId).hide();
            }, function () {
                LRH.ShowToast("Failed to upload document.", "error", 5000);
                that.uiObjectInstances["data-document-editor-save"].option("disabled", false);
                $(".document-editor-saveprogress-" + that.props.dataViewId).hide();
            }, ownerType);
    }

    documentDataPreviewerEditOnClick = () => {
        let that = this;
        let previewerInstance = this.uiObjectInstances["leopard_document_data_previewer"];
        if (LDH.IsObjectNull(previewerInstance)) return;

        let editorInstance = this.uiObjectInstances["leopard_document_data_editor"];
        if (LDH.IsObjectNull(editorInstance)) return;

        editorInstance.onChangeEventProcess = "disabled";
        that.updateDataPreviewerState({readOnly: false});

        this.setState({isDocumentEditorReadOnly: true}, function () {
            let $root = $(".document-editor_widget_" + that.props.dataViewId);
            $(".splitter-layout .layout-pane-primary", $root).addClass("hide-scrollbar");
            setTimeout(function () {
                editorInstance.onChangeEventProcess = "update";
            }, 100);
        });
    }

    documentDataPreviewerExportDataOnClick = () => {
        let that = this;
        let data = that.documentDataString;
        LRH.DownloadStringToFile("DocJSONData.json", data);
    }

    documentDataPreviewerSaveOnClick = () => {
        let that = this;

        let previewerInstance = this.uiObjectInstances["leopard_document_data_previewer"];
        if (LDH.IsObjectNull(previewerInstance)) return;

        let editorInstance = this.uiObjectInstances["leopard_document_data_editor"];
        if (LDH.IsObjectNull(editorInstance)) return;

        this.documentDataJSON = JSON.parse(that.documentDataString);
        this.documentDataForEditor = this.documentDataJSON;

        editorInstance.onChangeEventProcess = "update";
        editorInstance.updateDocumentData(this.documentDataJSON);

        this.setState({isDocumentEditorReadOnly: false, isHideDocumentEditor: true}, function () {
            // A workaround for re-generate JSON forms after the data has been changed.
            this.setState({isHideDocumentEditor: false}, function () {
                let $root = $(".document-editor_widget_" + that.props.dataViewId);
                $(".splitter-layout .layout-pane-primary", $root).removeClass("hide-scrollbar");
            })
        });
    }

    documentDataPreviewerCancelOnClick = () => {
        let that = this;

        let previewerInstance = this.uiObjectInstances["leopard_document_data_previewer"];
        if (LDH.IsObjectNull(previewerInstance)) return;

        let editorInstance = this.uiObjectInstances["leopard_document_data_editor"];
        if (LDH.IsObjectNull(editorInstance)) return;

        editorInstance.onChangeEventProcess = "undo";
        this.setState({isDocumentEditorReadOnly: false}, function () {
            let $root = $(".document-editor_widget_" + that.props.dataViewId);
            $(".splitter-layout .layout-pane-primary", $root).removeClass("hide-scrollbar");

            setTimeout(function () {
                editorInstance.onChangeEventProcess = "update";
            }, 100);
        });
    }

    documentDataPreviewerValueOnChanged = (data) => {
        try {
            let jsonData = JSON.parse(data);
            this.documentDataJSON = jsonData;
            this.documentDataForEditor = jsonData;
            this.documentDataString = data;
        } catch (ex) {
            console.log("Prevent crash from invalid JSON while being edited.");
        }
    }

    initializeDocumentDesignerPanel() {
        let definition = null;
        if (!LDH.IsObjectNull(this.state.documentDefinition)) {
            definition = this.state.documentDefinition;
        }

        return (
            <div className="leopard-document-editor-designer" custom_attr_gridviewid={this.props.dataViewId}>
                <LeopardDocumentEditorDesigner
                    dataViewId={this.props.dataViewId} definition={definition}
                    documentEditorDesignerButtonOnClick={(e) => this.documentEditorDesignerButtonOnClick(e)}>
                </LeopardDocumentEditorDesigner>
            </div>
        );
    }

    initializeAdministrativeToolbar = () => {
        return (
            <div style={{height: "30px"}}>
                <span className={"leopard-no-whitespace"} style={{padding: "0 10px 0 15px"}}>
                    <LeopardTooltipWithLink
                        elementId={"DocumentEditor_Admin_ConfigureEditor_" + this.props.dataViewId}
                        labelText={"Configure Document Editor"} width={300} title={"Configure Document Editor"}
                        additionalClass={"leopard-gridview-admin-toolbar"}
                        onClick={(e) => this.documentEditorDesignerButtonOnClick({e})}
                        text={"This feature allows admin users to configure the Document Editor settings."}>
                    </LeopardTooltipWithLink>
                </span>
            </div>
        );
    }

    onSecondaryPaneSizeChange = () => {
        LRH.TriggerWindowResizeEvent();
    }

    onDataPreviewerValidated = (data) => {
        let instance = this.uiObjectInstances["data-previewer-save"];
        if (LDH.IsObjectNull(instance)) return;

        if (data.isDataValid === true) {
            instance.option("disabled", false);
        } else {
            instance.option("disabled", true);
        }
    }

    updateDataPreviewerState = (data) => {
        let that = this;
        let instance = that.uiObjectInstances["leopard_document_data_previewer"];
        if (LDH.IsObjectNull(instance)) return;

        if (!LDH.IsObjectNull(data.documentDataString)) {
            let jsonData = JSON.parse(data.documentDataString);
            this.documentDataJSON = jsonData;
            this.documentDataForEditor = jsonData;
            this.documentDataString = data.documentDataString;
        }
        instance.updateValuesInState(data);
    }

    render() {
        let that = this;
        let isDataView = this.props.isDataView;
        if (this.state.isDataViewInitialized === false) {
            return (
                <React.Fragment>
                    <span className={"leopard-loading-icon"} style={{width: "100%", height: "100%", marginLeft: "0px"}}>
                      <i className="leopard-largeloading-progress-icon fas fa-spinner fa-pulse"></i>
                      <span className="leopard-largeloading-progress-text">Loading data...</span>
                    </span>
                </React.Fragment>
            );
        }

        let definition = null;
        if (!LDH.IsObjectNull(this.state.documentDefinition)) {
            definition = this.state.documentDefinition;
        }

        let showGridViewAdminToolbar = true;
        if (this.props.viewType === "workspace") {
            showGridViewAdminToolbar = false;
        } else if (this.props.state.permissions !== undefined && this.props.state.permissions !== null) {
            showGridViewAdminToolbar = this.props.state.permissions.ShowGridViewAdminToolbar;
        }
        let isExpanded = this.state.isExpandDesigner;

        let dataPreviewerDefaultWidth = 500;
        if (!LDH.IsObjectNull(definition.dataPreviewerDefaultWidth) &&
            !LDH.IsValueEmpty(definition.dataPreviewerDefaultWidth)) {
            dataPreviewerDefaultWidth = definition.dataPreviewerDefaultWidth;
        }

        let dataPreviewerVisible = false;
        if (!LDH.IsObjectNull(definition.dataPreviewerVisibilityByLocation) &&
            !LDH.IsValueEmpty(definition.dataPreviewerVisibilityByLocation) &&
            (definition.dataPreviewerVisibilityByLocation === "dataViewsOnly" ||
                definition.dataPreviewerVisibilityByLocation === "both") && isDataView) {
            dataPreviewerVisible = true;
        } else if (!LDH.IsObjectNull(definition.dataPreviewerVisibilityByLocation) &&
            !LDH.IsValueEmpty(definition.dataPreviewerVisibilityByLocation) &&
            (definition.dataPreviewerVisibilityByLocation === "workspacesOnly" ||
                definition.dataPreviewerVisibilityByLocation === "both") && !isDataView) {
            dataPreviewerVisible = true;
        } else if (LDH.IsObjectNull(definition.dataPreviewerVisibilityByLocation) ||
            LDH.IsValueEmpty(definition.dataPreviewerVisibilityByLocation)) {
            dataPreviewerVisible = true;
        }

        if (dataPreviewerVisible === true) {
            if (!LDH.IsObjectNull(definition.dataPreviewerVisibility) &&
                !LDH.IsValueEmpty(definition.dataPreviewerVisibility) &&
                (definition.dataPreviewerVisibility === "visibleToAdmin" &&
                    window.userRoles === LeopardStaticUIConfig.UserRoleCCAdmin)) {
                dataPreviewerVisible = true;
            } else if (!LDH.IsObjectNull(definition.dataPreviewerVisibility) &&
                !LDH.IsValueEmpty(definition.dataPreviewerVisibility) &&
                definition.dataPreviewerVisibility === "visibleToAll") {
                dataPreviewerVisible = true;
            } else if (LDH.IsObjectNull(definition.dataPreviewerVisibility) ||
                LDH.IsValueEmpty(definition.dataPreviewerVisibility)) {
                dataPreviewerVisible = true;
            } else {
                dataPreviewerVisible = false;
            }
        }

        if (dataPreviewerVisible === false) {
            dataPreviewerDefaultWidth = 0;
            setTimeout(function () {
                let $widget = $(".document-editor_widget_" + that.props.dataViewId);
                $(".splitter-layout .layout-splitter", $widget).addClass("hidden");
            }, 100);
        }

        return (
            <React.Fragment>
                {(!showGridViewAdminToolbar || isExpanded) ? null : this.initializeAdministrativeToolbar()}
                {this.state.isExpandDesigner === false ? "" : this.initializeDocumentDesignerPanel()}
                <div id={"leopard-document-editor-loading-progress-" + that.props.dataViewId}
                     className={"leopard-document-editor-loading-progress"}>
                     <span className={"leopard-loading-icon leopard-centre-box"}>
                         <i className="fas fa-spinner fa-pulse" style={{color: "#FF8100", fontSize: "45px"}}></i>
                     </span>
                </div>
                <div
                    className={"document-editor-notjson-warning document-editor-notjson-warning-" + that.props.dataViewId}
                    style={{display: "none"}}>
                    This is not a JSON document.
                </div>
                <div className={"document-editor_widget_" + that.props.dataViewId + " " +
                    "leopard-splitter-control " + ((!showGridViewAdminToolbar || isExpanded)
                        ? "" : "with-admin-panel")}>
                    <SplitterLayout
                        secondaryInitialSize={dataPreviewerDefaultWidth}
                        onSecondaryPaneSizeChange={this.onSecondaryPaneSizeChange}>
                        <div className={"leopard-documenteditor-left-hand-side"}>
                            <div style={{display: "flex"}}>
                                <div className={"panel-title"}>Document Editor</div>
                                <div style={{display: "flex"}}>
                                   <span
                                       className={"leopard-loading-icon savedocument document-editor-saveprogress-" + that.props.dataViewId}
                                       style={{marginRight: "5px", marginLeft: "30px", marginTop: "2px"}}>
                                                <i className="fas fa-spinner fa-pulse"
                                                   style={{color: "#FF8100", fontSize: "25px"}}></i>
                                   </span>
                                    <div className={"schema_file_uploader"} style={{display: "none"}}>
                                        <FileUploader multiple={false} uploadMode={"useForm"}
                                                      accept={'.json'}
                                                      ref={(e) => this.setUIInstance({
                                                          e,
                                                          name: "exportDataViewFileUploader_Schema"
                                                      })}
                                                      onValueChanged={(e) => this.schemaFileUploaderOnSelectedFileChanged({e})}/>
                                    </div>
                                    <div className={"data_file_uploader"} style={{display: "none"}}>
                                        <FileUploader multiple={false} uploadMode={"useForm"}
                                                      accept={'.json'}
                                                      ref={(e) => this.setUIInstance({
                                                          e,
                                                          name: "exportDataViewFileUploader_Data"
                                                      })}
                                                      onValueChanged={(e) => this.dataFileUploaderOnSelectedFileChanged({e})}/>
                                    </div>
                                    {
                                        this.props.isDataView ?
                                            <React.Fragment>
                                                <LeopardTooltipWithLink
                                                    elementId={"DocumentEditor_DataEditor_Import_" + this.props.dataViewId}
                                                    labelText={"Load Schema"}
                                                    refName={"data-document-editor-importschema"}
                                                    refFunc={(e) => this.setUIInstance({
                                                        e,
                                                        name: "document_editor_importschema"
                                                    })}
                                                    onClick={(e) => this.documentEditorImportButtonOnClick({e})}>
                                                </LeopardTooltipWithLink>
                                                <LeopardTooltipWithLink
                                                    elementId={"DocumentEditor_DataEditor_ImportData_" + this.props.dataViewId}
                                                    labelText={"Load Data"} width={250}
                                                    refName={"data-document-editor-importdata"}
                                                    refFunc={(e) => this.setUIInstance({
                                                        e,
                                                        name: "document_editor_importdata"
                                                    })}
                                                    onClick={(e) => this.documentDataPreviewerImportDataOnClick({e})}>
                                                </LeopardTooltipWithLink>
                                                <LeopardTooltipWithLink
                                                    elementId={"DocumentEditor_DataEditor_Export_" + this.props.dataViewId}
                                                    labelText={"Download Schema"}
                                                    refName={"data-document-editor-exportschema"}
                                                    refFunc={(e) => this.setUIInstance({
                                                        e,
                                                        name: "document_editor_exportschema"
                                                    })}
                                                    onClick={(e) => this.documentEditorExportButtonOnClick({e})}>
                                                </LeopardTooltipWithLink>
                                                <LeopardTooltipWithLink
                                                    elementId={"DocumentEditor_DataEditor_ExportData_" + this.props.dataViewId}
                                                    labelText={"Download Data"} width={250}
                                                    refName={"data-document-editor-exportdata"}
                                                    refFunc={(e) => this.setUIInstance({
                                                        e,
                                                        name: "document_editor_exportdata"
                                                    })}
                                                    onClick={(e) => this.documentDataPreviewerExportDataOnClick({e})}>
                                                </LeopardTooltipWithLink>
                                            </React.Fragment> :
                                            <React.Fragment>
                                                <LeopardTooltipWithLink
                                                    elementId={"DocumentEditor_DataEditor_Export_" + this.props.dataViewId}
                                                    labelText={"Download Schema"}
                                                    refName={"data-document-editor-exportschema"}
                                                    refFunc={(e) => this.setUIInstance({
                                                        e,
                                                        name: "document_editor_exportschema"
                                                    })}
                                                    onClick={(e) => this.documentEditorExportButtonOnClick({e})}>
                                                </LeopardTooltipWithLink>
                                                <LeopardTooltipWithLink
                                                    elementId={"DocumentEditor_DataEditor_ExportData_" + this.props.dataViewId}
                                                    labelText={"Download Data"} width={250}
                                                    refName={"data-document-editor-exportdata"}
                                                    refFunc={(e) => this.setUIInstance({
                                                        e,
                                                        name: "document_editor_exportdata"
                                                    })}
                                                    onClick={(e) => this.documentDataPreviewerExportDataOnClick({e})}>
                                                </LeopardTooltipWithLink>
                                            </React.Fragment>
                                    }
                                    {
                                        this.props.isDataView ? null :
                                            <LeopardTooltipWithLink
                                                elementId={"DocumentEditor_DataEditor_Save_" + this.props.dataViewId}
                                                labelText={"Save Document"} refName={"data-document-editor-save"}
                                                refFunc={(e) => this.setUIInstance(e)}
                                                onClick={(e) => this.documentEditorSaveButtonOnClick({e})}>
                                            </LeopardTooltipWithLink>
                                    }
                                </div>
                            </div>
                            {
                                this.state.isDocumentEditorReadOnly ?
                                    <div className={"ui-disabled-cover"}></div> : null
                            }
                            {
                                this.state.isHideDocumentEditor ? null :
                                    <LeopardDocumentEditorEngine
                                        documentDefinition={definition} documentData={this.documentDataForEditor}
                                        setUIInstance={(e) => that.setUIInstance(e)}
                                        updateDataPreviewerState={(e) => that.updateDataPreviewerState(e)}/>
                            }
                        </div>
                        <div className={"leopard-documenteditor-right-hand-side"}>
                            <div style={{display: "flex"}}>
                                <div className={"panel-title"}>Data Previewer</div>
                                {
                                    this.state.isDocumentEditorReadOnly ? null :
                                        <div>
                                            <LeopardTooltipWithLink
                                                elementId={"DocumentEditor_DataPreviewer_Edit_" + this.props.dataViewId}
                                                labelText={"Edit Document"} width={250}
                                                refName={"data-previewer-edit"}
                                                refFunc={(e) => this.setUIInstance(e)}
                                                onClick={(e) => this.documentDataPreviewerEditOnClick({e})}>
                                            </LeopardTooltipWithLink>
                                        </div>
                                }
                                {
                                    !this.state.isDocumentEditorReadOnly ? null :
                                        <div>
                                            <LeopardTooltipWithLink
                                                elementId={"DocumentEditor_DataPreviewer_Save_" + this.props.dataViewId}
                                                labelText={"Save Document"} width={250}
                                                refName={"data-previewer-save"}
                                                refFunc={(e) => this.setUIInstance(e)}
                                                onClick={(e) => this.documentDataPreviewerSaveOnClick({e})}>
                                            </LeopardTooltipWithLink>
                                        </div>
                                }
                                {
                                    !this.state.isDocumentEditorReadOnly ? null :
                                        <div>
                                            <LeopardTooltipWithLink
                                                elementId={"DocumentEditor_DataPreviewer_Cancel_" + this.props.dataViewId}
                                                labelText={"Cancel"} width={250}
                                                onClick={(e) => this.documentDataPreviewerCancelOnClick({e})}>
                                            </LeopardTooltipWithLink>
                                        </div>
                                }
                            </div>
                            <div className={"data-previewer-container"}>
                                <LeopardDocumentPreviewerEngine
                                    ref={(e) => that.setUIInstance({
                                        e: e,
                                        name: "leopard_document_data_previewer"
                                    })}
                                    onDataPreviewerValidated={(e) => that.onDataPreviewerValidated(e)}
                                    documentDataPreviewerValueOnChanged={(e) => that.documentDataPreviewerValueOnChanged(e)}
                                    documentDataJSON={this.documentDataJSON} documentDefinition={definition}>
                                </LeopardDocumentPreviewerEngine>
                            </div>
                        </div>
                    </SplitterLayout>
                </div>
            </React.Fragment>
        );
    }
}

const RetrieveDataFromReducer = (state) => {
    return {state};
};

export default connect(RetrieveDataFromReducer)(LeopardDocumentEditorConfiguration);
