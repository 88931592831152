import React from 'react';
import {Auth} from 'aws-amplify';
import $ from 'jquery';
import LDH from '../helpers/LeopardDataHelper';
import LPH from "../helpers/LeopardPermissionHelper";
import LRH from "../helpers/LeopardReactHelper";

class LeopardSecurity extends React.Component {
    static IsInputValueDangerous = (input) => {
        if (LDH.IsValueEmpty(input)) return false;

        let validity = false;
        if (input.toString().match(/[<>]+/) != null) {
            validity = true;
        }
        return validity;
    };

    static GetCurrentUserRoles = () => {
        return window.userRoles;
    }

    static ShowErrorMessageForLeopardAdminFeature = (component) => {
        let $parent = $(".leopard-leftmenu-item.selected").parent();
        let isDefaultView = $parent.hasClass("dataViewCategory_default-view");
        let errorMsg = "This feature is available for Leopard Admin only. If you want to make some changes, " +
            "please contact Leopard Systems for assistance.";

        if (!LPH.IsLeopardAdminModeEnabled(component) && isDefaultView) {
            LRH.ShowToast(errorMsg, "error", 5000);
            return true;
        }
        return false;
    };

    static async UserLogin(username, password, callbackSuccess, callbackFailed, isTestMode) {
        if (isTestMode === true) {
            let user = {username};
            callbackSuccess("login-success-nochallenge", user);
        } else {
            try {
                const user = await Auth.signIn(username, password);
                if (user.challengeName === 'SMS_MFA' || user.challengeName === 'SOFTWARE_TOKEN_MFA') {
                    callbackSuccess("login-success-" + user.challengeName, user);
                } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                    callbackSuccess("login-success-" + user.challengeName, user);
                } else if (user.challengeName === 'MFA_SETUP') {
                    callbackSuccess("login-success-" + user.challengeName, user);
                } else {
                    callbackSuccess("login-success-nochallenge", user);
                }
            } catch (err) {
                if (err.code === 'UserNotConfirmedException') {
                    callbackFailed("LeopardSecurity_UserLogin_Failed1", err.code);
                } else if (err.code === 'PasswordResetRequiredException') {
                    callbackFailed("LeopardSecurity_UserLogin_Failed2", err.code);
                } else {
                    callbackFailed("LeopardSecurity_UserLogin_Failed3", err);
                }
            }
        }
    }

    static UserLogout() {
        window.isUserLoggedOut = true;
        Auth.signOut({global: false});

        setTimeout(function () {
            $(".login-page-container").hide();
            $(".login-cover-progress-icon").show();

            setTimeout(function () {
                window.location.reload();
            }, 500);
        }, 10);
    }

    static GetCurrentAuthenticatedUser(callbackSuccess, callbackFailed) {
        Auth.currentAuthenticatedUser({bypassCache: false})
            .then(user => callbackSuccess(user))
            .catch(err => callbackFailed(err));
    }

    static GetCurrentUserCredentials(callbackSuccess, callbackFailed) {
        Auth.currentUserCredentials(callbackSuccess, callbackFailed)
            .then(credential => callbackSuccess(credential))
            .catch(err => callbackFailed(err));
    }

    static GetCurrentUserSession(callbackSuccess, callbackFailed) {
        Auth.currentSession(callbackSuccess, callbackFailed)
            .then(session => callbackSuccess(session))
            .catch(err => callbackFailed(err));
    }
}

export default LeopardSecurity;
