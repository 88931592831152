import {Component} from 'react';

class LeopardDropdownHelper extends Component {
    static DropdownSelectionYesNo = [{
        id: false,
        name: "No"
    }, {
        id: true,
        name: "Yes"
    }];

    static DropdownSelectionPopupFileUploader = [{
        id: "documentService",
        name: "Document Service"
    }, {
        id: "formService",
        name: "Form Service"
    }];

    static DropdownSelectionAutoManualMode = [{
        id: "auto-mode",
        name: "Auto Mode"
    }, {
        id: "manual-mode",
        name: "Manual Mode"
    }, {
        id: "hidden",
        name: "Hidden"
    }];

    static DropdownSelectionDefaultHtmlFormBuilderMode = [{
        id: "view-mode",
        name: "View Mode"
    }, {
        id: "design-mode",
        name: "Design Mode"
    }];

    static DropdownSelectionGridViewExportFormat = [{
        id: "XLSX",
        name: "Microsoft Excel (XLSX)"
    }, {
        id: "CSV",
        name: "Comma-Separated Values (CSV)"
    }, {
        id: "UserSelection",
        name: "User Selection (All Formats)"
    }];

    static DropdownSelectionGridViewImportOption = [{
        id: "popup",
        name: "Import data into a popup"
    }, {
        id: "gridview",
        name: "Import data into a grid view"
    }];

    static DropdownSelectionColumnHeaderMatchType = [{
        id: "column-header-id",
        name: "Column header ID"
    }, {
        id: "column-header-text",
        name: "Column header text"
    }];

    static DropdownSelectionEnableForAdminUsers = [{
        id: "hideForAll",
        name: "Hide for All Users"
    }, {
        id: "visibleToAdmin",
        name: "Show for Admin Users"
    }, {
        id: "visibleToAll",
        name: "Show for All Users"
    }];

    static DropdownSelectionVisibilityByLocation = [{
        id: "dataViewsOnly",
        name: "Show in Data Views"
    }, {
        id: "workspacesOnly",
        name: "Show in Workspaces"
    }, {
        id: "both",
        name: "Show in Data Views and Workspaces"
    }];

    static DropdownSelectionTruncationMethod = [{
        id: 0,
        name: "No Abbreviation"
    }, {
        id: 1,
        name: "Keep First N Characters"
    }, {
        id: 2,
        name: "Keep Last N Characters"
    }, {
        id: 3,
        name: "Remove First N Characters"
    }, {
        id: 4,
        name: "Remove Last N Characters"
    }];

    static DropdownSelectionShowHide = [{
        id: false,
        name: "Hide"
    }, {
        id: true,
        name: "Show"
    }];

    static DropdownSelectionDataViewMenuCategory = [{
        id: "data-view",
        name: "Data Views"
    }, {
        id: "default-view",
        name: "Default Views"
    }, {
        id: "system-view",
        name: "System Views"
    }];

    static DropdownSelectionScrollbarVisibility = [{
        id: "yes",
        name: "Always Visible"
    }, {
        id: "no",
        name: "Always Hidden"
    }, {
        id: "auto",
        name: "Auto"
    }];

    static DropdownSelectionAutoManual = [{
        id: "auto",
        name: "Set Automatically"
    }, {
        id: "manual",
        name: "Set Manually"
    }];

    static DropdownSelectionDataGridDownloadOption = [{
        id: "none",
        name: "None"
    }, {
        id: "document-service",
        name: "Document Service"
    }];

    static DropdownSelectionDataGridDocumentDirectory = [{
        id: "reference",
        name: "Reference"
    }, {
        id: "cc_dataview_config",
        name: "Config"
    }, {
        id: "global",
        name: "Global"
    }];

    static DropdownSelectionDataGridFormDirectory = [{
        id: "draft",
        name: "Draft"
    }];

    static DropdownSelectionDataGridUploadOption = [{
        id: "none",
        name: "None"
    }, {
        id: "document-service",
        name: "Document Service"
    }];

    static DropdownSelectionGridViewEngine = [{
        id: "odata-engine",
        name: "OData"
    }, {
        id: "json-engine",
        name: "JSON"
    }, {
        id: "csv-adhoc-engine",
        name: "CSV (Ad-hoc)"
    }];

    static DropdownSelectionJSONDataSource = [{
        id: "document",
        name: "Document"
    }];

    static DropdownSelectionReferenceDataOwner = [{
        id: "userid",
        name: "Current User ID"
    },{
        id: "orgid",
        name: "Organisation ID"
    }];

    static DropdownSelectionRegEx = [{
        id: "perl5",
        name: "Perl 5"
    }];

    static DropdownSelectionDataViewTypeList = [{
        id: "datagrid",
        name: "Data Grid"
    }, {
        id: "chart",
        name: "Chart"
    }, {
        id: "map",
        name: "Map"
    }, {
        id: "photo",
        name: "Photo Gallery"
    }, {
        id: "notepad",
        name: "Notepad"
    }, {
        id: "document-editor",
        name: "Document Editor"
    }, {
        id: "regex-tester",
        name: "RegEx Tester"
    }, {
        id: "form-editor",
        name: "Form"
    }, {
        id: "timeline",
        name: "Timeline"
    }, {
        id: "tabbed-view",
        name: "Tabbed View"
    }, {
        id: "html-form-builder",
        name: "HTML Form Builder"
    }];

    static DropdownSelectionMapProvider = [{
        id: "google-map",
        name: "Google Map"
    }, {
        id: "open-street-map",
        name: "OpenStreet Map"
    }];

    static DropdownSelectionValueFormat = [{
        id: "default",
        name: "Default"
    }, {
        id: "uppercase",
        name: "Upper Case"
    }, {
        id: "lowercase",
        name: "Lower Case"
    }];

    static DropdownSelectionDocumentEditorDataFormat = [{
        id: "json",
        name: "JSON"
    }];

    static DropdownSelectionDataViewExportMethodList = [{
        id: "individual",
        name: "Individual"
    }, {
        id: "all",
        name: "All"
    }];

    static DropdownSelectionChartDateRange = [{
        id: "7days",
        name: "Last 7 days"
    }, {
        id: "14days",
        name: "Last 14 days"
    }, {
        id: "30days",
        name: "Last 30 days"
    }, {
        id: "lastMonth",
        name: "Last Month"
    }, {
        id: "currentMonth",
        name: "Current Month"
    }];

    static DropdownSelectionReportTypeList = [{
        id: "report",
        name: "Standard"
    }];

    static DropdownSelectionAnalyticsTypeList = [{
        id: "aws-quicksight-dashboard",
        name: "AWS QuickSight Dashboard"
    }];

    static DropdownSelectionChartTypeList = [{
        id: "spline-chart",
        name: "Spline Chart"
    }, {
        id: "scatter-chart",
        name: "Scatter Chart"
    }, {
        id: "doughnut-chart",
        name: "Doughnut Chart"
    }, {
        id: "bar-chart",
        name: "Bar Chart"
    }, {
        id: "line-chart",
        name: "Line Chart"
    }, {
        id: "area-chart",
        name: "Area Chart"
    }];

    static DropdownSelectionDefaultTimeline = [{
        id: "timelineDay",
        name: "Day"
    }, {
        id: "timelineWeek",
        name: "Week"
    }, {
        id: "timelineWorkWeek",
        name: "Work Week"
    }, {
        id: "timelineMonth",
        name: "Month"
    }];

    static DropdownSelectionChartPalette = [{
        id: "Bright",
        name: "Bright"
    }, {
        id: "Harmony Light",
        name: "Harmony Light"
    }, {
        id: "Ocean",
        name: "Ocean"
    }, {
        id: "Pastel",
        name: "Pastel"
    }, {
        id: "Soft",
        name: "Soft"
    }, {
        id: "Soft Pastel",
        name: "Soft Pastel"
    }, {
        id: "Vintage",
        name: "Vintage"
    }, {
        id: "Violet",
        name: "Violet"
    }, {
        id: "Carmine",
        name: "Carmine"
    }, {
        id: "Dark Moon",
        name: "Dark Moon"
    }, {
        id: "Dark Violet",
        name: "Dark Violet"
    }, {
        id: "Green Mist",
        name: "Green Mist"
    }, {
        id: "Soft Blue",
        name: "Soft Blue"
    }, {
        id: "Material",
        name: "Material"
    }, {
        id: "Office",
        name: "Office"
    }];

    static DropdownSelectionChartTabPanel = [{
        tabId: 0,
        tabTitle: 'Chart'
    }, {
        tabId: 1,
        tabTitle: "Series"
    }, {
        tabId: 2,
        tabTitle: "Legends"
    }, {
        tabId: 3,
        tabTitle: "Scripts"
    }, {
        tabId: 4,
        tabTitle: "Parameters"
    }];

    static DropdownSelectionTimelineTabPanel = [{
        tabId: 0,
        tabTitle: 'Timeline'
    }, {
        tabId: 1,
        tabTitle: "Scripts"
    }, {
        tabId: 2,
        tabTitle: "Parameters"
    }];

    static DropdownSelectionTabbedViewTabPanel = [{
        tabId: 0,
        tabTitle: "Parameters"
    }];

    static DropdownSelectionMapTabPanel = [{
        tabId: 0,
        tabTitle: 'Map'
    }, {
        tabId: 1,
        tabTitle: "Parameters"
    }];

    static DropdownSelectionFormTabPanel = [{
        tabId: 0,
        tabTitle: 'Form'
    }, {
        tabId: 1,
        tabTitle: "Fields"
    }];

    static DropdownSelectionHtmlFormBuilderTabPanel = [{
        tabId: 0,
        tabTitle: 'Form'
    }];

    static DropdownSelectionDocumentEditorTabPanel = [{
        tabId: 0,
        tabTitle: 'General'
    }, {
        tabId: 1,
        tabTitle: 'Data Sources'
    }];

    static DropdownSelectionTypeOfSeries = [{
        id: "area",
        name: "Area"
    }, {
        id: "bar",
        name: "Bar"
    }, {
        id: "fullstackedarea",
        name: "Full stacked area"
    }, {
        id: "fullstackedbar",
        name: "Full stacked bar"
    }, {
        id: "fullstackedline",
        name: "Full stacked line"
    }, {
        id: "fullstackedspline",
        name: "Full stacked spline"
    }, {
        id: "fullstackedsplinearea",
        name: "Full stacked spline area"
    }, {
        id: "line",
        name: "Line"
    }, {
        id: "scatter",
        name: "Scatter"
    }, {
        id: "spline",
        name: "Spline"
    }, {
        id: "splinearea",
        name: "Spline area"
    }, {
        id: "stackedarea",
        name: "Stacked area"
    }, {
        id: "stackedbar",
        name: "Stacked bar"
    }, {
        id: "stackedline",
        name: "Stacked line"
    }, {
        id: "stackedspline",
        name: "Stacked spline"
    }, {
        id: "stackedsplinearea",
        name: "Stacked spline area"
    }, {
        id: "steparea",
        name: "Step area"
    }, {
        id: "stepline",
        name: "Step line"
    }];

    static DropdownSelectionSmallValuesGroupingMode = [{
        id: "none",
        name: "None"
    }, {
        id: "smallValueThreshold",
        name: "Small value threshold"
    }, {
        id: "topN",
        name: "Top N"
    }];

    static DropdownSelectionSeriesHoverMode = [{
        id: "none",
        name: "None"
    }, {
        id: "onlyPoint",
        name: "Only Point"
    }, {
        id: "allSeriesPoints",
        name: "All Series Points"
    }, {
        id: "allArgumentPoints",
        name: "All Argument Points"
    }];

    static DropdownSelectionPointSelectionMode = [{
        id: "none",
        name: "None"
    }, {
        id: "onlyPoint",
        name: "Only Point"
    }, {
        id: "allSeriesPoints",
        name: "All Series Points"
    }, {
        id: "allArgumentPoints",
        name: "All Argument Points"
    }];

    static DropdownSelectionSeriesSelectionModePieChart = [{
        id: "none",
        name: "None"
    }, {
        id: "onlyPoint",
        name: "Only Point"
    }];

    static DropdownSelectionSeriesSelectionModeBarChart = [{
        id: "none",
        name: "None"
    }, {
        id: "onlyPoint",
        name: "Only Point"
    }, {
        id: "allSeriesPoints",
        name: "All Series Points"
    }, {
        id: "allArgumentPoints",
        name: "All Argument Points"
    }, {
        id: "excludePoints",
        name: "Exclude Points"
    }, {
        id: "includePoints",
        name: "Include Points"
    }];

    static DropdownSelectionLegendHoverModeForBarChart = [{
        id: "none",
        name: "None"
    }, {
        id: "excludePoints",
        name: "Exclude Points"
    }, {
        id: "includePoints",
        name: "Include Points"
    }];

    static DropdownSelectionLegendHoverModeForPieChart = [{
        id: "none",
        name: "None"
    }, {
        id: "allArgumentPoints",
        name: "All Argument Points"
    }];

    static DropdownSelectionChartLineStyle = [{
        id: "solid",
        name: "Solid line",
    }, {
        id: "dash",
        name: "Dash line",
    }, {
        id: "dot",
        name: "Dotted line",
    }, {
        id: "longDash",
        name: "Long dash line",
    }];

    static DropdownSelectionValueAxisType = [{
        id: undefined,
        name: "None"
    }, {
        id: "continuous",
        name: "continuous"
    }, {
        id: "discrete",
        name: "Discrete"
    }, {
        id: "logarithmic",
        name: "Logarithmic"
    }];

    static DropdownSelectionTooltipLocation = [{
        id: "center",
        name: "center"
    }, {
        id: "edge",
        name: "edge"
    }];

    static DropdownSelectionTooltipCustomText = [{
        id: "none",
        name: "None"
    }, {
        id: "originalValue",
        name: "Original Value"
    }, {
        id: "value",
        name: "Value"
    }, {
        id: "valueText",
        name: "Value Text"
    }, {
        id: "originalArgument",
        name: "Original Argument"
    }, {
        id: "argument",
        name: "Argument"
    }, {
        id: "argumentText",
        name: "Argument Text"
    }, {
        id: "size",
        name: "Size"
    }, {
        id: "index",
        name: "Index"
    }, {
        id: "point",
        name: "Point"
    }, {
        id: "seriesName",
        name: "Series Name"
    }, {
        id: "percent",
        name: "Percent"
    }, {
        id: "percentText",
        name: "Percent Text"
    }, {
        id: "total",
        name: "Total"
    }, {
        id: "totalText",
        name: "Total Text"
    }];

    static DropdownSelectionDateRange = [{
        id: "7days",
        name: "Last 7 days"
    }, {
        id: "14days",
        name: "Last 14 days"
    }, {
        id: "30days",
        name: "Last 30 days"
    }, {
        id: "lastMonth",
        name: "Last Month"
    }, {
        id: "currentMonth",
        name: "Current Month"
    }];

    static DropdownSelectionHorizontalAlignOption = [{
        id: "left",
        name: "Left"
    }, {
        id: "center",
        name: "Center"
    }, {
        id: "right",
        name: "Right"
    }];

    static DropdownSelectionVerticalAlignOption = [{
        id: "top",
        name: "Top"
    }, {
        id: "bottom",
        name: "Bottom"
    }];

    static DropdownSelectionResolveLabelOverlapping = [{
        id: "hide",
        name: "Hide"
    }, {
        id: "none",
        name: "None"
    }, {
        id: "stack",
        name: "Stack"
    }];

    static DropdownSelectionItemTextPosition = [{
        id: "bottom",
        name: "Bottom"
    }, {
        id: "left",
        name: "Left"
    }, {
        id: "top",
        name: "Top"
    }, {
        id: "right",
        name: "Right"
    }];

    static DropdownSelectionOverlappingBehavior = [{
        id: "stagger",
        name: "Stagger"
    }, {
        id: "rotate",
        name: "Rotate"
    }, {
        id: "hide",
        name: "Hide"
    }, {
        id: "none",
        name: "None"
    }];

    static DropdownSelectionValueAxisValueType = [{
        id: "datetime",
        name: "Date Time"
    }, {
        id: "numeric",
        name: "Numeric"
    }, {
        id: "string",
        name: "String"
    }];

    static DropdownSelectionChartOrientation = [{
        id: "horizontal",
        name: "Horizontal"
    }, {
        id: "vertical",
        name: "Vertical"
    }];

    static DropdownSelectionLegendPosition = [{
        id: "inside",
        name: "Inside"
    }, {
        id: "outside",
        name: "Outside"
    }];

    static DropdownSelectionPaletteExtensionMode = [{
        id: "alternate",
        name: "alternate"
    }, {
        id: "blend",
        name: "blend"
    }, {
        id: "extrapolate",
        name: "extrapolate"
    }];

    static DropdownSelectionDashStyleOption = [{
        id: "solid",
        name: "Solid"
    }, {
        id: "dash",
        name: "Dash"
    }, {
        id: "dot",
        name: "Dot"
    }, {
        id: "longDash",
        name: "Long Dash"
    }];

    static DropdownSelectionChartCustomSymbol = [{
        id: "circle",
        name: "Circle"
    }, {
        id: "cross",
        name: "Cross"
    }, {
        id: "polygon",
        name: "Polygon"
    }, {
        id: "square",
        name: "Square"
    }, {
        id: "triangleDown",
        name: "Triangle Down"
    }, {
        id: "triangleUp",
        name: "Triangle Up"
    }];

    static DropdownSelectionColumnTypeDataSource = [{
        text: "String",
        value: "string"
    }, {
        text: "Number",
        value: "number"
    }, {
        text: "Date",
        value: "date"
    }, {
        text: "Date Time",
        value: "datetime"
    }, {
        text: "Boolean",
        value: "boolean"
    }, {
        text: "GUID",
        value: "guid"
    }, {
        text: "Status",
        value: "status"
    }];

    static DropdownSelectionColumnColorCoding = [{
        text: "None",
        value: "none"
    }, {
        text: "Color Text",
        value: "color-text"
    }];

    static DropdownSelectionGridViewColumnAlign = [{
        text: "Left",
        value: "left"
    }, {
        text: "Center",
        value: "center"
    }, {
        text: "Right",
        value: "right"
    }];

    static DropdownSelectionTopMenuJustify = [{
        name: "Left",
        id: "left"
    }, {
        name: "Right",
        id: "right"
    }];

    static DropdownSelectionGridViewSortOrder = [{
        text: "None",
        value: "none"
    }, {
        text: "Ascending",
        value: "asc"
    }, {
        text: "Descending",
        value: "desc"
    }];

    static DropdownSelectionAllFilterOperations = [{
        value: "contains",
        text: "Contains"
    }, {
        value: "notcontains",
        text: "Not Contains"
    }, {
        value: "startswith",
        text: "Starts With"
    }, {
        value: "endswith",
        text: "Ends With"
    }, {
        value: "=",
        text: "="
    }, {
        value: "<>",
        text: "<>"
    }, {
        value: "<",
        text: "<"
    }, {
        value: ">",
        text: ">"
    }, {
        value: "<=",
        text: "<="
    }, {
        value: ">=",
        text: ">="
    }, {
        value: "between",
        text: "Between"
    }];

    static DropdownSelectionStringFilterOperations = [{
        value: "=",
        text: "="
    }, {
        value: "<>",
        text: "<>"
    }, {
        value: "contains",
        text: "Contains"
    }, {
        value: "notcontains",
        text: "Not Contains"
    }, {
        value: "startswith",
        text: "Starts With"
    }, {
        value: "endswith",
        text: "Ends With"
    }];

    static DropdownSelectionNumberFilterOperations = [{
        value: "=",
        text: "="
    }, {
        value: "<>",
        text: "<>"
    }, {
        value: "<",
        text: "<"
    }, {
        value: ">",
        text: ">"
    }, {
        value: "<=",
        text: "<="
    }, {
        value: ">=",
        text: ">="
    }, {
        value: "between",
        text: "Between"
    }];

    static DropdownSelectionDateFilterOperations = [{
        value: "=",
        text: "="
    }, {
        value: "<>",
        text: "<>"
    }, {
        value: "<",
        text: "<"
    }, {
        value: ">",
        text: ">"
    }, {
        value: "<=",
        text: "<="
    }, {
        value: ">=",
        text: ">="
    }, {
        value: "between",
        text: "Between"
    }];

    static DropdownSelectionBoolFilterOperations = [{
        value: "=",
        text: "="
    }, {
        value: "<>",
        text: "<>"
    }];

    static DropdownSelectionAutoCompleteOperations = [{
        value: "contains",
        text: "Contains"
    }, {
        value: "startswith",
        text: "Starts With"
    }];

    static DropdownSelectionGridViewTabPanel = [{
        tabId: 0,
        tabTitle: 'Data Columns'
    }, {
        tabId: 1,
        tabTitle: 'Command Links'
    }, {
        tabId: 2,
        tabTitle: "Grid View"
    }, {
        tabId: 3,
        tabTitle: "Advanced Filter"
    }, {
        tabId: 4,
        tabTitle: "Parameters"
    }, {
        tabId: 5,
        tabTitle: "Messaging"
    }];

    static DropdownSelectionWebsocketConnectionMethod = [{
        id: "subscriber",
        name: 'Subscriber (Receiving Messages)'
    },{
        id: "publisher",
        name: 'Publisher (Sending Messages)'
    }];

    static DropdownSelectionWebsocketEventIdentifier = [{
        id: "leopardsystems.websocket.tooltip.request",
        name: 'tooltip.request'
    }, {
        id: "leopardsystems.websocket.connections.stats",
        name: 'connections.stats'
    }, {
        id: "leopardsystems.websocket.subscribe",
        name: 'websocket.subscribe'
    }, {
        id: "leopardsystems.reports.user.updated",
        name: 'reports.user.updated'
    }, {
        id: "leopardsystems.chat.delivery",
        name: 'chat.delivery'
    }, {
        id: "leopardsystems.websocket.publish",
        name: 'websocket.publish'
    }];

    static DropdownSelectionWebsocketAttachToEvent = [{
        id: "dataview-onload",
        name: 'Data view on load (for receiving data)'
    },{
        id: "received-data-from-parent",
        name: 'Updated by parent (for sending data)'
    }];

    static DropdownSelectionGridViewDefaultPageSize = [{
        value: 50,
        text: "50"
    }, {
        value: 100,
        text: "100"
    }, {
        value: 200,
        text: "200"
    }];

    static DropdownSelectionGridViewColumnResizingMode = [{
        value: "nextColumn",
        text: "Next Column"
    }, {
        value: "widget",
        text: "Grid View"
    }];

    static DropdownSelectionGridViewFilterOptions = [{
        value: "auto",
        text: "No"
    }, {
        value: "onClick",
        text: "Yes"
    }];

    static DropdownSelectionColumnTypeSource = [{
        value: "photo-gallery",
        text: "Photo Gallery"
    }, {
        value: "pdf-viewer",
        text: "PDF Viewer"
    }, {
        value: "modify-row",
        text: "Modify Row"
    }, {
        value: "add-row",
        text: "Add Row"
    }, {
        value: "delete-row",
        text: "Delete Row"
    }, {
        value: "disable-row",
        text: "Disable Row"
    }, {
        value: "reset-password",
        text: "Reset Password"
    }, {
        value: "action-group",
        text: "Action Group"
    }, {
        value: "action-item",
        text: "Action Item"
    }];

    static DropdownSelectionAttachmentTabPanel = [{
        tabId: 0,
        tabTitle: 'Photos'
    }, {
        tabId: 1,
        tabTitle: 'Report'
    }];

    static DropdownSelectionColumnAlignmentDataSource = [{
        text: "Left",
        value: "left"
    }, {
        text: "Center",
        value: "center"
    }, {
        text: "Right",
        value: "right"
    }];

    static DropdownSelectionExportExcelBandFilter = [{
        text: "Data only",
        value: "data-only"
    },{
        text: "Data and headers",
        value: "data-and-headers"
    },{
        text: "Data and headers/footers",
        value: "data-and-headers-footers"
    },{
        text: "All bands",
        value: "all-bands"
    }];

    static DropdownSelectionScheduledReportFileFormat = [{
        text: "Portable Document Format (PDF)",
        value: "PDF"
    }, {
        text: "Comma-Separated Values (CSV)",
        value: "CSV"
    }, {
        text: "Microsoft Excel (XLSX)",
        value: "XLSX"
    }];

    static DropdownSelectionTimeZone = [{
        id: "Australia/Perth",
        name: "(UTC+08:00) Perth"
    }, {
        id: "Australia/Adelaide",
        name: "(UTC+09:30) Adelaide"
    }, {
        id: "Australia/Darwin",
        name: "(UTC+09:30) Darwin"
    }, {
        id: "Australia/Brisbane",
        name: "(UTC+10:00) Brisbane"
    }, {
        id: "Australia/Sydney",
        name: "(UTC+10:00) Canberra, Melbourne, Sydney"
    }, {
        id: "Australia/Hobart",
        name: "(UTC+10:00) Hobart"
    }, {
        id: "Pacific/Auckland",
        name: "(UTC+12:00) Auckland, Wellington"
    }];

    static DropdownSelectionActionItemBehaviour = [{
        id: "popup-editor",
        name: "Show a Popup Editor"
    }, {
        id: "send-request",
        name: "Send a Request to API Gateway"
    }, {
        id: "send-data-to-childview",
        name: "Send Data to View"
    }, {
        id: "remove-local-data",
        name: "Remove local data"
    }];

    static DropdownSelectionHttpRequestType = [{
        id: "get",
        name: "HTTP Get"
    }, {
        id: "post",
        name: "HTTP Post"
    }, {
        id: "put",
        name: "HTTP Put"
    }, {
        id: "del",
        name: "HTTP Delete"
    }, {
        id: "patch",
        name: "HTTP Patch"
    }];

    static DropdownSelectionHttpRequestTypeWithEventSync = [{
        id: "get",
        name: "HTTP Get"
    }, {
        id: "post",
        name: "HTTP Post"
    }, {
        id: "put",
        name: "HTTP Put"
    }, {
        id: "del",
        name: "HTTP Delete"
    }, {
        id: "patch",
        name: "HTTP Patch"
    }, {
        id: "eventsync",
        name: "Event Sync"
    }];

    static DropdownSelectionHttpRequestTypeWithWebsocket = [{
        id: "get",
        name: "HTTP Get"
    }, {
        id: "post",
        name: "HTTP Post"
    }, {
        id: "put",
        name: "HTTP Put"
    }, {
        id: "del",
        name: "HTTP Delete"
    }, {
        id: "patch",
        name: "HTTP Patch"
    }, {
        id: "websocket",
        name: "Websocket"
    }];

    static DropdownSelectionHttpRequestTypeForChart = [{
        id: "get",
        name: "HTTP Get"
    }, {
        id: "post",
        name: "HTTP Post"
    }];

    static DropdownSelectionODataEngineVersionForChart = [{
        id: "v1",
        name: "Version 1"
    }, {
        id: "v2",
        name: "Version 2"
    }];

    static DropdownSelectionParameterType = [{
        id: "datetime",
        name: "DateTime"
    }, {
        id: "date",
        name: "Date"
    }, {
        id: "textbox",
        name: "Textbox"
    }, {
        id: "dropdown",
        name: "Dropdown"
    }, {
        id: "numberbox",
        name: "Numberbox"
    }, {
        id: "dropdown-multiselect",
        name: "Dropdown Multi-Selection"
    }];

    static DropdownSelectionParameterPredefinedLogic = [{
        id: "none",
        name: "None"
    }, {
        id: "timeline-date-interval",
        name: "Timeline Date Interval"
    }];
}

export default LeopardDropdownHelper;
