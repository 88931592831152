import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Button, NumberBox, SelectBox, TextArea} from 'devextreme-react';
import Box, {Item} from 'devextreme-react/box';
import TabPanel from 'devextreme-react/tab-panel';
import LRH from '../helpers/LeopardReactHelper';
import LDH from '../helpers/LeopardDataHelper';
import LeopardAjaxHelper from '../helpers/LeopardAjaxHelper';
import $ from "jquery";
import LeopardDropdownHelper from "../helpers/LeopardDropdownHelper";
import {RequiredRule, Validator} from "devextreme-react/validator";
import {Button as TextBoxButton, TextBox} from "devextreme-react/text-box";

class LeopardHtmlFormBuilderDesigner extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedIndex: 0,
            formDefinition: {}
        };

        this.definitionParameters = [];
        this.uiObjectInstance = [];
    }

    componentDidMount = () => {
        let definition = this.props.formDefinition;

        if (LDH.IsObjectNull(definition)) {
            definition = {};
        }
        if (!LDH.IsObjectNull(definition.parameters)) {
            this.definitionParameters = definition.parameters;
        }
        this.setState({formDefinition: definition});
    };

    setInputFieldInstance = (data) => {
        if (data.e === undefined || data.e === null || data.e.instance === null) {
            return;
        }
        let instances = this.uiObjectInstance;
        instances[data.input] = data.e.instance;
    };

    updateFormDefinition = (data) => {
        let inputValue = data.e.value;
        let formDef = this.state.formDefinition;
        let clonedFormDef = LDH.DeepClone(formDef);
        clonedFormDef[data.prop] = inputValue;

        this.setState({formDefinition: clonedFormDef}, function () {
            formDef = this.state.formDefinition;
        });
    };

    getDefaultValue = (name, defaultValue) => {
        if (this.state.formDefinition[name] === undefined) {
            return defaultValue;
        }
        return this.state.formDefinition[name];
    };

    showOrHideSettings = (settingsName) => {
        return true;
    };

    customValidationRuleCallback = (data) => {
        let instances = this.uiObjectInstance;
        return LRH.BindValidationRulesToUIObject(data, instances);
    };

    saveDataButtonOnClick = () => {
        let that = this;
        let definition = LDH.DeepClone(that.state.formDefinition);
        definition.parameters = that.definitionParameters;

        let dataViewId = that.props.dataViewId;
        $(".loading-progress-configureformeditor").css("visibility", "visible");

        that.uiObjectInstance["designer_save_button"].option("disabled", true);
        that.uiObjectInstance["designer_close_button"].option("disabled", true);

        setTimeout(function () {
            let userProfile = window.userProfile;
            let userId = LDH.GetUserIdFromUserProfile(userProfile);
            let organizationId = LDH.GetOrganizationIdFromUserProfile(userProfile);

            LeopardAjaxHelper.UpdateDataViewDefinitionByType(userId, organizationId,
                dataViewId, definition, function () {
                    let settingsVersionOnClient = parseInt($(".dataview-settings-version").attr("settingsversion"));
                    $(".dataview-settings-version").attr("settingsversion", settingsVersionOnClient + 1);

                    LRH.ShowToast("Your form configuration has been successfully saved.", "success", 5000);
                    $(".leopard-columndesigner-loading-progress").css("visibility", "hidden");
                    $(".leopard-leftmenu-item.selected .leopard-leftmenu-item-text").trigger("click");

                    that.uiObjectInstance["designer_save_button"].option("disabled", false);
                    that.uiObjectInstance["designer_close_button"].option("disabled", false);
                }, function (error, sessionTimeout) {
                    if (error === "version-out-of-date") {
                        LRH.ShowStaticToast("Configuration outdated",
                            "Your current configuration settings cannot be saved due to a newer " +
                            "version found on the server, please refresh your browser. ", "error", true);
                    } else if (sessionTimeout !== undefined && sessionTimeout === true) {
                        LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                    } else {
                        LRH.ShowToast("Failed to save your Data View configuration.", "error", 5000);
                    }
                    $(".loading-progress-configureformeditor").css("visibility", "hidden");

                    that.uiObjectInstance["designer_save_button"].option("disabled", false);
                    that.uiObjectInstance["designer_close_button"].option("disabled", false);
                }, "form-editor");
        }, 100);
    };

    cancelButtonOnClick = (e) => {
        this.props.formDesignerButtonOnClick(e);
    };

    initializeTabItemTitle = (data) => {
        return (
            <React.Fragment>
                <span>{data.tabTitle}</span>
            </React.Fragment>
        );
    };

    initializeSettingsNumberBoxField = (data) => {
        let additionalClass = LDH.IsValueEmpty(data.additionalClass) ? "" : data.additionalClass;
        return (
            <React.Fragment>
                {
                    this.showOrHideSettings(data.settingsName) === false ? "" :
                        <div className={"leopard-settings-panel-contentgroup " + additionalClass}>
                            <div className="leopard-settings-panel-content-label">
                                <span>{data.displayName}</span>
                            </div>
                            <div>
                                <NumberBox min={data.minValue} max={data.maxValue} showSpinButtons={true}
                                           showClearButton={true}
                                           defaultValue={this.getDefaultValue(data.settingsName, data.defaultValue)}
                                           onValueChanged={(e) => this.updateFormDefinition({
                                               e, tabInfo: data, prop: data.settingsName
                                           })}>
                                </NumberBox>
                            </div>
                        </div>
                }
            </React.Fragment>
        );
    };

    initializeSettingsSelectBoxField = (data) => {
        let additionalClass = LDH.IsValueEmpty(data.additionalClass) ? "" : data.additionalClass;
        return (
            <React.Fragment>
                {
                    this.showOrHideSettings(data.settingsName) === false ? "" :
                        <div className={"leopard-settings-panel-contentgroup " + additionalClass}>
                            <div className="leopard-settings-panel-content-label">
                                <span>{data.displayName}</span>
                            </div>
                            <div>
                                <SelectBox dataSource={data.dataSource} displayExpr={'name'} valueExpr={'id'}
                                           defaultValue={this.getDefaultValue(data.settingsName, data.defaultValue)}
                                           onValueChanged={(e) => this.updateFormDefinition({
                                               e, tabInfo: data, prop: data.settingsName
                                           })}>
                                </SelectBox>
                            </div>
                        </div>
                }
            </React.Fragment>
        );
    };

    initializeSettingsTextAreaWithButtonField = (data) => {
        let additionalClass = LDH.IsValueEmpty(data.additionalClass) ? "" : data.additionalClass;
        let height = LDH.IsObjectNull(data.height) ? "140px" : data.height;
        return (
            <React.Fragment>
                {
                    this.showOrHideSettings(data.settingsName) === false ? "" :
                        <div className={"leopard-settings-panel-contentgroup " + additionalClass}>
                            <div className="leopard-settings-panel-content-label">
                                <span>{data.displayName}</span>
                            </div>
                            <div>
                                <TextArea defaultValue={this.getDefaultValue(data.settingsName, data.defaultValue)}
                                          ref={(e) => this.setInputFieldInstance({e, input: data.settingsName})}
                                          id={"Textbox_" + data.settingsName} height={height}
                                          onValueChanged={(e) => this.updateFormDefinition({
                                              e, tabInfo: data, prop: data.settingsName
                                          })} placeholder={data.placeholder}>
                                    {
                                        data.validator === false ? "" :
                                            <Validator>
                                                <RequiredRule
                                                    validationCallback={(e) => this.customValidationRuleCallback({
                                                        e,
                                                        input: data.settingsName,
                                                        rules: [{rule: "safeinput"}]
                                                    })} type="custom"/>
                                            </Validator>
                                    }
                                    {
                                        data.hasMiniButton === false ? "" :
                                            <TextBoxButton location={'after'} options={{
                                                icon: data.buttonIcon, type: 'default',
                                                onClick: () => {
                                                    data.onClick();
                                                }
                                            }} name={data.settingsName + "_MiniButton"}>
                                            </TextBoxButton>
                                    }
                                </TextArea>
                            </div>
                        </div>
                }
            </React.Fragment>
        );
    };

    initializeSettingsTextBoxField = (data) => {
        let additionalClass = LDH.IsValueEmpty(data.additionalClass) ? "" : data.additionalClass;
        return (
            <React.Fragment>
                {
                    this.showOrHideSettings(data.settingsName) === false ? "" :
                        <div className={"leopard-settings-panel-contentgroup " + additionalClass}>
                            <div className="leopard-settings-panel-content-label">
                                <span>{data.displayName}</span>
                            </div>
                            <div>
                                <TextBox defaultValue={this.getDefaultValue(data.settingsName, data.defaultValue)}
                                         ref={(e) => this.setInputFieldInstance({e, input: data.settingsName})}
                                         onValueChanged={(e) => this.updateFormDefinition({
                                             e, tabInfo: data, prop: data.settingsName
                                         })} showClearButton={true} placeholder={data.placeholder}>
                                    <Validator>
                                        <RequiredRule validationCallback={(e) => this.customValidationRuleCallback({
                                            e, input: data.settingsName, rules: [{rule: "safeinput"}]
                                        })} type="custom">
                                        </RequiredRule>
                                    </Validator>
                                </TextBox>
                            </div>
                        </div>
                }
            </React.Fragment>
        );
    };

    definitionParametersOnChanged = (data) => {
        this.definitionParameters = data.definitionParameters;
    };

    tabItemContent = (data) => {
        let directoryList = [];
        for (let i = 0; i < LeopardDropdownHelper.DropdownSelectionDataGridDocumentDirectory.length; i++) {
            directoryList.push(LeopardDropdownHelper.DropdownSelectionDataGridDocumentDirectory[i]);
        }
        for (let i = 0; i < LeopardDropdownHelper.DropdownSelectionDataGridFormDirectory.length; i++) {
            directoryList.push(LeopardDropdownHelper.DropdownSelectionDataGridFormDirectory[i]);
        }

        if (data.tabId === 0) {
            return (
                <React.Fragment>
                    <div style={{overflowX: "auto", maxHeight: "432px"}} className={"leopard-dataview-configbox"}>
                        <Box direction={'row'} width={'100%'}>
                            <Item baseSize={300}>
                                <div className="leopard-settings-panel-cell">
                                    <div className="leopard-settings-panel-title">General</div>
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "defaultMode",
                                            defaultValue: "design-mode",
                                            displayName: "Default mode:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionDefaultHtmlFormBuilderMode
                                        })
                                    }
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "hideModeButton",
                                            defaultValue: false,
                                            displayName: "Hide mode button:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionYesNo
                                        })
                                    }
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "hideUploadButton",
                                            defaultValue: false,
                                            displayName: "Hide upload button:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionYesNo
                                        })
                                    }
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "hideLoadButton",
                                            defaultValue: false,
                                            displayName: "Hide load button:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionYesNo
                                        })
                                    }
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "hideSaveButton",
                                            defaultValue: false,
                                            displayName: "Hide save button:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionYesNo
                                        })
                                    }
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "saveToParentLocation",
                                            defaultValue: false,
                                            displayName: "Save file to parent location:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionYesNo
                                        })
                                    }
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "formLoadDirectory",
                                            defaultValue: "global",
                                            displayName: "Directory:",
                                            dataSource: directoryList
                                        })
                                    }
                                    {
                                        this.initializeSettingsTextBoxField({
                                            settingsName: "formLoadFileName",
                                            defaultValue: "",
                                            displayName: "File name:"
                                        })
                                    }
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "formLoadOwnerId",
                                            defaultValue: "orgid",
                                            displayName: "Owner ID:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionReferenceDataOwner
                                        })
                                    }
                                    {
                                        this.initializeSettingsTextBoxField({
                                            settingsName: "documentRetrieveType",
                                            defaultValue: "leopardsystems.document.retrieve",
                                            displayName: "Document retrieve type:"
                                        })
                                    }
                                    {
                                        this.initializeSettingsTextBoxField({
                                            settingsName: "documentModifyType",
                                            defaultValue: "leopardsystems.document.modify",
                                            displayName: "Document modify type:"
                                        })
                                    }
                                    {
                                        this.initializeSettingsTextBoxField({
                                            settingsName: "documentCreateType",
                                            defaultValue: "leopardsystems.document.create",
                                            displayName: "Document create type:"
                                        })
                                    }
                                    {
                                        this.initializeSettingsTextBoxField({
                                            settingsName: "documentListType",
                                            defaultValue: "leopardsystems.document.list",
                                            displayName: "Document list type:"
                                        })
                                    }
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "uploadCacheToS3",
                                            defaultValue: false,
                                            displayName: "Upload cache to S3:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionYesNo
                                        })
                                    }
                                </div>
                            </Item>
                        </Box>
                    </div>
                </React.Fragment>
            );
        }
        return null;
    };

    render() {
        return (
            <React.Fragment>
                <div style={{width: "100%", overflowX: "auto"}}>
                    <div className={"leopard-editgridview-title"}>Configure Form Builder</div>
                    <div className={"leopard-gray-panel-container"} style={{marginTop: "10px"}}>
                        <TabPanel dataSource={LeopardDropdownHelper.DropdownSelectionHtmlFormBuilderTabPanel}
                                  onOptionChanged={this.onSelectionChanged}
                                  loop={true} itemTitleRender={this.initializeTabItemTitle}
                                  itemComponent={this.tabItemContent} animationEnabled={true} swipeEnabled={false}
                        ></TabPanel>
                    </div>
                </div>
                <div style={{padding: "10px 0px 10px 10px", minWidth: "900px", clear: "both"}}>
                    <span style={{padding: "0 10px 0 0"}}>
                            <Button className="leopard-standard-button" style={{minWidth: "100px"}} text={"Save"}
                                    ref={(e) => this.setInputFieldInstance({e: e, input: "designer_save_button"})}
                                    onClick={(e) => this.saveDataButtonOnClick(e)}>
                            </Button>
                        </span>
                    <span style={{padding: "0 0 0 5px"}}>
                            <Button className="leopard-standard-button" style={{minWidth: "100px"}} text={"Close"}
                                    ref={(e) => this.setInputFieldInstance({e: e, input: "designer_close_button"})}
                                    onClick={(e) => this.cancelButtonOnClick({e})}>
                            </Button>
                        </span>
                    <span className={"loading-progress-configureformeditor"}>
                            <i className="fas fa-spinner fa-pulse" style={{color: "#FF8100", fontSize: "25px"}}></i>
                        </span>
                </div>
                <br/>
            </React.Fragment>
        );
    }
}

const RetrieveDataFromReducer = (state) => {
    return {state};
};

export default connect(RetrieveDataFromReducer)(LeopardHtmlFormBuilderDesigner);
